import React, {Fragment} from "react";
import styled from "@emotion/styled";
import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet-async";

import {Button as MuiButton, CircularProgress, Typography} from "@mui/material";
import {Box, spacing} from "@mui/system";
import {useSelector} from "react-redux";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function Page498() {
    const LogedInTeamMember = useSelector(store => store.org.LogedInTeamMember);
    const navigate = useNavigate();

    const handleRedirect = () => {
        //const accessToken = window.localStorage.getItem("accessToken");
        //if (LogedInTeamMember.role === 'editor' && accessToken) {
        //    navigate("/bots");
        //} else if (accessToken && LogedInTeamMember.role !== 'editor') {
            navigate("/login");
        //}
    }

    return (
        <Wrapper>
            <Helmet title="404 Error"/>
            {Object.keys(LogedInTeamMember).length === 0 && <Fragment>
                <CircularProgress/>
            </Fragment>}
            {Object.keys(LogedInTeamMember).length > 0 && <Box>
                <Typography component="h1" variant="h1" align="center" gutterBottom>
                    498
                </Typography>
                <Typography component="h2" variant="h5" align="center" gutterBottom>
                    Subscription Expired.
                </Typography>
                <Typography component="h2" variant="body1" align="center" gutterBottom>
                    Please renew your subscription to continue.
                </Typography>
                <Button
                    onClick={handleRedirect}
                    variant="contained"
                    color="secondary"
                    mt={2}
                    size="large"
                >
                    Return to Application
                </Button>
            </Box>}
        </Wrapper>
    );
}

export default Page498;
