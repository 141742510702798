import {
    checkVariableStringIsValidOrNot,
    removeBracesFromStringNewImplementation,
} from "../../pages/pages/Bots/workflow-builder/utils/helpers";
import WorkflowErrors from "../../utils/workflowErrors";
import { renderableCards } from "./builderStoreLogic";
import { findVariableInStringWithBracess } from "./findVariableWithBraces";
import { reports_key } from "./genericValidation";
import { nodeValidationsLogic } from "./validations";

/**
 *
 * @param {*} data : string which can be single word or many words.
 * @param {*} variableList : array of variable objects.
 * @param {*} acceptVariableType : accepted variable type ['string','number',etc.].
 * @param {*} isInputVariableOrOneVariable : (Optional) Boolean flag of the data is single word and variable or not.
 * @param {*} variableTypeErrorMessageOnlyForOneVariable : (Optional) custom error message if variable type doesn't match to accepted variable type.
 * @param {*} outputVariableNotFoundErrorMessage : (Optional) Only for Output Variable, custom error message if output variable not found error message.
 * @returns
 */

const validateVariableFromList = ({
    data = "",
    variableList,
    acceptVariableType,
    isInputVariableOrOneVariable = false,
    variableTypeErrorMessageOnlyForOneVariable,
    outputVariableNotFoundErrorMessage,
}) => {
    // ================================= NEW CODE ==================================
    let result = { flag: false, messages: [] };
    if (!data || data === "") {
        return result;
    }

    let variablesToValidate = [];
    // here we are assuming that data is a string which have could contain more variables or words
    if (isInputVariableOrOneVariable === false) {
        const { variableFoundWhileTraversingData } =
            findVariableInStringWithBracess(data);
        variablesToValidate = variableFoundWhileTraversingData;
    } else {
        // here we know data is a one variable (Input variable)
        if (checkVariableStringIsValidOrNot(data)) {
            variablesToValidate = [data];
        } else {
            return { flag: true, messages: [`${data} is not valid variable.`] };
        }
    }
    //  TO Show error
    // Check if variable is valid
    for (let index = 0; index < variablesToValidate.length; index++) {
        const currentVariable = variablesToValidate[index];
        const currentVariableWithoutBraces =
            removeBracesFromStringNewImplementation(currentVariable);

        // Here we are only validating variable which are created or real variable without "." in variableName
        if (currentVariableWithoutBraces.includes(".")) {
            const mainVariableWithoutBraces =
                currentVariableWithoutBraces.split(".")[0];
            const variable = variableList.find(
                (variable) =>
                    removeBracesFromStringNewImplementation(variable.display) ===
                    mainVariableWithoutBraces
            );
            if (!variable) {
                result.flag = true;
                if (!outputVariableNotFoundErrorMessage) {
                    result.messages = [
                        ...result.messages,
                        `{{${mainVariableWithoutBraces}}} variable is not defined.`,
                    ];
                } else {
                    result.messages = [
                        ...result.messages,
                        outputVariableNotFoundErrorMessage,
                    ];
                }
            } else if (acceptVariableType && variable.variableType) {
                if (
                    acceptVariableType.includes("string") &&
                    !["array", "object"].includes(variable.variableType)
                ) {
                    result.flag = true;
                    if (!variableTypeErrorMessageOnlyForOneVariable) {
                        result.messages = [
                            ...result.messages,
                            `{{${mainVariableWithoutBraces}}} variable is not object or array type.`,
                        ];
                    } else {
                        result.messages = [
                            ...result.messages,
                            variableTypeErrorMessageOnlyForOneVariable,
                        ];
                    }
                } else if (!["object"].includes(variable.variableType)) {
                    // here we know mainVariableWithoutBraces is object type
                    result.flag = true;
                    if (!variableTypeErrorMessageOnlyForOneVariable) {
                        result.messages = [
                            ...result.messages,
                            `{{${mainVariableWithoutBraces}}} variable is not object type.`,
                        ];
                    } else {
                        result.messages = [
                            ...result.messages,
                            variableTypeErrorMessageOnlyForOneVariable,
                        ];
                    }
                }
            }
        } else {
            const variable = variableList.find(
                (variable) =>
                    removeBracesFromStringNewImplementation(variable.display) ===
                    currentVariableWithoutBraces
            );
            if (!variable) {
                result.flag = true;
                if (!outputVariableNotFoundErrorMessage) {
                    result.messages = [
                        ...result.messages,
                        `{{${currentVariableWithoutBraces}}} variable is not defined.`,
                    ];
                } else {
                    result.messages = [
                        ...result.messages,
                        outputVariableNotFoundErrorMessage,
                    ];
                }
            } else if (
                variable.variableType &&
                acceptVariableType &&
                !acceptVariableType.includes(variable.variableType) &&
                !acceptVariableType.includes(undefined) &&
                !acceptVariableType.includes(null)
            ) {
                result.flag = true;
                if (!variableTypeErrorMessageOnlyForOneVariable) {
                    result.messages = [
                        ...result.messages,
                        `{{${currentVariableWithoutBraces}}} variable must be of ${acceptVariableType.join(
                            ", "
                        )} type.`,
                    ];
                } else {
                    result.messages = [
                        ...result.messages,
                        variableTypeErrorMessageOnlyForOneVariable,
                    ];
                }
            }
        }
    }

    //TODO:Implement waring To Show warning [incomplete variable ]
    // for (let index = 0; index < inCompleteVariableFoundWhileTraversingData.length; index++) {
    //     const currentVariable = inCompleteVariableFoundWhileTraversingData[index];
    //     result.messages.push(`${currentVariable} variable is invalid`);
    // }

    return result;
    // ================================= NEW CODE ==================================

    // ================================= OLD IMPLEMENTATION =================================
    // let result = { flag: false, messages: [] };
    // let splitedBySpacedString = data.split(" "); // split string into sop that we can get variables
    // for (let i = 0; i < splitedBySpacedString.length; i++) {
    //     let text = splitedBySpacedString[i];
    //     if (text.length > 2 && text[0] === '{' && text[1] === '{') {
    //         if (text[text.length - 1] !== '}' && text[text.length - 2] !== '}') {
    //             result = {
    //                 flag: true,
    //                 messages: [`${text} variable is invalid`]
    //             }
    //         } else if (text.length > 5) { // four character of [ '{' '{' '}' '}' ], here we have a valid variable name in our format
    //             if ((variableList.find(variable => variable.display === text) === undefined)) {
    //                 result = {
    //                     flag: true,
    //                     messages: [`${text} variable is not defined`]
    //                 }
    //             }
    //         }
    //     }
    // }
    // return result;
    // ================================= OLD IMPLEMENTATION =================================
};

const suggestedVariableValidationSwitchCaseLogic = (
    currentNode,
    newVariableSuggetionList
) => {
    return validateVariableInNodeContent(
        currentNode,
        newVariableSuggetionList,
        validateVariableFromList
    );
};

export const suggestedVariableValidation = (
    currentNode,
    stateVariables,
    stateVariableSuggestionList,
    localeSupportedByBot
) => {
    // let result = { flag: false, messages: [] };

    // TEMP FIX
    // if (result) {
    // 	return result;
    // }

    // filter out all the variable whose variable type => [string, number, boolean]
    let variableList = stateVariables;

    // converting filtered variable list into suggestion variable list form
    let variableSuggestionList = variableList.map((variable) => ({
        display: variable.variableName,
        id: variable.variableId,
        nodeId: variable.nodeId,
        variableName: variable.variableName,
        variableType: variable.variableType,
    }));

    let newVariableSuggetionListWithoutFilterToParentChild = [
        ...variableSuggestionList,
        ...stateVariableSuggestionList,
    ];
    let newVariableSuggetionList = [];
    newVariableSuggetionListWithoutFilterToParentChild.forEach((variable) => {
        const isExist = currentNode.data.parentNodesIdArray.find(
            (parent) => parent.parentId === variable.nodeId
        );
        if (isExist) {
            newVariableSuggetionList.push(variable);
        }
    });

    let reports = {
        errors: currentNode.data.reports.errors,
        warnings: currentNode.data.reports.warnings,
    };
    if (reports.errors[reports_key.error_key.VARIABLE_NOT_DEFINED.key]) {
        reports.errors[reports_key.error_key.VARIABLE_NOT_DEFINED.key] = {
            messages: [],
        };
    }

    // =============================  For Errors Reports =================
    if (renderableCards.includes(currentNode.type)) {
        Object.keys(localeSupportedByBot).forEach((localeContentKeyName) => {
            // Only validate those locale which are enabled in our bot
            if (localeSupportedByBot[localeContentKeyName] === true) {
                // This is a hack
                const generatedContentWithoutLocale =
                    nodeValidationsLogic.generateNodeContentBasedForValidation(
                        currentNode,
                        localeContentKeyName
                    );
                const nodeToValidate = {
                    ...currentNode,
                    data: {
                        ...currentNode.data,
                        contents: generatedContentWithoutLocale,
                    },
                };
                const validationResult = suggestedVariableValidationSwitchCaseLogic(
                    nodeToValidate,
                    newVariableSuggetionList
                );

                let reportMessages = [];
                if (
                    reports.errors[reports_key.error_key.VARIABLE_NOT_DEFINED.key] &&
                    reports.errors[reports_key.error_key.VARIABLE_NOT_DEFINED.key]
                        .messages
                ) {
                    reportMessages =
                        reports.errors[reports_key.error_key.VARIABLE_NOT_DEFINED.key]
                            .messages;
                }

                reports.errors[reports_key.error_key.VARIABLE_NOT_DEFINED.key] = {
                    messages: [
                        ...reportMessages,
                        // For now we are going with only one locale English
                        // ...validationResult.messages.map((errorMessage) => `For Locale (${localeContentKeyName}) : ${errorMessage}`)
                        ...validationResult.messages.map(
                            (errorMessage) => `${errorMessage}`
                        ),
                    ],
                };
            }
        });
        // return reports;
    } else {
        let suggestionVariableResult = suggestedVariableValidationSwitchCaseLogic(
            currentNode,
            newVariableSuggetionList
        );
        reports.errors[reports_key.error_key.VARIABLE_NOT_DEFINED.key] = {
            messages: suggestionVariableResult.messages,
        };
        // return reports;
    }
    // =============================  For Errors Reports =================

    if (reports.warnings[reports_key.warning_key.VARIABLE_NOT_FOUND.key]) {
        reports.warnings[reports_key.warning_key.VARIABLE_NOT_FOUND.key] = {
            messages: [],
        };
    }

    // =============================  For Warnings Reports =================

    if (renderableCards.includes(currentNode.type)) {
        Object.keys(localeSupportedByBot).forEach((localeContentKeyName) => {
            // Only validate those locale which are enabled in our bot
            if (localeSupportedByBot[localeContentKeyName] === true) {
                // This is a hack
                const generatedContentWithoutLocale =
                    nodeValidationsLogic.generateNodeContentBasedForValidation(
                        currentNode,
                        localeContentKeyName
                    );
                const nodeToValidate = {
                    ...currentNode,
                    data: {
                        ...currentNode.data,
                        contents: generatedContentWithoutLocale,
                    },
                };
                const validationResult =
                    suggestedVariableValidationSwitchCaseLogicForWarningReports(
                        nodeToValidate,
                        newVariableSuggetionList
                    );

                let reportMessages = [];
                if (
                    reports.warnings[reports_key.warning_key.VARIABLE_NOT_FOUND.key] &&
                    reports.warnings[reports_key.warning_key.VARIABLE_NOT_FOUND.key]
                        .messages
                ) {
                    reportMessages =
                        reports.warnings[reports_key.warning_key.VARIABLE_NOT_FOUND.key]
                            .messages;
                }

                reports.warnings[reports_key.warning_key.VARIABLE_NOT_FOUND.key] = {
                    messages: [
                        ...reportMessages,
                        // For now we are going with only one locale English
                        // ...validationResult.messages.map((errorMessage) => `For Locale (${localeContentKeyName}) : ${errorMessage}`)
                        ...validationResult.messages.map(
                            (errorMessage) => `${errorMessage}`
                        ),
                    ],
                };
            }
        });
        // return reports;
    } else {
        let suggestionVariableResult =
            suggestedVariableValidationSwitchCaseLogicForWarningReports(
                currentNode,
                newVariableSuggetionList
            );
        reports.warnings[reports_key.warning_key.VARIABLE_NOT_FOUND.key] = {
            messages: suggestionVariableResult.messages,
        };
        // return reports;
    }

    // =============================  For Warnings Reports =================

    return reports;
};

const validateVariableFromListForWarningReports = ({
    data = "",
    variableList,
    acceptVariableType,
    isInputVariableOrOneVariable = false,
}) => {
    // ================================= NEW CODE ==================================
    let result = { flag: false, messages: [] };
    if (!data || data === "") {
        return result;
    }

    let variablesToValidate = [];
    // here we are assuming that data is a string which have could contain more variables or words
    if (isInputVariableOrOneVariable === false) {
        const { variableFoundWhileTraversingData } =
            findVariableInStringWithBracess(data);
        variablesToValidate = variableFoundWhileTraversingData;
    } else {
        // her we know data is a one variable (Input variable)
        if (checkVariableStringIsValidOrNot(data)) {
            variablesToValidate = [data];
        } else {
            return { flag: true, messages: [`${data} is not valid variable.`] };
        }
    }

    //  TO Show error
    // Check if variable is valid
    for (let index = 0; index < variablesToValidate.length; index++) {
        const currentVariable = variablesToValidate[index];
        // Here we are only validating variable which contain "." in variableName for warnings
        if (currentVariable.includes(".")) {
            if (
                variableList.find(
                    (variable) => variable.display === currentVariable
                ) === undefined
            ) {
                result.flag = true;
                result.messages = [
                    ...result.messages,
                    `${currentVariable} variable is not defined`,
                ];
            }
        }
    }

    //TODO:Implement waring To Show warning [incomplete variable ]
    // for (let index = 0; index < inCompleteVariableFoundWhileTraversingData.length; index++) {
    //     const currentVariable = inCompleteVariableFoundWhileTraversingData[index];
    //     result.messages.push(`${currentVariable} variable is invalid`);
    // }

    return result;
};

const suggestedVariableValidationSwitchCaseLogicForWarningReports = (
    currentNode,
    newVariableSuggetionList
) => {
    return validateVariableInNodeContent(
        currentNode,
        newVariableSuggetionList,
        validateVariableFromListForWarningReports
    );
};

const validateVariableInNodeContent = (
    currentNode,
    variableList,
    callBackToValidate
) => {
    let result = { flag: false, messages: [] };
    switch (currentNode.type) {
        // renderable nodes
        case "plainMessageTextNode":
        case "questionNode":
            result = callBackToValidate({
                data: currentNode.data.contents,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            break;
        case "listNode":
            const resultOfOpenButtonText = callBackToValidate({
                data: currentNode.data.contents.openButtonText,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag: result.flag === true ? true : resultOfOpenButtonText.flag,
                messages: [...result.messages, ...resultOfOpenButtonText.messages],
            };
            const resultOfBodyText = callBackToValidate({
                data: currentNode.data.contents.bodyText,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag: result.flag === true ? true : resultOfBodyText.flag,
                messages: [...result.messages, ...resultOfBodyText.messages],
            };
            for (let i = 0; i < currentNode.data.contents.list.length; i++) {
                const resultOfListButtonText = callBackToValidate({
                    data: currentNode.data.contents.list[i].value,
                    variableList,
                    acceptVariableType: ["string", "number", "boolean"],
                });
                result = {
                    flag: result.flag === true ? true : resultOfListButtonText.flag,
                    messages: [...result.messages, ...resultOfListButtonText.messages],
                };
            }
            break;
        case "buttonNode":
            if (
                currentNode.data.contents.isMediaRender === true &&
                currentNode.data.contents.isUrlByVariable === true &&
                currentNode.data.contents.mediaFromType === "byURL"
            ) {
                const resultOfHeaderMediaUrlByVariable = callBackToValidate({
                    data: currentNode.data.contents.mediaUrl,
                    variableList,
                    acceptVariableType: ["string"],
                    isInputVariableOrOneVariable: true,
                });
                result = {
                    flag:
                        result.flag === true ? true : resultOfHeaderMediaUrlByVariable.flag,
                    messages: [
                        ...result.messages,
                        ...resultOfHeaderMediaUrlByVariable.messages,
                    ],
                };
            }
            if (
                currentNode.data.contents.isMediaRender === true &&
                currentNode.data.contents.mediaType === "document" &&
                currentNode.data.contents.fileDisplayNameForDocument
            ) {
                const resultOfHeaderMediaDocumentFilename = callBackToValidate({
                    data: currentNode.data.contents.fileDisplayNameForDocument,
                    variableList,
                    acceptVariableType: ["string", "number"],
                });
                result = {
                    flag:
                        result.flag === true
                            ? true
                            : resultOfHeaderMediaDocumentFilename.flag,
                    messages: [
                        ...result.messages,
                        ...resultOfHeaderMediaDocumentFilename.messages,
                    ],
                };
            }
            const resultOfButtonHeaderText = callBackToValidate({
                data: currentNode.data.contents.headerText,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag: result.flag === true ? true : resultOfButtonHeaderText.flag,
                messages: [...result.messages, ...resultOfButtonHeaderText.messages],
            };
            const resultOfButtonBodyText = callBackToValidate({
                data: currentNode.data.contents.bodyText,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag: result.flag === true ? true : resultOfButtonBodyText.flag,
                messages: [...result.messages, ...resultOfButtonBodyText.messages],
            };
            const resultOfButtonFooterText = callBackToValidate({
                data: currentNode.data.contents.footerText,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag: result.flag === true ? true : resultOfButtonFooterText.flag,
                messages: [...result.messages, ...resultOfButtonFooterText.messages],
            };
            for (let i = 0; i < currentNode.data.contents.buttons.length; i++) {
                const resultOfSubButtonText = callBackToValidate({
                    data: currentNode.data.contents.buttons[i].value,
                    variableList,
                    acceptVariableType: ["string", "number", "boolean"],
                });
                result = {
                    flag: result.flag === true ? true : resultOfSubButtonText.flag,
                    messages: [...result.messages, ...resultOfSubButtonText.messages],
                };
            }
            break;
        case "mediaNode":
            result = callBackToValidate({
                data:
                    currentNode.data.contents.caption !== null
                        ? currentNode.data.contents.caption
                        : "",
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            if (
                currentNode.data.contents.isDataFromUrl === true &&
                currentNode.data.contents.media &&
                currentNode.data.contents.media.fileUrl &&
                currentNode.data.contents.media.byVariable
            ) {
                const fileUrlResult = callBackToValidate({
                    data: currentNode.data.contents.media.fileUrl,
                    variableList,
                    acceptVariableType: ["string"],
                    isInputVariableOrOneVariable: true,
                });
                result = {
                    flag: result.flag === true ? true : fileUrlResult.flag,
                    messages: [...result.messages, ...fileUrlResult.messages],
                };
            }
            if (
                currentNode.data.contents.mediaType === "document" &&
                currentNode.data.contents.fileDisplayNameForDocument
            ) {
                const fileDisplayNameForDocumentResult = callBackToValidate({
                    data: currentNode.data.contents.fileDisplayNameForDocument,
                    variableList,
                    acceptVariableType: ["string", "number"],
                });
                result = {
                    flag:
                        result.flag === true ? true : fileDisplayNameForDocumentResult.flag,
                    messages: [
                        ...result.messages,
                        ...fileDisplayNameForDocumentResult.messages,
                    ],
                };
            }
            break;
        case "mediaInputNode":
            result = callBackToValidate({
                data:
                    currentNode.data.contents.text !== null
                        ? currentNode.data.contents.text
                        : "",
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            break;

        case "mediaListNode": {
            result = callBackToValidate({
                data: currentNode.data.contents?.mediaVariable,
                variableList,
                acceptVariableType: ["array"],
                isInputVariableOrOneVariable: true,
            });
            break;
        }

        case "locationOutputNode": {
            result = callBackToValidate({
                data: currentNode.data.contents?.variable,
                variableList,
                acceptVariableType: ["object"],
                isInputVariableOrOneVariable: true,
            });
            break;
        }
        case "locationInputNode":
            result = callBackToValidate({
                data:
                    currentNode.data.contents.text !== null
                        ? currentNode.data.contents.text
                        : "",
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            break;
        case "dynamicButtonNode":
            result = callBackToValidate({
                data:
                    currentNode.data.contents.bodyText !== null
                        ? currentNode.data.contents.bodyText
                        : "",
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            const inputVariableResult = callBackToValidate({
                data: currentNode.data.contents.inputVariable,
                variableList,
                acceptVariableType: ["array"],
                isInputVariableOrOneVariable: true,
            });
            result = {
                flag: result.flag === true ? true : inputVariableResult.flag,
                messages: [...result.messages, ...inputVariableResult.messages],
            };
            break;
        case "dynamicListNode":
            const bodyTextResult = callBackToValidate({
                data:
                    currentNode.data.contents.bodyText !== null
                        ? currentNode.data.contents.bodyText
                        : "",
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag: result.flag === true ? true : bodyTextResult.flag,
                messages: [...result.messages, ...bodyTextResult.messages],
            };
            const openButtonTextResult = callBackToValidate({
                data:
                    currentNode.data.contents.openButtonText !== null
                        ? currentNode.data.contents.openButtonText
                        : "",
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag: result.flag === true ? true : openButtonTextResult.flag,
                messages: [...result.messages, ...openButtonTextResult.messages],
            };

            const listNodeInputVariableResult = callBackToValidate({
                data: currentNode.data.contents.inputVariable,
                variableList,
                acceptVariableType: ["array"],
                isInputVariableOrOneVariable: true,
            });
            result = {
                flag: result.flag === true ? true : listNodeInputVariableResult.flag,
                messages: [...result.messages, ...listNodeInputVariableResult.messages],
            };
            break;

        //  Logical Nodes
        case "conditionNode":
            if (
                currentNode.data.contents.conditions &&
                currentNode.data.contents.conditions.length > 1
            ) {
                for (let i = 0; i < currentNode.data.contents.conditions.length; i++) {
                    if (
                        currentNode.data.contents.conditions[i].isCompareWithConstant ===
                        false
                    ) {
                        const resultCompareToValue = callBackToValidate({
                            data: currentNode.data.contents.conditions[i].compareWithValue,
                            variableList,
                            isInputVariableOrOneVariable: true,
                        });
                        result = {
                            flag: result.flag === true ? true : resultCompareToValue.flag,
                            messages: [...result.messages, ...resultCompareToValue.messages],
                        };
                    }
                    const resultCompareWithValue = callBackToValidate({
                        data: currentNode.data.contents.conditions[i].compareToValue,
                        variableList,
                        isInputVariableOrOneVariable: true,
                    });
                    result = {
                        flag: result.flag === true ? true : resultCompareWithValue.flag,
                        messages: [...result.messages, ...resultCompareWithValue.messages],
                    };
                }
            }
            break;
        case "iterationGuardNode":
            if (currentNode.data.contents.isMaxValueFromVariable === true)
                result = callBackToValidate({
                    data:
                        currentNode.data.contents.maxValue !== null
                            ? currentNode.data.contents.maxValue
                            : "",
                    variableList,
                });
            break;
        case "switchConditionNode":
            result = callBackToValidate({
                data:
                    currentNode.data.contents.variableToCheckCondition !== null
                        ? currentNode.data.contents.variableToCheckCondition
                        : "",
                variableList,
                isInputVariableOrOneVariable: true,
            });
            for (let i = 0; i < currentNode.data.contents.conditions.length; i++) {
                if (
                    currentNode.data.contents.conditions[i].isCompareWithConstant ===
                    false
                ) {
                    const resultCompareWithValue = callBackToValidate({
                        data: currentNode.data.contents.conditions[i].compareValue,
                        variableList,
                        isInputVariableOrOneVariable: true,
                    });
                    result = {
                        flag: result.flag === true ? true : resultCompareWithValue.flag,
                        messages: [...result.messages, ...resultCompareWithValue.messages],
                    };
                }
            }
            break;
        case "webhookNode":
            const urlResult = callBackToValidate({
                data:
                    currentNode.data.contents.url !== null
                        ? currentNode.data.contents.url
                        : "",
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag: result.flag === true ? true : urlResult.flag,
                messages: [...result.messages, ...urlResult.messages],
            };

            const bodyResult = callBackToValidate({
                data:
                    currentNode.data.contents.body !== null
                        ? currentNode.data.contents.body
                        : "",
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });

            result = {
                flag: result.flag === true ? true : bodyResult.flag,
                messages: [...result.messages, ...bodyResult.messages],
            };

            if (
                currentNode.data.contents.isBodybyVariable === true &&
                currentNode.data.contents.bodyVariable
            ) {
                const bodyByVariableResult = callBackToValidate({
                    data: currentNode.data.contents.bodyVariable,
                    variableList,
                    acceptVariableType: ["object"],
                    isInputVariableOrOneVariable: true,
                });
                result = {
                    flag: result.flag === true ? true : bodyByVariableResult.flag,
                    messages: [...result.messages, ...bodyByVariableResult.messages],
                };
            }

            for (let i = 0; i < currentNode.data.contents.headers.length; i++) {
                const headerResult = callBackToValidate({
                    data: currentNode.data.contents.headers[i].value,
                    variableList,
                    acceptVariableType: ["string", "number", "boolean"],
                });
                result = {
                    flag: result.flag === true ? true : headerResult.flag,
                    messages: [...result.messages, ...headerResult.messages],
                };
            }
            break;

        case "loopBackNode":
            break;

        case "subFlowNode": {
            for (
                let i = 0;
                i < currentNode.data.contents.subflowInputVariables.length;
                i++
            ) {
                const subflowInputVariableResult = callBackToValidate({
                    data: currentNode.data.contents?.subflowInputVariables[i].value,
                    variableList,
                });
                result = {
                    flag: result.flag === true ? true : subflowInputVariableResult.flag,
                    messages: [
                        ...result.messages,
                        ...subflowInputVariableResult.messages,
                    ],
                };
            }
            for (
                let i = 0;
                i < currentNode.data.contents.subflowOutputVariables.length;
                i++
            ) {
                const subflowOutputVariableResult = callBackToValidate({
                    data: currentNode.data.contents.subflowOutputVariables[i].value,
                    variableList,
                });
                result = {
                    flag: result.flag === true ? true : subflowOutputVariableResult.flag,
                    messages: [
                        ...result.messages,
                        ...subflowOutputVariableResult.messages,
                    ],
                };
            }
            break;
        }

        case "sendEmailCard": {
            const receipientEmailResult = callBackToValidate({
                data: currentNode.data.contents?.receipientEmail,
                variableList,
                acceptVariableType: ["string"],
                isInputVariableOrOneVariable: true,
            });
            result = {
                flag: result.flag === true ? true : receipientEmailResult.flag,
                messages: [...result.messages, ...receipientEmailResult.messages],
            };

            const emailBodyResult = callBackToValidate({
                data: currentNode.data.contents?.emailBody,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag: result.flag === true ? true : emailBodyResult.flag,
                messages: [...result.messages, ...emailBodyResult.messages],
            };
            const subjectResult = callBackToValidate({
                data: currentNode.data.contents?.subject,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag: result.flag === true ? true : subjectResult.flag,
                messages: [...result.messages, ...subjectResult.messages],
            };
            const senderEmail = callBackToValidate({
                data: currentNode.data.contents?.senderEmail,
                variableList,
                acceptVariableType: ["string"],
            });
            result = {
                flag: result.flag === true ? true : senderEmail.flag,
                messages: [...result.messages, ...senderEmail.messages],
            };
            const senderDisplayName = callBackToValidate({
                data: currentNode.data.contents?.senderDisplayName,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag: result.flag === true ? true : senderDisplayName.flag,
                messages: [...result.messages, ...senderDisplayName.messages],
            };
            const replyToEmail = callBackToValidate({
                data: currentNode.data.contents?.replyToEmail,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag: result.flag === true ? true : replyToEmail.flag,
                messages: [...result.messages, ...replyToEmail.messages],
            };
            const replyToDisplayName = callBackToValidate({
                data: currentNode.data.contents?.replyToDisplayName,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag: result.flag === true ? true : replyToDisplayName.flag,
                messages: [...result.messages, ...replyToDisplayName.messages],
            };
            break;
        }

        case "sendSMSCard": {
            const receipientNumber = callBackToValidate({
                data: currentNode.data.contents?.receipientNumber,
                variableList,
                acceptVariableType: ["string", "number"],
                isInputVariableOrOneVariable: true,
            });
            result = {
                flag: result.flag === true ? true : receipientNumber.flag,
                messages: [...result.messages, ...receipientNumber.messages],
            };
            const message = callBackToValidate({
                data: currentNode.data.contents?.message,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag: result.flag === true ? true : message.flag,
                messages: [...result.messages, ...message.messages],
            };
            const senderName = callBackToValidate({
                data: currentNode.data.contents?.senderName,
                variableList,
                acceptVariableType: ["string"],
            });
            result = {
                flag: result.flag === true ? true : senderName.flag,
                messages: [...result.messages, ...senderName.messages],
            };
            const dltentityId = callBackToValidate({
                data: currentNode.data.contents?.dltentityId,
                variableList,
                acceptVariableType: ["string", "number"],
            });
            result = {
                flag: result.flag === true ? true : dltentityId.flag,
                messages: [...result.messages, ...dltentityId.messages],
            };
            const dlttempId = callBackToValidate({
                data: currentNode.data.contents?.dlttempId,
                variableList,
                acceptVariableType: ["string", "number"],
            });
            result = {
                flag: result.flag === true ? true : dlttempId.flag,
                messages: [...result.messages, ...dlttempId.messages],
            };

            for (
                let i = 0;
                i < currentNode.data.contents.messageVariableArray.length;
                i++
            ) {
                const messageVariableResult = callBackToValidate({
                    data: currentNode.data.contents.messageVariableArray[i].value,
                    variableList,
                });
                result = {
                    flag: result.flag === true ? true : messageVariableResult.flag,
                    messages: [...result.messages, ...messageVariableResult.messages],
                };
            }

            break;
        }

        case "jsonMapperCard": {
            if (currentNode.data.contents.withSourceData === true) {
                if (
                    currentNode.data.contents.inputObjectArrayVariable &&
                    currentNode.data.contents.inputObjectArrayVariable.variableType
                ) {
                    result = callBackToValidate({
                        data: `{{${currentNode.data.contents.inputObjectArrayVariable.path}}}`,
                        acceptVariableType: [
                            currentNode.data.contents.inputObjectArrayVariable?.variableType,
                        ],
                        variableList,
                        isInputVariableOrOneVariable: true,
                    });
                }
            } else {
                if (
                    currentNode.data.outputVariable &&
                    currentNode.data.outputVariable?.variableType !== "object"
                ) {
                    const outputVariableTypeResult = callBackToValidate({
                        data: currentNode.data.outputVariable.variableName,
                        acceptVariableType: ["object"],
                        variableList,
                        isInputVariableOrOneVariable: true,
                    });
                    result = {
                        flag: result.flag === true ? true : outputVariableTypeResult.flag,
                        messages: [
                            ...result.messages,
                            ...outputVariableTypeResult.messages,
                        ],
                    };
                }
            }

            for (let i = 0; i < currentNode.data.contents.propertyArray.length; i++) {
                const propertyArrayResult = getContentAndValidateForJsonMapper(
                    currentNode.data.contents.propertyArray[i],
                    result,
                    variableList,
                    callBackToValidate
                );
                result = {
                    flag: result.flag === true ? true : propertyArrayResult.flag,
                    messages: [...result.messages, ...propertyArrayResult.messages],
                };
            }
            break;
        }

        case "liveAgentNode": {
            const nodeContent = currentNode.data.contents;
            result = callBackToValidate({
                data: nodeContent?.messageAfterLiveAgentConnect,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });

            // Not Available button Content
            const notAvailableButtonContentBodyText = callBackToValidate({
                data: nodeContent?.liveAgentNotAvailableButtonContents?.bodyText,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag:
                    result.flag === true ? true : notAvailableButtonContentBodyText.flag,
                messages: [
                    ...result.messages,
                    ...notAvailableButtonContentBodyText.messages,
                ],
            };
            const notAvailableBtnRegisterResult = callBackToValidate({
                data: nodeContent?.liveAgentNotAvailableButtonContents?.buttons
                    ?.register?.value,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag: result.flag === true ? true : notAvailableBtnRegisterResult.flag,
                messages: [
                    ...result.messages,
                    ...notAvailableBtnRegisterResult.messages,
                ],
            };
            const notAvailableBtnCancelRegisterResult = callBackToValidate({
                data: nodeContent?.liveAgentNotAvailableButtonContents?.buttons
                    ?.cancelRegister?.value,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag:
                    result.flag === true
                        ? true
                        : notAvailableBtnCancelRegisterResult.flag,
                messages: [
                    ...result.messages,
                    ...notAvailableBtnCancelRegisterResult.messages,
                ],
            };

            // Live Agent Callback received
            const callbackReceivedBodyText = callBackToValidate({
                data: nodeContent?.liveAgentWhenCallbackReceived?.bodyText,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag: result.flag === true ? true : callbackReceivedBodyText.flag,
                messages: [...result.messages, ...callbackReceivedBodyText.messages],
            };
            const callbackReceivedConnectResult = callBackToValidate({
                data: nodeContent?.liveAgentWhenCallbackReceived?.buttons
                    ?.connectToLiveAgent?.value,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag: result.flag === true ? true : callbackReceivedConnectResult.flag,
                messages: [
                    ...result.messages,
                    ...callbackReceivedConnectResult.messages,
                ],
            };
            const callbackReceivedSkipResult = callBackToValidate({
                data: nodeContent?.liveAgentWhenCallbackReceived?.buttons
                    ?.skipLiveAgentCallback?.value,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag: result.flag === true ? true : callbackReceivedSkipResult.flag,
                messages: [...result.messages, ...callbackReceivedSkipResult.messages],
            };

            break;
        }

        case "saveRecipientDataNode": {
            if (
                currentNode.data.contents &&
                currentNode.data.contents.recipientInfo
            ) {
                const recipientInfo = currentNode.data.contents.recipientInfo;
                result = callBackToValidate({
                    data: recipientInfo?.fullname,
                    variableList,
                    acceptVariableType: ["string"],
                    isInputVariableOrOneVariable: true,
                });

                const emailVariableValidationResult = callBackToValidate({
                    data: recipientInfo?.email,
                    variableList,
                    acceptVariableType: ["string"],
                    isInputVariableOrOneVariable: true,
                });
                result = {
                    flag:
                        result.flag === true ? true : emailVariableValidationResult.flag,
                    messages: [
                        ...result.messages,
                        ...emailVariableValidationResult.messages,
                    ],
                };

                const phoneNumberVariableValidationResult = callBackToValidate({
                    data: recipientInfo?.phoneNumber,
                    variableList,
                    acceptVariableType: ["string", "number"],
                    isInputVariableOrOneVariable: true,
                });
                result = {
                    flag:
                        result.flag === true
                            ? true
                            : phoneNumberVariableValidationResult.flag,
                    messages: [
                        ...result.messages,
                        ...phoneNumberVariableValidationResult.messages,
                    ],
                };
            }
            break;
        }

        case "variableAssignmentNode": {
            if (
                currentNode.data.contents &&
                currentNode.data.contents.variableAssign
            ) {
                const variableAssign = currentNode.data.contents.variableAssign;
                for (let i = 0; i < variableAssign.length; i++) {
                    if (variableAssign[i].targetVariable) {
                        const targetVariable = variableList.find(
                            (variable) =>
                                variable.display === variableAssign[i].targetVariable
                        );
                        const targetVariableResult = callBackToValidate({
                            data: variableAssign[i].targetVariable,
                            variableList,
                        });
                        result = {
                            flag: result.flag === true ? true : targetVariableResult.flag,
                            messages: [...result.messages, ...targetVariableResult.messages],
                        };
                        let acceptVariableType = [];
                        if (targetVariable && targetVariable.variableType) {
                            acceptVariableType.push(targetVariable.variableType);
                        }
                        const sourceVariableResult = callBackToValidate({
                            data: variableAssign[i].sourceVariable,
                            variableList,
                            acceptVariableType,
                            isInputVariableOrOneVariable: true,
                        });
                        result = {
                            flag: result.flag === true ? true : sourceVariableResult.flag,
                            messages: [...result.messages, ...sourceVariableResult.messages],
                        };
                    }
                }
            }
            break;
        }

        case "constantAssignmentCard": {
            if (
                currentNode.data.contents &&
                currentNode.data.contents.variableAssignArray
            ) {
                const variableAssignArray =
                    currentNode.data.contents.variableAssignArray;
                for (let i = 0; i < variableAssignArray.length; i++) {
                    // First check if variables is deleted
                    // Check if variables values is satisfied with given value
                    // Check if variable is recreated ( deleted and created).

                    const variableNameResult = callBackToValidate({
                        data: variableAssignArray[i].variable,
                        variableList,
                        acceptVariableType: [variableAssignArray[i].variableType],
                    });
                    result = {
                        flag: result.flag === true ? true : variableNameResult.flag,
                        messages: [...result.messages, ...variableNameResult.messages],
                    };
                    const variableValueResult = callBackToValidate({
                        data: variableAssignArray[i].variableValue,
                        variableList,
                    });
                    result = {
                        flag: result.flag === true ? true : variableValueResult.flag,
                        messages: [...result.messages, ...variableValueResult.messages],
                    };
                }
            }
            break;
        }

        case "scriptNode": {
            result = callBackToValidate({
                data: currentNode.data.contents.code,
                variableList,
            });
            break;
        }

        case "documentCognitionCard": {
            result = callBackToValidate({
                data: currentNode.data.contents.initialMessage,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            break;
        }

        case "carouselNode": {
            if (
                Array.isArray(currentNode.data.contents.carousels) &&
                currentNode.data.contents.carousels.length > 0
            ) {
                currentNode.data.contents.carousels.forEach((carousel) => {
                    result = callBackToValidate({
                        data: carousel.title,
                        variableList,
                        acceptVariableType: ["string", "number", "boolean"],
                    });
                    const bodyTextResult = callBackToValidate({
                        data: carousel.bodyText,
                        variableList,
                        acceptVariableType: ["string", "number", "boolean"],
                    });
                    result = {
                        flag: result.flag === true ? true : bodyTextResult.flag,
                        messages: [...result.messages, ...bodyTextResult.messages],
                    };
                    if (Array.isArray(carousel.buttons) && carousel.buttons.length > 0) {
                        carousel.buttons.forEach((button) => {
                            const buttonResult = callBackToValidate({
                                data: button.buttonName,
                                variableList,
                                acceptVariableType: ["string", "number", "boolean"],
                            });
                            result = {
                                flag: result.flag === true ? true : buttonResult.flag,
                                messages: [...result.messages, ...buttonResult.messages],
                            };
                        });
                    }
                });
            }
            break;
        }

        case "responseCard": {
            const contents = currentNode.data.contents;
            result = callBackToValidate({
                data: contents?.response,
                variableList,
                acceptVariableType: ["string", "number", "boolean", "array", "object"],
                isInputVariableOrOneVariable: true,
            });
            if (
                contents.isResponseStatusCodeByVariable &&
                contents.isResponseStatusCodeByVariable === true
            ) {
                const statusCodeResult = callBackToValidate({
                    data: contents.responseStatusCode,
                    variableList,
                    acceptVariableType: ["string", "number"],
                    isInputVariableOrOneVariable: true,
                });
                result = {
                    flag: result.flag === true ? true : statusCodeResult.flag,
                    messages: [...result.messages, ...statusCodeResult.messages],
                };
            }
            if (
                contents.isResponseContentTypeByVariable &&
                contents.isResponseContentTypeByVariable === true
            ) {
                const contentTypeResult = callBackToValidate({
                    data: contents.responseContentType,
                    variableList,
                    acceptVariableType: ["string"],
                    isInputVariableOrOneVariable: true,
                });
                result = {
                    flag: result.flag === true ? true : contentTypeResult.flag,
                    messages: [...result.messages, ...contentTypeResult.messages],
                };
            }
            break;
        }

        case "whatsAppFlowsNode": {
            const openButtonTextValidationResult = callBackToValidate({
                data: currentNode.data.contents.footerText,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag: result.flag === true ? true : openButtonTextValidationResult.flag,
                messages: [
                    ...result.messages,
                    ...openButtonTextValidationResult.messages,
                ],
            };

            const resultOfHeaderText = callBackToValidate({
                data: currentNode.data.contents.headerText,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag: result.flag === true ? true : resultOfHeaderText.flag,
                messages: [...result.messages, ...resultOfHeaderText.messages],
            };

            const resultOfBodyText = callBackToValidate({
                data: currentNode.data.contents.bodyText,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag: result.flag === true ? true : resultOfBodyText.flag,
                messages: [...result.messages, ...resultOfBodyText.messages],
            };

            const resultOfFooterText = callBackToValidate({
                data: currentNode.data.contents.footerText,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag: result.flag === true ? true : resultOfFooterText.flag,
                messages: [...result.messages, ...resultOfFooterText.messages],
            };

            break;
        }

        case "whatsAppTemplateNode": {
            const openButtonTextValidationResult = callBackToValidate({
                data: currentNode.data.contents.footerText,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag: result.flag === true ? true : openButtonTextValidationResult.flag,
                messages: [
                    ...result.messages,
                    ...openButtonTextValidationResult.messages,
                ],
            };

            const resultOfHeaderText = callBackToValidate({
                data: currentNode.data.contents.headerText,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag: result.flag === true ? true : resultOfHeaderText.flag,
                messages: [...result.messages, ...resultOfHeaderText.messages],
            };

            const resultOfBodyText = callBackToValidate({
                data: currentNode.data.contents.bodyText,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag: result.flag === true ? true : resultOfBodyText.flag,
                messages: [...result.messages, ...resultOfBodyText.messages],
            };

            const resultOfFooterText = callBackToValidate({
                data: currentNode.data.contents.footerText,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag: result.flag === true ? true : resultOfFooterText.flag,
                messages: [...result.messages, ...resultOfFooterText.messages],
            };

            break;
        }

        // suggest variables based on variable type
        case "whatsAppPaymentNode": {
            const resultOfHeaderText = callBackToValidate({
                data: currentNode.data.contents.headerText,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag: result.flag === true ? true : resultOfHeaderText.flag,
                messages: [...result.messages, ...resultOfHeaderText.messages],
            };

            const resultOfBodyText = callBackToValidate({
                data: currentNode.data.contents.bodyText,
                variableList,
                acceptVariableType: ["string", "number", "boolean", "array"],
            });
            result = {
                flag: result.flag === true ? true : resultOfBodyText.flag,
                messages: [...result.messages, ...resultOfBodyText.messages],
            };

            const resultOfFooterText = callBackToValidate({
                data: currentNode.data.contents.footerText,
                variableList,
                acceptVariableType: ["string", "number", "boolean"],
            });
            result = {
                flag: result.flag === true ? true : resultOfFooterText.flag,
                messages: [...result.messages, ...resultOfFooterText.messages],
            };
            // const resultOfpaymentConfiguration = callBackToValidate({
            //     data: currentNode.data.contents.paymentConfiguration,
            //     variableList,
            //     acceptVariableType: ["boolean"],
            // });
            // result = {
            //     flag: result.flag === true ? true : resultOfpaymentConfiguration.flag,
            //     messages: [
            //         ...result.messages,
            //         ...resultOfpaymentConfiguration.messages,
            //     ],
            // };

            const resultOfPaymentItemsByVariables = callBackToValidate({
                data: currentNode.data.contents.itemsByVariable,
                variableList,
                acceptVariableType: ["object"],
            });
            result = {
                flag:
                    result.flag === true ? true : resultOfPaymentItemsByVariables.flag,
                messages: [
                    ...result.messages,
                    ...resultOfPaymentItemsByVariables.messages,
                ],
            };
            const catalogId = callBackToValidate({
                data: currentNode.data.contents.catalogId,
                variableList,
                acceptVariableType: ["number", "string"],
            });
            result = {
                flag: result.flag === true ? true : catalogId.flag,
                messages: [...result.messages, ...catalogId.messages],
            };

            // varaible validation
            const discountDescription = callBackToValidate({
                data: currentNode.data.contents.discount.description,
                variableList,
                acceptVariableType: ["number", "string"],
            });
            result = {
                flag: result.flag === true ? true : discountDescription.flag,
                messages: [...result.messages, ...discountDescription.messages],
            };
            const shippingDescription = callBackToValidate({
                data: currentNode.data.contents.shipping.description,
                variableList,
                acceptVariableType: ["number", "string"],
            });
            result = {
                flag: result.flag === true ? true : shippingDescription.flag,
                messages: [...result.messages, ...shippingDescription.messages],
            };
            const responseSuccesss = callBackToValidate({
                data: currentNode.data.contents.response.successResponse,
                variableList,
                acceptVariableType: ["number", "string"],
            });
            result = {
                flag: result.flag === true ? true : responseSuccesss.flag,
                messages: [...result.messages, ...responseSuccesss.messages],
            };
            const responseFailureResponse = callBackToValidate({
                data: currentNode.data.contents.response.failureResponse,
                variableList,
                acceptVariableType: ["number", "string"],
            });
            result = {
                flag: result.flag === true ? true : responseFailureResponse.flag,
                messages: [...result.messages, ...responseFailureResponse.messages],
            };

            const expirationDescription = callBackToValidate({
                data: currentNode.data.contents.expiration.description,
                variableList,
                acceptVariableType: ["number", "string"],
            });
            result = {
                flag: result.flag === true ? true : expirationDescription.flag,
                messages: [...result.messages, ...expirationDescription.messages],
            };

            break;
        }

        default:
            // console.log("TODO : unhandled error");
            break;
    }

    if (currentNode.data.outputVariable) {
        let resultOfOutputVariable = {};
        // Here the jsonMapper card is only card which output variable type can be object or array based on input variable type cause that this check is neccessary
        //
        if (
            currentNode.type === "jsonMapperCard" &&
            currentNode.data.contents.withSourceData === true &&
            currentNode.data.contents.inputObjectArrayVariable &&
            currentNode.data.contents.inputObjectArrayVariable.variableType
        ) {
            resultOfOutputVariable = callBackToValidate({
                data: currentNode.data.outputVariable.variableName,
                variableList,
                acceptVariableType: [
                    currentNode.data.contents.inputObjectArrayVariable.variableType,
                ],
                variableTypeErrorMessageOnlyForOneVariable: `Output variable ${currentNode.data.outputVariable.variableName} must be of ${currentNode.data.contents.inputObjectArrayVariable.variableType} type.`,
                outputVariableNotFoundErrorMessage: `${currentNode.data.outputVariable.variableName} ${WorkflowErrors.OUTPUT_VARIABLE_NOT_DEFINED}`,
            });
        } else {
            resultOfOutputVariable = callBackToValidate({
                data: currentNode.data.outputVariable.variableName,
                variableList,
                acceptVariableType: [currentNode.data.outputVariable.variableType],
                variableTypeErrorMessageOnlyForOneVariable: `Output variable ${currentNode.data.outputVariable.variableName} must be of ${currentNode.data.outputVariable.variableType} type.`,
                outputVariableNotFoundErrorMessage: `Output variable ${currentNode.data.outputVariable.variableName} is not defined.`,
            });
        }
        // let outputVariableErrorMessagesArray = resultOfOutputVariable.messages.map((message) => ` ${currentNode.data.outputVariable.variableName} ${WorkflowErrors.OUTPUT_VARIABLE_NOT_DEFINED}`)
        result = {
            flag: result.flag === true ? true : resultOfOutputVariable.flag,
            messages: [...result.messages, ...resultOfOutputVariable.messages],
        };
    }

    return result;
};

const getContentAndValidateForJsonMapper = (
    property,
    result,
    variableList,
    callBackToValidate
) => {
    let newResult = { flag: false, messages: [] };
    if (property.type === "object" && property.byVariable === false) {
        for (let i = 0; i < property.propertyArray.length; i++) {
            const propertyArrayResult = getContentAndValidateForJsonMapper(
                property.propertyArray[i],
                newResult,
                variableList,
                callBackToValidate
            );
            newResult = {
                flag: newResult.flag === true ? true : propertyArrayResult.flag,
                messages: [...newResult.messages, ...propertyArrayResult.messages],
            };
        }
    } else {
        const propertyResult = callBackToValidate({
            data: property.from,
            variableList,
        });
        newResult = {
            flag: newResult.flag === true ? true : propertyResult.flag,
            messages: [...newResult.messages, ...propertyResult.messages],
        };
    }
    return newResult;
};
