export const generateNodeValidation = (nodeType) => {
    let validation = {
        validate: false,
        validationType: "string",
        maxRepetition: 5,
        errorMessage: "",
        isOnErrorHandleConnected: false,
        mediaInput: ""
    };

    switch (nodeType) {
        case "questionNode": {
            validation.validate = true;
            break;
        }

        case "listNode":
            validation.validate = true;
            validation.validationType = "clickable"
            break;

        case "buttonNode":
            validation.validate = true;
            validation.validationType = "clickable"
            break;

        case "mediaInputNode":
            validation.validate = true;
            validation.validationType = "mediaInput"
            break;


        case "locationInputNode":
            validation.validate = true;
            validation.validationType = "location"
            break;

        case "dynamicButtonNode":
            validation.validate = true;
            validation.validationType = "clickable"
            break;

        case "dynamicListNode":
            validation.validate = true;
            validation.validationType = "clickable"
            break;

        case "whatsAppFlowsNode":
            validation.validate = true;
            validation.validationType = "clickable"
            break;

        case "whatsAppTemplateNode":
            validation.validate = true;
            validation.validationType = "clickable"
            break;
        case "whatsAppPaymentNode":
            validation.validate = true;
            validation.validationType = "clickable"
            break;
        case "whatsAppCatalogNode":
            validation.validate = true;
            validation.validationType = "clickable";
            break;
        default:
            validation = {};
            break;
    }


    return validation;

};

export const generateNodeTimeout = (type) => {
    let inputTimeout = {
        compulsoryTimeout: false,
        enabled: false,
        duration: { seconds: 0, minutes: 1, hours: 0, days: 0 },
        durationInSeconds: 60 + 5, // assuming bot will take 5 secs to process all its DB calls after input card is processed, instead of making change in CBE we are sending +5 from here.  
        errorMessage: "",
        isTimeoutHandleConnected: false,
    }
    switch (type) {
        case 'httpTriggerNode': {
            inputTimeout.compulsoryTimeout = true;
            inputTimeout.enabled = true;
            break;
        }

        // case 'whatsAppFlowsNode': {
        //     inputTimeout.compulsoryTimeout = true;
        //     inputTimeout.enabled = true;
        //     break;
        // }

        default:
            inputTimeout.compulsoryTimeout = false;
            break;
    }
    return inputTimeout;
};
