import { BsMessenger, BsWhatsapp } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import { RiComputerLine, RiFacebookLine, RiInstagramLine, RiMailAddLine, RiMessage2Line } from "react-icons/ri";
import browserIcon from "../assets/IMG/channels/browser.png";
import facebookIcon from "../assets/IMG/channels/facebook.png";
import telegramIcon from "../assets/IMG/channels/telegram.png";
import whatsappIcon from "../assets/IMG/channels/whatsapp.png";
// import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
// import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';

export const getChannelIdByChannelName = (name) => {
  switch (name.toLowerCase()) {
    case "whatsapp":
    case "whatsapp_pinnacle_bsp":
    case "whatsapp pinnacle bsp":
      return "whatsapp";
    case "facebook":
    case "facebook_messenger":
    case "facebook messenger":
      return "facebook_messenger";
    case "instagram":
    case "instagram_messenger":
    case "instagram messenger":
      return "instagram_messenger";
    case "telegram":
      return "telegram";
    case "tubulu":
      return "tubulu";
    case "webchat":
    case "website chat":
      return "webchat";
    case "email":
      return "email";
    case "sms":
      return "sms";
    case "live agent":
    case "liveagent":
      return "liveagent";
    case "voice":
      return "voice";
    case "slack":
      return "slack";
    case "microsoft teams":
    case "teams":
      return "teams";
    case "viber":
      return "viber";
    case "line":
      return "line";
    default:
      return '';
  }
};
export const getChannelNameByChannelId = (channelId) => {
  switch (channelId) {
    case 1:
      return "whatsapp";
    case 2:
      return "facebook_messenger";
    case 3:
      return "telegram";
    case 4:
      return "tubulu";
    case 5:
      return "webchat";
    case 6:
      return "email";
    case 7:
      return "sms"
    case 8:
      return "instagram_messenger";
    default:
      return "webchat";
  }
};

export const getChannelIconById = (channelId) => {
  switch (channelId) {
    case "whatsapp":
    case "whatsapp_pinnacle_bsp":
    case "whatsapp pinnacle bsp":
    case 1:
      // return <WhatsAppIcon width={'20px'} height={'20px'} fillColor={'#000000'} />;
      return <img alt="" style={{ height: "16px" }} src={whatsappIcon} />;
    case "facebook_messenger":
    case "facebook messenger":
    case "facebook":
    case 2:
      // return <MessengerIcon width={'16px'} height={'16px'} fillColor={'#000000'} />;
      return <img alt="" style={{ height: "16px" }} src={facebookIcon} />;
    case "telegram":
    case 3:
      // return <TelegramIcon width={'16px'} height={'16px'} fillColor={'#000000'} />;
      return <img alt="" style={{ height: "16px" }} src={telegramIcon} />;
    case "tubulu":
    case 4:
      return <img alt="" style={{ height: "16px" }} src={browserIcon} />;
    case "webchat":
    case 5:
      return <RiComputerLine size={16} />;
    case "email":
    case 6:
      return <RiMailAddLine size={16} />
    case "sms":
    case 7:
      return <RiMessage2Line size={16} />
    case 8:
    case "instagram":
    case "instagram_messenger":
    case "instagram messenger":
      return <RiInstagramLine size={16} />
    default:
      return <img alt="" style={{ height: "16px" }} src={browserIcon} />;
  }
};

// export const ShowActiveChannels = (channels) => {
//   let arr = [];
//   for (const channel of channels) {
//     if (channel.toLowerCase() === "tubulu")
//       arr.push(<RiComputerLine size={18} />);
//     if (channel.toLowerCase() === "webchat" || channel.toLowerCase() === "web chat")
//       arr.push(<RiComputerLine size={18} />);
//     if (channel.toLowerCase() === "facebook" || channel.toLowerCase() === "facebook_messenger" || channel.toLowerCase() === "facebook messenger")
//       arr.push(<RiFacebookLine size={18} color="#006AFF" />);
//     if (channel.toLowerCase() === "facebook")
//       arr.push(<BsMessenger size={18} color="#006AFF" />);
//     if (channel.toLowerCase() === "whatsapp" || channel.toLowerCase() === "whatsapp_pinnacle_bsp" || channel.toLowerCase() === "whatsapp pinnacle bsp")
//       arr.push(<BsWhatsapp size={18} color="green" />);
//     if (channel.toLowerCase() === "telegram")
//       arr.push(<FaTelegramPlane size={18} color="#0088CC" />);
//     if (channel.toLowerCase() === 'email')
//       arr.push(<RiMailAddLine size={18} />)
//     if (channel.toLowerCase() === 'sms')
//       arr.push(<RiMessage2Line size={18} />)
//     if (channel.toLowerCase() === 'instagram' || channel.toLowerCase() === 'instagram_messenger' || channel.toLowerCase() === 'instagram messenger')
//       arr.push(<RiInstagramLine size={18} />)
//     arr.push(<MailOutlineRoundedIcon size={18} />)
//     if (channel.toLowerCase() === 'sms')
//       arr.push(<SmsOutlinedIcon size={18} />)
//   }
//
//   return (
//     <Stack direction="row" spacing={2}>
//       {arr.map((channel, index) => (
//         <span key={index}>{channel}</span>
//       ))}
//     </Stack>
//   );
// };

export const getChannelIconByName = (channelName) => {
  switch (channelName.toLowerCase()) {
    case "tubulu":
      return <RiComputerLine size={18} />;
    case "webchat":
      return <RiComputerLine size={18} />;
    case "facebook":
    case "facebook_messenger":
    case "facebook messenger":
      return <RiFacebookLine size={18} color="#006AFF" />;
    case "messenger":
      return <BsMessenger size={18} color="#006AFF" />;
    case "whatsapp":
    case "whatsapp_pinnacle_bsp":
    case "whatsapp pinnacle bsp":
      return <BsWhatsapp size={18} color="green" />;
    case "telegram":
      return <FaTelegramPlane size={18} color="#0088CC" />;
    case "email":
      return <RiMailAddLine size={18} />;
    case "sms":
      return <RiMessage2Line size={18} />
    case "instagram":
    case "instagram_messenger":
    case "instagram messenger":
      return <RiInstagramLine size={18} />
    default:
      return <RiComputerLine size={18} />;
  }
};

const channelsWhichNotAllowedForChannelSwitchNode = ['email', 'sms', 'liveAgent', 'liveagent', 'live agent', 'voice'];

export const checkIsChannelValidForChannelSwitchNode = (channelId) => {
  if (!channelsWhichNotAllowedForChannelSwitchNode.includes(channelId)) {
    return true;
  }
  return false;
};
