//

export function getColorThemeForCardType(nodeType, theme) {
  const nodeTheme = theme.cards[nodeType];
  if (nodeTheme) {
    return nodeTheme;
  } else {
    const defaultTheme = theme.cards["defaultNodeTheme"];
    if (defaultTheme) {
      return defaultTheme;
    } else {
      return {
        cardColor: "#ddd", // this default is for development purpose in production it will not come
        headerText: "#333",
        bodyText: "grey",
      };
    }
  }
}

export function getTitleBasedOnNodeType(nodeType, version, title) {
  if (title && nodeType === NODE_TYPES.CONNECTOR_NODE) {
    return title;
  }

  const node = cardList.find((node) => node.type === nodeType);
  if (node) {
    return version === "1.0" ? node.title : `${node.title} ( V_${version} )`;
  } else {
    return "Node";
  }
}

// Since we have started versioning of cards from scriptNode only scriptNode is handled below...
export const CardVersions = {
  cardVersionsDictionary: {
    scriptNode: {
      currentVersion: {
        number: "2.0",
        description: "Script node with additional libraries and on-error handler.",
        obsolete: false,
      },
      previousVersions: [{ number: "1.0", description: "", obsolete: false }],
    },
    webhookNode: {
      currentVersion: {
        number: "2.0",
        description: "Webhook node with support of additional request body types",
        obsolete: false,
      },
      previousVersions: [{ number: "1.0", description: "", obsolete: false }],
    },
  },

  getPreviousVersions: (nodeType) => {
    const nodeData = CardVersions.cardVersionsDictionary[nodeType];
    return nodeData.previousVersions;
  },

  getCurrentVersion: (nodeType) => {
    const nodeData = CardVersions.cardVersionsDictionary[nodeType];
    return nodeData.currentVersion;
  },

  getVersionsListByNodeType: (nodeType) => {
    const nodeData = CardVersions.cardVersionsDictionary[nodeType];
    return Object.keys(nodeData.versions);
  },
};

export const NODE_TYPES = {
  PLAIN_MESSAGE_TEXT_NODE: "plainMessageTextNode",
  QUESTION_NODE: "questionNode",
  LIST_NODE: "listNode",
  BUTTON_NODE: "buttonNode",
  MEDIA_LIST_NODE: "mediaListNode",
  CAROUSEL_NODE: "carouselNode",
  MEDIA_NODE: "mediaNode",
  MEDIA_INPUT_NODE: "mediaInputNode",
  WEBHOOK_NODE: "webhookNode",
  CONDITION_NODE: "conditionNode",
  SWITCH_CONDITION_NODE: "switchConditionNode",
  ITERATION_GUARD_NODE: "iterationGuardNode",
  LOOPBACK_NODE: "loopBackNode",
  CHANNEL_SWITCH_NODE: "channelSwitchNode",
  SUB_FLOW_NODE: "subFlowNode",
  SCRIPT_NODE: "scriptNode",
  LOCATION_OUTPUT_NODE: "locationOutputNode",
  LOCATION_INPUT_NODE: "locationInputNode",
  DYNAMIC_BUTTON_NODE: "dynamicButtonNode",
  DYNAMIC_LIST_NODE: "dynamicListNode",
  SEND_SMS_CARD: "sendSMSCard",
  SEND_EMAIL_CARD: "sendEmailCard",
  JSON_MAPPER_CARD: "jsonMapperCard",
  LIVE_AGENT_NODE: "liveAgentNode",
  GET_RECIPIENT_DATA_NODE: "getRecipientDataNode",
  SAVE_RECIPIENT_DATA_NODE: "saveRecipientDataNode",
  VARIABLE_ASSIGNMENT_NODE: "variableAssignmentNode",
  CONSTANT_ASSIGNMENT_CARD: "constantAssignmentCard",
  DOCUMENT_COGNITION_CARD: "documentCognitionCard",
  RESPONSE_CARD: "responseCard",
  BROADCAST_NODE: "broadcastNode",
  HTTP_TRIGGER_NODE: "httpTriggerNode",
  REGISTER_HTTP_TRIGGER_NODE: "registerHttpTriggerNode",
  RABBIT_MQ_NODE: "rabbitMqNode",
  BROADCAST_SMS_NODE: "broadcastSMSNode",
  SET_LOCALE_NODE: "setLocaleNode",
  WHATSAPP_FLOWS_NODE: "whatsAppFlowsNode",
  MESSAGING_SERVICE_NODE: "messagingServiceNode",
  WHATSAPP_TEMPLATE_NODE: "whatsAppTemplateNode",
  WHATSAPP_PAYMENT_NODE: "whatsAppPaymentNode",
  WHATSAPP_CATALOG_NODE:"whatsAppCatalogNode"
}

const allCardsObject = {
  plainMessageTextNode: {
    type: NODE_TYPES.PLAIN_MESSAGE_TEXT_NODE,
    nodeType: NODE_TYPES.PLAIN_MESSAGE_TEXT_NODE,
    description: "",
    title: "Text Message",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: false,
  },
  questionNode: {
    type: NODE_TYPES.QUESTION_NODE,
    nodeType: NODE_TYPES.QUESTION_NODE,
    description: "",
    title: "Question",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: false,
  },
  listNode: {
    type: NODE_TYPES.LIST_NODE,
    title: "List Node",
    nodeType: NODE_TYPES.LIST_NODE,
    description: "",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: false,
  },
  buttonNode: {
    type: NODE_TYPES.BUTTON_NODE,
    title: "Button Node",
    nodeType: NODE_TYPES.BUTTON_NODE,
    description: "",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: false,
  },
  mediaListNode: {
    type: NODE_TYPES.MEDIA_LIST_NODE,
    title: "Dynamic List of Media",
    nodeType: NODE_TYPES.MEDIA_LIST_NODE,
    description: "",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: false,
  },
  carouselNode: {
    type: NODE_TYPES.CAROUSEL_NODE,
    title: "Carousel",
    nodeType: NODE_TYPES.CAROUSEL_NODE,
    description: "",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: false,
  },
  mediaNode: {
    type: NODE_TYPES.MEDIA_NODE,
    title: "Media Output",
    nodeType: NODE_TYPES.MEDIA_NODE,
    description: "",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: true,
  },
  mediaInputNode: {
    type: NODE_TYPES.MEDIA_INPUT_NODE,
    title: "Media Input",
    nodeType: NODE_TYPES.MEDIA_INPUT_NODE,
    description: "",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: false,
  },
  webhookNode: {
    type: NODE_TYPES.WEBHOOK_NODE,
    title: "HTTP Request",
    nodeType: NODE_TYPES.WEBHOOK_NODE,
    description: "",
    currentVersion: CardVersions.getCurrentVersion(NODE_TYPES.WEBHOOK_NODE),
    previousVersions: CardVersions.getPreviousVersions(NODE_TYPES.WEBHOOK_NODE),
    resetNodeContentOnImport: false,
  },
  conditionNode: {
    type: NODE_TYPES.CONDITION_NODE,
    title: "Condition",
    nodeType: NODE_TYPES.CONDITION_NODE,
    description: "",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: false,
  },
  switchConditionNode: {
    type: NODE_TYPES.SWITCH_CONDITION_NODE,
    title: "Switch",
    nodeType: NODE_TYPES.SWITCH_CONDITION_NODE,
    description: "",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: false,
  },
  iterationGuardNode: {
    type: NODE_TYPES.ITERATION_GUARD_NODE,
    title: "Iteration Guard",
    nodeType: NODE_TYPES.ITERATION_GUARD_NODE,
    description: "",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: false,
  },
  loopBackNode: {
    type: NODE_TYPES.LOOPBACK_NODE,
    title: "Loop Back",
    nodeType: NODE_TYPES.LOOPBACK_NODE,
    description: "",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: false,
  },
  channelSwitchNode: {
    type: NODE_TYPES.CHANNEL_SWITCH_NODE,
    title: "Channel Switch",
    nodeType: NODE_TYPES.CHANNEL_SWITCH_NODE,
    description: "",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: false,
  },
  subFlowNode: {
    type: NODE_TYPES.SUB_FLOW_NODE,
    title: "Sub Workflow",
    nodeType: NODE_TYPES.SUB_FLOW_NODE,
    description: "",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: true,
  },
  scriptNode: {
    type: NODE_TYPES.SCRIPT_NODE,
    title: `Script`,
    nodeType: NODE_TYPES.SCRIPT_NODE,
    description: "",
    currentVersion: CardVersions.getCurrentVersion("scriptNode"),
    previousVersions: CardVersions.getPreviousVersions("scriptNode"),
    resetNodeContentOnImport: false,
  },
  locationOutputNode: {
    type: NODE_TYPES.LOCATION_OUTPUT_NODE,
    title: "Location Output",
    nodeType: NODE_TYPES.LOCATION_OUTPUT_NODE,
    description: "",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: false,
  },
  locationInputNode: {
    type: NODE_TYPES.LOCATION_INPUT_NODE,
    title: "Location Input",
    nodeType: NODE_TYPES.LOCATION_INPUT_NODE,
    description: "",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: false,
  },
  dynamicButtonNode: {
    type: NODE_TYPES.DYNAMIC_BUTTON_NODE,
    title: "Dynamic Button Node",
    nodeType: NODE_TYPES.DYNAMIC_BUTTON_NODE,
    description: "",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: false,
  },
  dynamicListNode: {
    type: NODE_TYPES.DYNAMIC_LIST_NODE,
    title: "Dynamic List Node",
    nodeType: NODE_TYPES.DYNAMIC_LIST_NODE,
    description: "",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: false,
  },
  sendSMSCard: {
    type: NODE_TYPES.SEND_SMS_CARD,
    title: "Send SMS",
    nodeType: NODE_TYPES.SEND_SMS_CARD,
    description: "",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: true,
  },
  sendEmailCard: {
    type: NODE_TYPES.SEND_EMAIL_CARD,
    title: "Send Email",
    nodeType: NODE_TYPES.SEND_EMAIL_CARD,
    description: "",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: true,
  },
  jsonMapperCard: {
    type: NODE_TYPES.JSON_MAPPER_CARD,
    title: "Data Transformation",
    nodeType: NODE_TYPES.JSON_MAPPER_CARD,
    description: "",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: false,
  },
  liveAgentNode: {
    type: NODE_TYPES.LIVE_AGENT_NODE,
    nodeType: NODE_TYPES.LIVE_AGENT_NODE,
    description: "",
    title: "Live Agent Connect",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: false,
  },
  getRecipientDataNode: {
    type: NODE_TYPES.GET_RECIPIENT_DATA_NODE,
    nodeType: NODE_TYPES.GET_RECIPIENT_DATA_NODE,
    description: "",
    title: "Get Recipient Data",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: false,
  },
  saveRecipientDataNode: {
    type: NODE_TYPES.SAVE_RECIPIENT_DATA_NODE,
    nodeType: NODE_TYPES.SAVE_RECIPIENT_DATA_NODE,
    description: "",
    title: "Save Recipient Data",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: false,
  },
  variableAssignmentNode: {
    type: NODE_TYPES.VARIABLE_ASSIGNMENT_NODE,
    nodeType: NODE_TYPES.VARIABLE_ASSIGNMENT_NODE,
    description: "",
    title: "Variable Assignment Node",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: false,
  },
  constantAssignmentCard: {
    type: NODE_TYPES.CONSTANT_ASSIGNMENT_CARD,
    nodeType: NODE_TYPES.CONSTANT_ASSIGNMENT_CARD,
    description: "",
    title: "Constant Assignment Card",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false
    },
    previousVersions: [],
    resetNodeContentOnImport: false,
  },
  documentCognitionCard: {
    type: NODE_TYPES.DOCUMENT_COGNITION_CARD,
    nodeType: NODE_TYPES.DOCUMENT_COGNITION_CARD,
    description: "",
    title: "Docuwise Card",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false
    },
    previousVersions: [],
    resetNodeContentOnImport: true,
  },
  responseCard: {
    type: NODE_TYPES.RESPONSE_CARD,
    nodeType: NODE_TYPES.RESPONSE_CARD,
    description: "",
    title: "Response Card",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false
    },
    previousVersions: [],
    resetNodeContentOnImport: false,
  },
  broadcastNode: {
    type: NODE_TYPES.BROADCAST_NODE,
    nodeType: NODE_TYPES.BROADCAST_NODE,
    description: "",
    title: "Broadcast",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false
    },
    previousVersions: [],
    resetNodeContentOnImport: true,
  },
  httpTriggerNode: {
    type: NODE_TYPES.HTTP_TRIGGER_NODE,
    nodeType: NODE_TYPES.HTTP_TRIGGER_NODE,
    description: "",
    title: "HTTP Trigger",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false
    },
    previousVersions: [],
    resetNodeContentOnImport: true,
  },
  registerHttpTriggerNode: {
    type: NODE_TYPES.REGISTER_HTTP_TRIGGER_NODE,
    nodeType: NODE_TYPES.REGISTER_HTTP_TRIGGER_NODE,
    description: "",
    title: "Register HTTP Trigger",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false
    },
    previousVersions: [],
    resetNodeContentOnImport: true,
  },

  rabbitMqNode: {
    type: NODE_TYPES.RABBIT_MQ_NODE,
    nodeType: NODE_TYPES.RABBIT_MQ_NODE,
    description: "Add to RabbitMQ Queue",
    title: "RabbitMQ Node",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false
    },
    previousVersions: [],
    resetNodeContentOnImport: false,
  },
  broadcastSMSNode: {
    type: NODE_TYPES.BROADCAST_SMS_NODE,
    nodeType: NODE_TYPES.BROADCAST_SMS_NODE,
    description: "Add to Broadcast SMS Node",
    title: "Broadcast SMS",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false
    },
    previousVersions: [],
    resetNodeContentOnImport: true,
  },
  setLocaleNode: {
    type: NODE_TYPES.SET_LOCALE_NODE,
    nodeType: NODE_TYPES.SET_LOCALE_NODE,
    description: "Set Locale at runtime.",
    title: "Set Locale",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false
    },
    previousVersions: [],
    resetNodeContentOnImport: true,
  },
  whatsAppFlowsNode: {
    type: NODE_TYPES.WHATSAPP_FLOWS_NODE,
    nodeType: NODE_TYPES.WHATSAPP_FLOWS_NODE,
    description: "",
    title: "WhatsApp Flows",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: true,
  },
  messagingServiceNode: {
    type: NODE_TYPES.MESSAGING_SERVICE_NODE,
    nodeType: NODE_TYPES.MESSAGING_SERVICE_NODE,
    description: "Add to BroadcastWithRabbitMq Node",
    title: "Messaging Service",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false
    },
    previousVersions: [],
    resetNodeContentOnImport: true,
  },

  whatsAppTemplateNode: {
    type: NODE_TYPES.WHATSAPP_TEMPLATE_NODE,
    nodeType: NODE_TYPES.WHATSAPP_TEMPLATE_NODE,
    description: "",
    title: "WhatsApp Template",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: true,
  },

  whatsAppPaymentNode: {
    type: NODE_TYPES.WHATSAPP_PAYMENT_NODE,
    nodeType: NODE_TYPES.WHATSAPP_PAYMENT_NODE,
    description: "",
    title: "WhatsApp Payment",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: true,
  },
  //   previousVersions: [],
  // },
  connectorNode: {
    type: NODE_TYPES.CONNECTOR_NODE,
    nodeType: NODE_TYPES.CONNECTOR_NODE,
    description: "",
    title: "Connector Node",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: true,
  },

  whatsAppCatalogNode: {
    type: NODE_TYPES.WHATSAPP_CATALOG_NODE,
    nodeType: NODE_TYPES.WHATSAPP_CATALOG_NODE,
    description: "",
    title: "WhatsApp Catalog",
    currentVersion: {
      number: "1.0",
      description: "",
      obsolete: false,
    },
    previousVersions: [],
    resetNodeContentOnImport: true,
  },
};

export const cardList = Object.keys(allCardsObject).map(
  (card) => allCardsObject[card]
);

export const workflowCardList = [
  allCardsObject.plainMessageTextNode,
  allCardsObject.questionNode,
  allCardsObject.listNode,
  allCardsObject.buttonNode,
  allCardsObject.mediaListNode,
  allCardsObject.carouselNode,
  allCardsObject.mediaNode,
  allCardsObject.mediaInputNode,
  allCardsObject.webhookNode,
  allCardsObject.conditionNode,
  allCardsObject.switchConditionNode,
  allCardsObject.iterationGuardNode,
  allCardsObject.loopBackNode,
  allCardsObject.channelSwitchNode,
  allCardsObject.subFlowNode,
  allCardsObject.scriptNode,
  allCardsObject.locationOutputNode,
  allCardsObject.locationInputNode,
  allCardsObject.dynamicButtonNode,
  allCardsObject.dynamicListNode,
  allCardsObject.sendSMSCard,
  allCardsObject.sendEmailCard,
  allCardsObject.jsonMapperCard,
  allCardsObject.liveAgentNode,
  allCardsObject.getRecipientDataNode,
  allCardsObject.saveRecipientDataNode,
  allCardsObject.variableAssignmentNode,
  allCardsObject.constantAssignmentCard,
  allCardsObject.documentCognitionCard,
  allCardsObject.httpTriggerNode,
  allCardsObject.registerHttpTriggerNode,
  allCardsObject.setLocaleNode,
  allCardsObject.whatsAppFlowsNode,
  allCardsObject.whatsAppTemplateNode,
  allCardsObject.whatsAppPaymentNode,
  allCardsObject.whatsAppCatalogNode,
  // allCardsObject.connectorNode,
];

export const processWorkflowCardList = [
  allCardsObject.webhookNode,
  allCardsObject.conditionNode,
  allCardsObject.switchConditionNode,
  allCardsObject.scriptNode,
  allCardsObject.jsonMapperCard,
  allCardsObject.variableAssignmentNode,
  allCardsObject.constantAssignmentCard,
  allCardsObject.responseCard,
  //   allCardsObject.broadcastNode,
  allCardsObject.messagingServiceNode,
  // allCardsObject.rabbitMqNode,
  // allCardsObject.broadcastSMSNode,
  //   allCardsObject.connectorNode,
];

export const cardListService = {
  getRestNodeContentOnImportCardTypeListByParamBoolean: (checked) => {
    const resetNodeContentOnImportCards = cardList.filter(card => ![null, undefined].includes(card.resetNodeContentOnImport) && card.resetNodeContentOnImport === checked);
    return resetNodeContentOnImportCards.map(card => card.type);
  },
}
