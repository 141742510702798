import { createSlice } from "@reduxjs/toolkit";


const ProcessBroadcastGatewayReports = createSlice({
    name: "ProcessBroadcastGatewayReports",
    initialState: {
        data: [],
        totalCount: 0,
        totalRecords: 0,
        totalLoadRecords: 0
    },
    reducers: {
        SetProcessGatewayBroadcast: (state, action) => {

            // {
            //     "_id": "652e1f2a03b8ff0033e7f4b0",
            //     "correlationId": "46375639-2e58-463f-90d6-49e39d6796de",
            //     "processId": "651d6f37f58a6b00345107d9",
            //     "mobile": "14435912226",
            //     "status": "DELIVRD",
            //     "senderId": "ENCRCL",
            //     "channelId": "SMS",
            //     "isInternationalNumber": false,
            //     "createdAt": "2023-10-17T05:44:10.925Z",
            //     "dataFromWebhook": {
            //       "Type": "TXT",
            //       "SubmitDate": "17-10-2023 05:44:12",
            //       "DoneDate": "17-10-2023 05:44:17"
            //     }
            //   }
            // const convertedData = action.payload.userReports.map((item, index) => {
            //     debugger;
            //     const timestamp = new Date(item.createdAt ?? item.timestamp);
            //     const simplyfiedDate = `${renderNumberInStringFormat(timestamp.getDate())}/${renderNumberInStringFormat(timestamp.getMonth()) + 1}/${renderNumberInStringFormat(timestamp.getFullYear())}  ${renderNumberInStringFormat(timestamp.getHours())}:${renderNumberInStringFormat(timestamp.getMinutes())}`
            //     const submitDateObject = new Date(item.createdAt);
            //     const simplifiedSubmitDate = `${renderNumberInStringFormat(submitDateObject.getDate())}/${renderNumberInStringFormat(submitDateObject.getMonth()) + 1}/${renderNumberInStringFormat(submitDateObject.getFullYear())}  ${renderNumberInStringFormat(submitDateObject.getHours())}:${renderNumberInStringFormat(submitDateObject.getMinutes())}`
            //     let simplifiedDoneDate = "";
            //     if (item.dataFromWebhook && item.dataFromWebhook.DoneDate) {
            //         const doneDateObject = new Date(item.dataFromWebhook.DoneDate);
            //         simplifiedDoneDate = `${renderNumberInStringFormat(doneDateObject.getDate())}/${renderNumberInStringFormat(doneDateObject.getMonth()) + 1}/${renderNumberInStringFormat(doneDateObject.getFullYear())}  ${renderNumberInStringFormat(doneDateObject.getHours())}:${renderNumberInStringFormat(doneDateObject.getMinutes())}`
            //     }
            //     return {
            //         id: index + 1,
            //         correlationId: item.correlationId,
            //         processId: item.processId,
            //         system: item.callingSystem ?? "Test",
            //         status: item.status || 'Progress',
            //         triggerTime: simplyfiedDate,
            //         submitDate: simplifiedSubmitDate,
            //         doneDate: simplifiedDoneDate,
            //         messageCount: item.messagesCount ?? "0",
            //         action: "",
            //         senderId: item.senderId,
            //         mobile: item.mobile,
            //         channelId: item.channelId,
            //     }
            // })
            // state.data = convertedData;
            state.data = action.payload.userReports;
            state.totalCount = action.payload.totalReports;
            state.totalLoadRecords = state.totalLoadRecords + action.payload.userReports.length;
        },
        ResetGatewayReports: (state, action) => {
            state.data = [];
            state.totalCount = 0;
            state.totalRecords = 0;
            state.totalLoadRecords = 0;
        },
    },
});

export const { SetProcessGatewayBroadcast, ResetActivityLogs } = ProcessBroadcastGatewayReports.actions;

export default ProcessBroadcastGatewayReports.reducer;
