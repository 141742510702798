import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import ContactsTwoToneIcon from '@mui/icons-material/ContactsTwoTone';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import ExtensionTwoToneIcon from '@mui/icons-material/ExtensionTwoTone';
import LocalLibraryTwoToneIcon from '@mui/icons-material/LocalLibraryTwoTone';
import SchemaTwoToneIcon from '@mui/icons-material/SchemaTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';
import TrendingUpTwoToneIcon from '@mui/icons-material/TrendingUpTwoTone';

const pagesSection = [
    {
        href: "/dashboard",
        icon: <DashboardTwoToneIcon />,
        title: "Dashboard",
        permission_code: 8
    },
    {
        href: "/integrations",
        icon: <ExtensionTwoToneIcon />,
        title: "Integrations",
        permission_code: 16
    },
    {
        href: "/cognitive/documents",
        icon: <LocalLibraryTwoToneIcon />,
        title: "DocuWise",
        permission_code: 65536
    },
    // {
    //   href: "/additionalintegrations",
    //   icon: <ExtensionTwoToneIcon />,
    //   title: "Additional Integration",
    //   permission_code: 64
    // },
    {
        href: "/bots",
        icon: <SmartToyTwoToneIcon />,
        title: "Bots",
        permission_code: 32
    },
    // {
    //     href: "/broadcast",
    //     icon: <CellTowerTwoToneIcon />,
    //     title: "Broadcast",
    //     permission_code: 131072
    // },
    {
        href: "/process-container",
        icon: <AccountTreeTwoToneIcon />,
        title: "Process",
        permission_code: 64
    },
    // {
    //     href: "/campaigns",
    //     icon: <CampaignTwoToneIcon />,
    //     title: "Campaigns",
    //     permission_code: 8388608
    // },
    {
        href: "/whatsapp-flows",
        icon: <SchemaTwoToneIcon />,
        title: "WhatsApp Flow",
        permission_code: 262144
    },
    {
        href: "/users",
        icon: <ContactsTwoToneIcon />,
        title: "Users",
        permission_code: 128
    },
    {
        href: "/activity",
        icon: <TrendingUpTwoToneIcon />,
        title: "Reports",
        permission_code: 32768
    },
    {
        href: "/settings",
        icon: <SettingsTwoToneIcon />,
        title: "Settings",
        permission_code: 256
    },
];


const navItems = [
    {
        pages: pagesSection,
    }
];

export default navItems;
