import { IsStaticVariantContext } from "@mui/lab/internal/pickers/wrappers/WrapperVariantContext";
import WorkflowErrors from "../../utils/workflowErrors";
import { limitation } from "./limitation";
const {
    START_NODE_NOT_CONNECTED_ERROR,
    PLAIN_TEXT_NODE,
    QUESTION_NODE,
    BUTTON_NODE,
    LIST_NODE,
    MEDIA_NODE,
    MEDIA_INPUT_NODE,
    WEBHOOK_NODE,
    INPUT_VARIABLE_NOT_SELECTED_ERROR,
    NOT_VALID_VARIABLE,
    VARIABLE_ASSIGNMENT_NODE,
    CONSTANT_ASSIGNMENT_NODE,
    CONDITION_NODE,
    SWITCH_CONDITION_NODE,
    LOOP_BACK_NODE,
    ITERATION_GUARD_NODE,
    SUB_FLOW_NODE,
    LOCATION_OUTPUT_NODE,
    LOCATION_INPUT_NODE,
    DYNAMIC_BUTTON_NODE,
    DYNAMIC_LIST_NODE,
    DELAY_NODE,
    SEND_SMS_NODE,
    SEND_EMAIL_NODE,
    SAVE_RECIPIENT_DATA_NODE,
    CONTENT_NOT_FOUND_ERROR,
    LIVE_AGENT_NODE,
    DOCUMENT_COGNITION_NODE,
    CAROUSEL_NODE,
    RESPONSE_CARD,
    BROADCAST_NODE,
    REGISTER_HTTP_TRIGGER_NODE,
    BROADCAST_SMS_NODE,
    SET_LOCALE_NODE,
    BROADCAST_WITH_RABBITMQ_NODE,
    ZOHO_CRM
} = WorkflowErrors;

export const NodeValidationWithoutChannel = {

    validateStartNode: (data) => {
        let message = [];
        if (data.contents) {
            if (
                (data.contents.startNodeConnected !== undefined && data.contents.startNodeConnected === false)
                || (Array.isArray(data.contents) && data.contents.length === 0) // it will only for first time load of start node
            ) {
                message.push(START_NODE_NOT_CONNECTED_ERROR);
            }
        }

        // ---------------------------------- Validations For Start Card --------------------------------------------
        if (data.subWorkFlowKeyword) {
            for (let index = 0; index < data.subWorkFlowKeyword.length; index++) {
                const element = data.subWorkFlowKeyword[index];

                //error count greter than 0 
                if (
                    element.errorCount !== undefined &&
                    typeof element.errorCount === 'number' &&
                    element.errorCount > 0
                ) {
                    message.push(`Total ${element.errorCount} error${element.errorCount === 1 ? '' : 's'} in "${element.name}" sub workflow. If you have resolved errors but still see the errors then refresh the page.[Keyword Tab]`)
                }

                // Not Select Any Sub-workflow in Droupdown 
                if (element.uniqueId && element.workflowId === "") {
                    message.push(`Please select a sub-workflow with keywords.[Keyword Tab]`)
                }

                // select subWorkflow but not added keywords  
                if (element.workflowId && element.workflowId !== "" && element.keywords.length === 0) {
                    message.push(`Please add atleast one keyword for "${element.name}" sub-workflow [Keyword Tab].`)
                }
            }
        }
        return message;
    },

    validateTextMessage: (data) => {
        let result = { flag: false, messages: [] };
        let limitMessageText = limitation.nodes.plainMessageTextNode;
        if (data.contents !== undefined) {
            if (data.contents.length > limitMessageText.maxCharacterLength) {
                result = {
                    flag: true,
                    messages: [PLAIN_TEXT_NODE.MAX_CHARACTER_LENGTH_ERROR],
                };
            } else if (data.contents.trim().length === 0) {
                result = {
                    flag: true,
                    messages: [PLAIN_TEXT_NODE.RESPONSE_TEXT_EMPTY_ERROR],
                };
            }
        }
        return result;
    },

    validateTextQuestion: (data) => {
        let result = { flag: false, messages: [] };
        let limitMessageText = limitation.nodes.plainMessageTextNode;
        if (data.contents !== undefined) {
            if (data.contents.length > limitMessageText.maxCharacterLength) {
                result = {
                    flag: true,
                    messages: [QUESTION_NODE.MAX_CHARACTER_LENGTH_ERROR],
                };
            } else if (data.contents.trim().length === 0) {
                result = {
                    flag: true,
                    messages: [QUESTION_NODE.QUESTION_TEXT_EMPTY_ERROR],
                };
            }
        }
        return result;
    },

    validateButton: (data) => {
        let messages = [];
        let limitButtonNode = limitation.nodes.buttonNode;

        // ================================= Content =================================
        if (!data.contents) {
            messages.push(BUTTON_NODE.CONTENT_NOT_FOUND_ERROR);
        }
        // ================================= Content =================================

        // ================================= BODY TEXT =================================
        if (data.contents && data.contents.buttons && !data.contents.bodyText) {
            messages.push(BUTTON_NODE.BODY_TEXT_EMPTY_ERROR);
        }
        if (data.contents.bodyText && data.contents.bodyText.trim() === "") {
            messages.push(BUTTON_NODE.BODY_TEXT_EMPTY_ERROR);
        }

        if (data.contents.bodyText.length > limitButtonNode.bodyText) {
            messages.push(BUTTON_NODE.BODY_TEXT_MAX_CHARACTER_LENGTH_ERROR)
        }
        // ================================= BODY TEXT =================================


        // ================================= BUTTONS =================================

        if (data.contents.buttons.length < 1) {
            messages.push(BUTTON_NODE.MIN_REQUIRED_BUTTON_ERROR);
        }

        if (data.contents.buttons.length > limitButtonNode.maxButtons) {
            messages.push(BUTTON_NODE.MAX_BUTTON_ERROR);
        }

        if (data.contents && Array.isArray(data.contents.buttons)) {
            let hasErrorInListItem = false
            data.contents.buttons.forEach(element => {
                if (typeof element.value === 'string' && element.value.trim() === "") {
                    hasErrorInListItem = true
                }
            });
            if (hasErrorInListItem === true) {
                messages.push(BUTTON_NODE.BUTTON_TEXT_ERROR);
            }
        }

        // ================================= BUTTONS =================================	

        return {
            flag: messages.length > 0,
            messages
        }

    },

    validateList: (data) => {
        let messages = [];
        let limitForListNode = limitation.nodes.listNode;

        // ================================= Content =================================
        if (!data.contents) {
            messages.push(LIST_NODE.CONTENT_NOT_FOUND_ERROR);
        }
        // ================================= Content =================================

        // ================================= BODY TEXT =================================
        if (data.contents && data.contents.list && !data.contents.bodyText) {
            messages.push(LIST_NODE.BODY_TEXT_EMPTY_ERROR);
        }
        if (data.contents.bodyText && data.contents.bodyText.trim() === "") {
            messages.push(LIST_NODE.BODY_TEXT_EMPTY_ERROR);
        }

        if (data.contents.bodyText.length > limitForListNode.bodyText) {
            messages.push(LIST_NODE.BODY_TEXT_MAX_CHARACTER_LENGTH_ERROR)
        }
        // ================================= BODY TEXT =================================

        // ================================= LIST  =================================
        if (
            data.contents.openButtonText !== undefined
            && typeof data.contents.openButtonText === 'string'
            && data.contents.openButtonText.trim().length === 0
        ) {
            messages.push(LIST_NODE.OPEN_BTN_TEXT_EMPTY_ERROR);
        }

        if (data.contents && (!data.contents.list || !Array.isArray(data.contents.list))) {
            messages.push(LIST_NODE.EMPTY_LIST_ERROR);
        }

        if (data.contents && Array.isArray(data.contents.list)) {
            let hasErrorInListItem = false
            data.contents.list.forEach(element => {
                if (typeof element.value === 'string' && element.value.trim() === "") {
                    hasErrorInListItem = true
                }
            });
            if (hasErrorInListItem === true) {
                messages.push(LIST_NODE.LIST_ITEM_TEXT_EMPTY_ERROR);
            }
        }
        // ================================= LIST  =================================

        return {
            flag: messages.length > 0,
            messages
        }
    },

    validateMediaNode: (data) => {
        let result = { flag: false, messages: [] };
        let mediaLimitation = limitation.nodes.mediaNode;
        // Select Media url from
        if (data.contents.media.selectedMediaUrlFrom === "" || data.contents.isDataFromUrl === null) {
            result = {
                flag: true,
                messages: [...result.messages, MEDIA_NODE.MEDIA_URL_IS_FROM_ERROR],
            };
        }

        // Select media type
        if (data.contents.media.mediaType === null) {
            result = {
                flag: true,
                messages: [...result.messages, MEDIA_NODE.MEDIA_TYPE_REQUIRED_ERROR],
            };
        }

        if (!data.contents.media.fileUrl || data.contents.media.fileUrl.trim().length === 0) {
            if (data.contents.media.selectedMediaUrlFrom === 'byURL') {
                result = {
                    flag: true,
                    messages: [...result.messages, MEDIA_NODE.MEDIA_FILE_NOT_SELECTED_ERROR_FOR_BY_URL],
                };
            }

            if (data.contents.media.selectedMediaUrlFrom === 'byUpload') {
                result = {
                    flag: true,
                    messages: [...result.messages, MEDIA_NODE.MEDIA_FILE_NOT_SELECTED_ERROR_FOR_BY_UPLOAD],
                };
            }
        }

        if (typeof data.contents.caption === 'string' && data.contents.caption.length > mediaLimitation.caption) {
            result = {
                flag: true,
                messages: [...result.messages, MEDIA_NODE.MEDIA_CAPTION_MAX_CHARACTER_LENGTH_ERROR]
            };
        }

        if (data.contents.mediaType === 'document' && typeof data.contents.fileDisplayNameForDocument === 'string') {
            if (data.contents.fileDisplayNameForDocument === '') {
                result = {
                    flag: true,
                    messages: [...result.messages, MEDIA_NODE.MEDIA_FILENAME_EMPTY_ERROR]
                };
            }
            if (data.contents.fileDisplayNameForDocument.length > mediaLimitation.fileDisplayNameForDocument) {
                result = {
                    flag: true,
                    messages: [...result.messages, MEDIA_NODE.MEDIA_FILENAME_MAX_CHARACTER_LENGTH_ERROR]
                };
            }
        }

        return result;
    },

    validateMediaInputNode: (data) => {
        let result = { flag: false, messages: [] };
        let mediaInputInstructionLimitation = limitation.nodes.mediaInputNode;
        if (
            data.contents.text.length > mediaInputInstructionLimitation.textLength
        ) {
            result = {
                flag: true,
                messages: [
                    ...result.messages, MEDIA_INPUT_NODE.INSTRUCTION_MAX_CHARACTER_LENGTH_ERROR],
            };
        } else if (data.contents.text.trim().length === 0) {
            result = {
                flag: true,
                messages: [...result.messages, MEDIA_INPUT_NODE.INSTRUCTION_EMPTY_ERROR],
            };
        }
        return result;
    },

    validationMediaListNode: (data) => {
        let result = { flag: false, messages: [] };
        if (
            data.contents.mediaVariable === null ||
            data.contents.mediaVariable === ""
        ) {
            result = {
                flag: true,
                messages: [...result.messages, `${INPUT_VARIABLE_NOT_SELECTED_ERROR}`],
            };
        }
        return result;
    },

    validateWebhookNode: (data) => {
        let result = { flag: false, messages: [] };
        if (data.contents.url.trim().length === 0) {
            result = {
                flag: true,
                messages: [...result.messages, WEBHOOK_NODE.EMPTY_URL_ERROR],
            };
        }
        if (data.contents.isBodybyVariable !== undefined &&
            data.contents.isBodybyVariable === true &&
            data.contents.method ===
            'POST' &&
            (
                data.contents.bodyVariable === null ||
                data.contents.bodyVariable === ''
            )) {
            result = {
                flag: true,
                messages: [...result.messages, WEBHOOK_NODE.BODY_VARIABLE_NOT_SELECTED_ERROR],
            };
        }
        return result;
    },

    //=================================================================================================

    validateSimpleConditionNode: (data) => {
        let result = { flag: false, messages: [] };
        if (data.contents.conditions) {
            if (!data.contents.conditions[0].compareToValue || data.contents.conditions[0].compareToValue.trim().length === 0) {
                result = {
                    flag: true,
                    messages: [...result.messages, CONDITION_NODE.SELECT_COMPARE_TO_VALUE_IN_FIRST_CONDITION],
                };
            }
            if ([null, undefined].includes(data.contents.conditions[0].compareWithValue)) {
                result = {
                    flag: true,
                    messages: [...result.messages, CONDITION_NODE.SELECT_COMPARE_WITH_VALUE_IN_FIRST_CONDITION],
                };
            } else {
                const compareWithValueType = typeof data.contents.conditions[0].compareWithValue;
                if (compareWithValueType === 'string' && data.contents.conditions[0].compareWithValue.trim().length === 0) {
                    result = {
                        flag: true,
                        messages: [...result.messages, CONDITION_NODE.SELECT_COMPARE_WITH_VALUE_IN_FIRST_CONDITION],
                    };
                }
            }

            if (data.contents.checkType !== null) {
                if (!data.contents.conditions[1].compareToValue || data.contents.conditions[1].compareToValue.trim().length === 0) {
                    result = {
                        flag: true,
                        messages: [...result.messages, CONDITION_NODE.SELECT_COMPARE_TO_VALUE_IN_SECOND_CONDITION],
                    };
                }
                if ([null, undefined].includes(data.contents.conditions[1].compareWithValue)) {
                    result = {
                        flag: true,
                        messages: [...result.messages, CONDITION_NODE.SELECT_COMPARE_WITH_VALUE_IN_SECOND_CONDITION],
                    };
                } else {
                    const compareWithValueType = typeof data.contents.conditions[1].compareWithValue;
                    if (compareWithValueType === 'string' && data.contents.conditions[1].compareWithValue.trim().length === 0) {
                        result = {
                            flag: true,
                            messages: [...result.messages, CONDITION_NODE.SELECT_COMPARE_WITH_VALUE_IN_SECOND_CONDITION],
                        };
                    }
                }

            }
        }
        return result;
    },

    validateSwitchConditionNode: (data) => {
        let result = { flag: false, messages: [] };
        if (!data.contents.variableToCheckCondition || data.contents.variableToCheckCondition.trim() === "") {
            result = {
                flag: true,
                messages: [...result.messages, SWITCH_CONDITION_NODE.SELECT_COMPARE_TO_VALUE]
            };
        }

        if (data.contents.conditions.length > 0) {
            for (let i = 0; i < data.contents.conditions.length; i++) {
                const compareValueType = typeof data.contents.conditions[i].compareValue;
                if (data.contents.conditions[i].conditionType.length === 0) {
                    result = {
                        flag: true,
                        messages: [...result.messages, SWITCH_CONDITION_NODE.SELECT_CONDITION_TYPE]
                    };
                }
                if ([null, undefined].includes(data.contents.conditions[i].compareValue)) {
                    result = {
                        flag: true,
                        messages: [...result.messages, SWITCH_CONDITION_NODE.SELECT_COMPARE_WITH]
                    };
                } else if (compareValueType === 'string' && data.contents.conditions[i].compareValue.trim().length === 0) {
                    result = {
                        flag: true,
                        messages: [...result.messages, SWITCH_CONDITION_NODE.SELECT_COMPARE_WITH]
                    };
                } else if (data.contents.conditions[i].isCompareWithConstant === true && compareValueType === 'string' && data.contents.conditions[i].compareValue.length > 50) {
                    result = {
                        flag: true,
                        messages: [...result.messages, SWITCH_CONDITION_NODE.COMPARE_WITH_VALUE_TEXT_LENGTH_ERROR]
                    };
                }
            }
        }
        return result;
    },

    validationLoopBackNode: (data) => {
        let result = { flag: false, messages: [] };
        if (
            data.contents.isSourceNodeConnected !==
            data.contents.isTargetNodeConnected
        ) {
            result = {
                flag: true,
                messages: [...result.messages, LOOP_BACK_NODE.SOURCE_AND_TARGET_MUST_CONNECTED_TO_PARENT_ERROR]
            };
        } else {
            if (data.contents.isInputCardTypePresentInLoopBackCycle !== undefined && data.contents.isInputCardTypePresentInLoopBackCycle === false) {
                result = {
                    flag: true,
                    messages: [...result.messages, LOOP_BACK_NODE.LOOP_BACK_CYCLE_INPUT_NODE_ERROR]
                };
            }
        }
        return result;
    },

    validationIterationGuard: (data) => {
        let result = { flag: false, messages: [] };
        if (
            data.contents.isMaxValueFromVariable === false &&
            (data.contents.maxValue === "" ||
                data.contents.maxValue <= 0 ||
                data.contents.maxValue === null)
        ) {
            result = {
                flag: true,
                messages: [...result.messages, ITERATION_GUARD_NODE.MAX_ITERATION_VALUE_ERROR]
            };
        }
        if (
            data.contents.isMaxValueFromVariable === true &&
            (data.contents.maxValue === "" || data.contents.maxValue === null)
        ) {
            result = {
                flag: true,
                messages: [...result.messages, ITERATION_GUARD_NODE.SELECT_MAX_VALUE_VARIABLE_ERROR],
            };
        }

        if (data.contents.isBreakHandleConnected === false) {
            result = {
                flag: true,
                messages: [...result.messages, ITERATION_GUARD_NODE.CONNECT_BREAK_HANDLE_ERROR],
            };
        }
        if (data.contents.isContinueHandleConnected === false) {
            result = {
                flag: true,
                messages: [...result.messages, ITERATION_GUARD_NODE.CONNECT_CONTINUE_HANDLE_ERROR],
            };
        }
        if (
            data.contents.isMaxValueFromVariable === false &&
            data.contents.maxValue > 0 && Number.isInteger(data.contents.maxValue) === false
        ) {
            result = { flag: true, messages: [...result.messages, ITERATION_GUARD_NODE.MAX_ITERATION_VALUE_DECIMAL_ERROR] };
        }
        return result;
    },

    validationSubFlowNode: (data) => {
        let result = { flag: false, messages: [] };
        if (data.contents.subFlow.id === null || data.contents.subFlow.id === "") {
            result = {
                flag: true,
                messages: [...result.messages, SUB_FLOW_NODE.SELECT_SUB_FLOW_ERROR]
            };
        }
        if (
            data.contents.subFlow.errorsInSubflow !== undefined &&
            typeof data.contents.subFlow.errorsInSubflow === 'number' &&
            data.contents.subFlow.errorsInSubflow > 0
        ) {
            result = {
                flag: true,
                messages: [
                    ...result.messages,
                    `Total ${data.contents.subFlow.errorsInSubflow} error${data.contents.subFlow.errorsInSubflow === 1 ? '' : 's'} in selected sub workflow.(Note: If you have resolved errors then click refresh button from sub workflow property menu).`,
                ],
            };
        }
        return result;
    },

    validationLocationOutputNode: (data) => {
        let result = { flag: false, messages: [] };
        if (data.contents.isLocationFromVariable === true) {
            if (!data.contents.variable || data.contents.variable.trim().length === 0) {
                result = {
                    flag: true,
                    messages: [...result.messages, LOCATION_OUTPUT_NODE.SELECT_VARIABLE_ERROR]
                };
            }
        } else {
            if (data.contents.location.latitude === 0) {
                result = {
                    flag: true,
                    messages: [...result.messages, LOCATION_OUTPUT_NODE.ENTER_LATITUDE_ERROR]
                };
            }
            if (data.contents.location.longitude === 0) {
                result = {
                    flag: true,
                    messages: [...result.messages, LOCATION_OUTPUT_NODE.ENTER_LONGITUDE_ERROR]
                };
            }
        }
        return result;
    },
    validationLocationInputNode: (data) => {
        let result = { flag: false, messages: [] };
        if (!data.contents.text || data.contents.text.trim() === "") {
            result = {
                flag: true,
                messages: [...result.messages, LOCATION_INPUT_NODE.ENTER_TEXT_ERROR],
            };
        }
        return result;
    },

    validationDynamicButtonNode: (data) => {
        let result = { flag: false, messages: [] };
        if (!data.contents.inputVariable || data.contents.inputVariable.trim() === "") {
            result = {
                flag: true,
                messages: [...result.messages, INPUT_VARIABLE_NOT_SELECTED_ERROR],
            };
        }
        if (!data.contents.bodyText || data.contents.bodyText.trim() === "") {
            result = {
                flag: true,
                messages: [...result.messages, DYNAMIC_BUTTON_NODE.BODY_TEXT_EMPTY_ERROR],
            };
        }
        if (data.contents.bodyText.length > 1024) {
            result = {
                flag: true,
                messages: [...result.messages, DYNAMIC_BUTTON_NODE.BODY_TEXT_MAX_CHARACTER_LENGTH_ERROR],
            };
        }
        return result;
    },
    validationDynamicListNode: (data) => {
        let result = { flag: false, messages: [] };
        if (!data.contents.inputVariable || data.contents.inputVariable.trim() === "") {
            result = {
                flag: true,
                messages: [...result.messages, INPUT_VARIABLE_NOT_SELECTED_ERROR]
            };
        }
        if (!data.contents.bodyText || data.contents.bodyText.trim() === "") {
            result = {
                flag: true,
                messages: [...result.messages, DYNAMIC_LIST_NODE.BODY_TEXT_EMPTY_ERROR],
            };
        }
        if (data.contents.bodyText.length > 1024) {
            result = {
                flag: true,
                messages: [...result.messages, DYNAMIC_LIST_NODE.BODY_TEXT_MAX_CHARACTER_LENGTH_ERROR],
            };
        }
        if (!data.contents.openButtonText || data.contents.openButtonText.trim() === "") {
            result = {
                flag: true,
                messages: [...result.messages, DYNAMIC_LIST_NODE.OPEN_BUTTON_TEXT_EMPTY_ERROR],
            };
        }
        return result;
    },

    validationDelayNode: (data) => {
        let result = { flag: false, messages: [] };
        if (data.contents.delayIntervalInSeconds <= 0) {
            result = {
                flag: true,
                messages: [...result.messages, DELAY_NODE.INTERVAL_GREATER_THAN_ZERO_ERROR]
            };
        }
        return result;
    },
    validationSendSMS: (data) => {
        let result = { flag: false, messages: [] };
        if (!data.contents.receipientNumber || data.contents.receipientNumber.trim().length === 0) {
            result = {
                flag: true,
                messages: [...result.messages, SEND_SMS_NODE.SELECT_RECEIPIENT_NUMBER_ERROR]
            };
        }
        if (!data.contents.senderName || data.contents.senderName.trim().length === 0) {
            result = {
                flag: true,
                messages: [...result.messages, SEND_SMS_NODE.SELECT_DLT_ENTITY_ID_ERROR]
            };
        }
        if (!data.contents.dltentityId || data.contents.dltentityId.trim().length === 0) {
            result = {
                flag: true,
                messages: [...result.messages, SEND_SMS_NODE.SELECT_DLT_ENTITY_ID_ERROR]
            };
        }
        if (!data.contents.dlttempId || data.contents.dlttempId.trim().length === 0) {
            result = {
                flag: true,
                messages: [...result.messages, SEND_SMS_NODE.SELECT_DLT_TEMPLATE_ID_ERROR]
            };
        }
        return result;
    },
    validationSendEmail: (data) => {
        let result = { flag: false, messages: [] };
        if (!data.contents.emailBody || data.contents.emailBody.trim().length === 0) {
            result = {
                flag: true,
                messages: [...result.messages, SEND_EMAIL_NODE.EMAIL_BODY_EMPTY_ERROR]
            };
        }
        if (!data.contents.receipientEmail || data.contents.receipientEmail.trim().length === 0) {
            result = {
                flag: true,
                messages: [...result.messages, SEND_EMAIL_NODE.SELECT_RECEIPIENT_EMAIL_ERROR]
            };
        }
        if (!data.contents.subject || data.contents.subject.trim().length === 0) {
            result = {
                flag: true,
                messages: [...result.messages, SEND_EMAIL_NODE.EMAIL_SUBJECT_EMPTY_ERROR]
            };
        }
        if (!data.contents.senderEmail || data.contents.senderEmail.trim().length === 0) {
            result = {
                flag: true,
                messages: [...result.messages, SEND_EMAIL_NODE.SELECT_SENDER_EMAIL_ERROR]
            };
        }
        if (!data.contents.senderDisplayName || data.contents.senderDisplayName.trim().length === 0) {
            result = {
                flag: true,
                messages: [...result.messages, SEND_EMAIL_NODE.SELECT_SENDER_DISPLAY_NAME_ERROR]
            };
        }
        if (!data.contents.replyToEmail || data.contents.replyToEmail.trim().length === 0) {
            result = {
                flag: true,
                messages: [...result.messages, SEND_EMAIL_NODE.SELECT_REPLY_TO_EMAIL_ERROR]
            };
        }

        if (!data.contents.replyToDisplayName || data.contents.replyToDisplayName.trim().length === 0) {
            result = {
                flag: true,
                messages: [...result.messages, SEND_EMAIL_NODE.SELECT_REPLY_TO_DISPLAY_NAME_ERROR]
            };
        }
        return result;
    },

    validationJsonMapper: (data) => {
        let result = { flag: false, messages: [] };
        if (data.contents.withSourceData !== undefined && data.contents.withSourceData === true) {
            if (data.contents.inputObjectArrayVariable && data.contents.inputObjectArrayVariable.variableName !== undefined) {
                if (data.contents.inputObjectArrayVariable.variableName.trim() === '') {
                    result = {
                        flag: true,
                        messages: [...result.messages, INPUT_VARIABLE_NOT_SELECTED_ERROR]
                    };
                }
            }
        }
        return result;
    },

    validationSaveRecipientData: (data) => {
        let result = { flag: false, messages: [] };
        if (data.contents.recipientInfo !== undefined) {
            if (!data.contents.recipientInfo.fullname || data.contents.recipientInfo.fullname.trim() === '') {
                result = {
                    flag: true,
                    messages: [...result.messages, SAVE_RECIPIENT_DATA_NODE.SELECT_FULLNAME_VARIABLE_ERROR]
                };
            }
            if (!data.contents.recipientInfo.email || data.contents.recipientInfo.email.trim() === '') {
                result = {
                    flag: true,
                    messages: [...result.messages, SAVE_RECIPIENT_DATA_NODE.SELECT_EMAIL_VARIABLE_ERROR]
                };
            }
            if (!data.contents.recipientInfo.phoneNumber || data.contents.recipientInfo.phoneNumber.trim() === '') {
                result = {
                    flag: true,
                    messages: [...result.messages, SAVE_RECIPIENT_DATA_NODE.SELECT_PHONE_NUMBER_ERROR]
                };
            }
        } else {
            result = {
                flag: true,
                messages: [...result.messages, SAVE_RECIPIENT_DATA_NODE.ADD_DATA_IN_CARD_ERROR]
            };
        }
        return result;
    },

    validateCarouselNode: (data) => {
        let result = { flag: false, messages: [] };
        // ================================= Content =================================
        if (!data.contents) {
            result.messages.push(CONTENT_NOT_FOUND_ERROR);
        }
        // ================================= Content =================================



        // ================================= LIST  =================================

        // if (data.contents && Array.isArray(data.contents.carousels)) {
        // 	let hasErrorInListItem = false
        // 	data.contents.carousels.forEach(element => {
        // 		if (Array.isArray(element.buttons)) {
        // 			element.buttons.forEach(button => {
        // 				if (typeof button.buttonName === 'string' && button.buttonName.trim() === '') {
        // 					hasErrorInListItem = true
        // 				}
        // 			});
        // 		}
        // 	});
        // 	if (hasErrorInListItem === true) {
        // 		messages.push(CAROUSEL_NODE.BUTTON_NAME_EMPTY_ERROR);
        // 	}
        // }
        // ================================= LIST  =================================


        if (
            data.contents !== undefined &&
            data.contents.buttons !== undefined &&
            data.contents.carousels !== undefined
        ) {
            // if (data.contents.buttons.length === 0) {
            //   result = {
            //     flag: true,
            //     messages: [
            //       ...result.messages,
            //       `Carousel should have at least one button.`,
            //     ],
            //   };
            // }
            if (data.contents.carousels.length === 0) {
                result = {
                    flag: true,
                    messages: [...result.messages, CAROUSEL_NODE.ADD_CAROUSEL_ERROR],
                };
            }
            for (let i = 0; i < data.contents.carousels.length; i++) {
                if (data.contents.carousels[i]?.imageUrl.trim() === "") {
                    if (data.contents.carousels[i].mediaFromType === 'byURL') {
                        result = {
                            flag: true,
                            messages: [
                                ...result.messages,
                                CAROUSEL_NODE.EMPTY_IMAGE_URL_BY_URL({ carouselNumber: i + 1 })],
                        };
                    }

                    if (data.contents.carousels[i].mediaFromType === 'byUpload') {
                        result = {
                            flag: true,
                            messages: [
                                ...result.messages,
                                CAROUSEL_NODE.EMPTY_IMAGE_URL_BY_UPLOAD({ carouselNumber: i + 1 })],
                        };
                    }

                } else {
                    let regexForUrl =
                        /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
                    if (!regexForUrl.test(data.contents.carousels[i]?.imageUrl.trim())) {
                        result = {
                            flag: true,
                            messages: [...result.messages, CAROUSEL_NODE.INVALID_IMAGE_URL({ carouselNumber: i + 1 })],
                        };
                    }
                }
                if (data.contents.carousels[i].bodyText.trim() === "") {
                    result = {
                        flag: true,
                        messages: [...result.messages, CAROUSEL_NODE.BODY_TEXT_EMPTY_ERROR({ carouselNumber: i + 1 })],
                    };
                }
                if (data.contents.carousels[i].buttons.length === 0) {
                    result = {
                        flag: true,
                        messages: [...result.messages, CAROUSEL_NODE.MINIMUM_BUTTON_ERROR({ carouselNumber: i + 1 })],
                    };
                }

                // validate button contains
                if (data.contents.carousels[i].buttons.length > 0) {
                    let errorMessage = [];
                    data.contents.carousels[i].buttons.forEach((btn, btnIndex) => {
                        let hasError = false;
                        let hasCharacterLimitError = false;
                        if (typeof btn.buttonName !== 'string' || btn.buttonName.trim() === "") {
                            hasError = true;
                        } else {
                            if (typeof btn.buttonName === 'string' && btn.buttonName.length > 20) {
                                hasCharacterLimitError = true
                            }
                        }
                        if (hasError === true) {
                            errorMessage.push(CAROUSEL_NODE.BUTTON_NAME_EMPTY_ERROR({ carouselNumber: i + 1, buttonNumber: btnIndex + 1 }));
                        }

                        if (hasCharacterLimitError === true) {
                            errorMessage.push(CAROUSEL_NODE.BUTTON_NAME_MAX_CHARACTER_ERROR({ carouselNumber: i + 1, buttonNumber: btnIndex + 1 }));
                        }

                        if (typeof btn.redirectUrl === 'string') {
                            let regexForUrl =
                                /^(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?$/;

                            if (typeof btn.redirectUrl !== 'string' || btn.redirectUrl.trim() === "") {

                                errorMessage.push(CAROUSEL_NODE.EMPTY_BUTTON_REDIRECT_URL_ERROR({ carouselNumber: i + 1, buttonNumber: btnIndex + 1 }));

                            } else if (!regexForUrl.test(btn.redirectUrl.trim())) {

                                errorMessage.push(CAROUSEL_NODE.INVALID_BUTTON_REDIRECT_URL_ERROR({ carouselNumber: i + 1, buttonNumber: btnIndex + 1 }));

                            }
                        }
                    });

                    result = {
                        flag: true,
                        messages: [
                            ...result.messages,
                            ...errorMessage
                        ],
                    };
                }
            }
        }

        return {
            flag: result.messages.length > 0,
            messages: result.messages
        }
    },

    validateLiveAgentNode: (data) => {
        let result = { flag: false, messages: [] };
        if (data.contents !== undefined) {
            if (!data.contents.messageAfterLiveAgentConnect || data.contents.messageAfterLiveAgentConnect.trim() === '') {
                result = {
                    flag: true,
                    messages: [...result.messages, LIVE_AGENT_NODE.MESSAGE_AFTER_LIVE_AGENT_CONNECTED_EMPTY_ERROR]
                };
            } else if (data.contents.messageAfterLiveAgentConnect.length > 1024) {
                result = {
                    flag: true,
                    messages: [...result.messages, LIVE_AGENT_NODE.MESSAGE_AFTER_LIVE_AGENT_CONNECTED_MAX_CHAR_ERROR]
                };
            }

            if (!data.contents.liveAgentDisconnectKeywords || (Array.isArray(data.contents.liveAgentDisconnectKeywords) && data.contents.liveAgentDisconnectKeywords.length === 0)) {
                result = {
                    flag: true,
                    messages: [...result.messages, LIVE_AGENT_NODE.DISCONNECT_KEYWORD_ERROR]
                };
            }

            if (!data.contents.liveAgentNotAvailableButtonContents.bodyText || data.contents.liveAgentNotAvailableButtonContents.bodyText.trim() === '') {
                result = {
                    flag: true,
                    messages: [...result.messages, LIVE_AGENT_NODE.AGENT_NOT_AVAILABLE_MESSAGE_EMPTY_ERROR]
                };
            } else if (data.contents.liveAgentNotAvailableButtonContents.bodyText.length > 1024) {
                result = {
                    flag: true,
                    messages: [...result.messages, LIVE_AGENT_NODE.AGENT_NOT_AVAILABLE_BODY_TEXT_MAX_CHAR_ERROR]
                };
            }

            if (!data.contents.liveAgentNotAvailableButtonContents.buttons.register.value || data.contents.liveAgentNotAvailableButtonContents.buttons.register.value.trim() === '') {
                result = {
                    flag: true,
                    messages: [...result.messages, LIVE_AGENT_NODE.CONFIRM_BUTTON_TEXT_EMPTY_ERROR]
                };
            }

            if (!data.contents.liveAgentNotAvailableButtonContents.buttons.cancelRegister.value || data.contents.liveAgentNotAvailableButtonContents.buttons.cancelRegister.value.trim() === '') {
                result = {
                    flag: true,
                    messages: [...result.messages, LIVE_AGENT_NODE.CANCEL_BUTTON_TEXT_EMPTY_ERROR]
                };
            }

            if (!data.contents.liveAgentWhenCallbackReceived.bodyText || data.contents.liveAgentWhenCallbackReceived.bodyText.trim() === '') {
                result = {
                    flag: true,
                    messages: [...result.messages, LIVE_AGENT_NODE.AFTER_CALLBACK_RECEIVED_BODY_TEXT_EMPTY_ERROR]
                };
            } else if (data.contents.liveAgentWhenCallbackReceived.bodyText.length > 1024) {
                result = {
                    flag: true,
                    messages: [...result.messages, LIVE_AGENT_NODE.AFTER_CALLBACK_RECEIVED_BODY_TEXT_MAX_CHAR_ERROR]
                };
            }

            if (!data.contents.liveAgentWhenCallbackReceived.buttons.connectToLiveAgent.value || data.contents.liveAgentWhenCallbackReceived.buttons.connectToLiveAgent.value.trim() === '') {
                result = {
                    flag: true,
                    messages: [...result.messages, LIVE_AGENT_NODE.CONNECT_BUTTON_TEXT_EMPTY_ERROR]
                };
            }

            if (!data.contents.liveAgentWhenCallbackReceived.buttons.skipLiveAgentCallback.value || data.contents.liveAgentWhenCallbackReceived.buttons.skipLiveAgentCallback.value.trim() === '') {
                result = {
                    flag: true,
                    messages: [...result.messages, LIVE_AGENT_NODE.SKIP_BUTTON_TEXT_EMPTY_ERROR]
                };
            }
        }
        return result;
    },

    validateDocumentCognitionNode: (data) => {
        let result = { flag: false, messages: [] };
        if (data.contents !== undefined) {
            if (!data.contents.initialMessage || data.contents.initialMessage.trim() === '') {
                result = {
                    flag: true,
                    messages: [...result.messages, DOCUMENT_COGNITION_NODE.INITIAL_MESSAGE_EMPTY_ERROR]
                };
            }

            if (!data.contents.selectedConfig || typeof (data.contents.selectedConfig) !== 'object' || (typeof (data.contents.selectedConfig) === "object" && Object.keys(data.contents.selectedConfig).length === 0)) {
                result = {
                    flag: true,
                    messages: [...result.messages, DOCUMENT_COGNITION_NODE.SELECT_DOCUMENT_ERROR]
                };
            }

            if (!data.contents.modelDisconnectKeywords || (Array.isArray(data.contents.modelDisconnectKeywords) && data.contents.modelDisconnectKeywords.length === 0)) {
                result = {
                    flag: true,
                    messages: [...result.messages, DOCUMENT_COGNITION_NODE.DISCONNECT_KEYWORD_ERROR]
                };
            }


        }
        return result;
    },

    validateVariableAssignmentNode: (data) => {
        let messages = [];
        if (data.contents.variableAssign) {
            if (data.contents.variableAssign.length === 0) {
                messages.push(VARIABLE_ASSIGNMENT_NODE.NO_VARIABLE_ASSIGN_ERROR);
            } else {
                data.contents.variableAssign.forEach(variable => {
                    if (!variable.targetVariable || variable.targetVariable.trim().length === 0) {
                        messages.push(VARIABLE_ASSIGNMENT_NODE.TARGET_VARIABLE_EMPTY_ERROR);
                    }
                    if (!variable.sourceVariable || variable.sourceVariable.trim().length === 0) {
                        messages.push(VARIABLE_ASSIGNMENT_NODE.SOURCE_VARIABLE_EMPTY_ERROR)
                    }
                });
            }
        }
        return {
            flag: messages.length > 0,
            messages
        };
    },

    validateConstantAssignmentCard: (data) => {
        let messages = [];
        if (data.contents.variableAssignArray) {
            if (data.contents.variableAssignArray.length === 0) {
                messages.push(CONSTANT_ASSIGNMENT_NODE.NO_VARIABLE_ASSIGN_ERROR);
            } else {
                data.contents.variableAssignArray.forEach(element => {
                    if (!element.variable || element.variable.trim().length === 0) {
                        messages.push(CONSTANT_ASSIGNMENT_NODE.VARIABLE_EMPTY_ERROR);
                    }
                    if ([null, undefined].includes(element.variableValue)) {
                        messages.push(CONSTANT_ASSIGNMENT_NODE.VALUE_EMPTY_ERROR)
                    }
                    const variableValueType = typeof element.variableValue;
                    if (variableValueType === 'string' && element.variableValue.trim().length === 0) {
                        messages.push(CONSTANT_ASSIGNMENT_NODE.VALUE_EMPTY_ERROR)
                    }
                });
            }
        }
        return {
            flag: messages.length > 0,
            messages
        };
    },

    responseCard: (data) => {
        let messages = [];
        if (![undefined, null].includes(data.contents.response) && data.contents.response === "") {
            messages.push(RESPONSE_CARD.VARIABLE_NOT_SELECTED);
        }
        if (![undefined, null].includes(data.contents.responseStatusCode) && data.contents.responseStatusCode === "") {
            messages.push(RESPONSE_CARD.STATUS_CODE_NOT_SELECTED_ERROR);
        }
        if (![undefined, null].includes(data.contents.responseContentType) && data.contents.responseContentType === "") {
            messages.push(RESPONSE_CARD.CONTENT_TYPE_NOT_SELECTED_ERROR);
        }

        return {
            flag: messages.length > 0,
            messages
        };
    },

    broadcastNode: (data) => {
        let messages = [];

        const INTEGRATION_TYPES = {
            WHATSAPP: "whatsapp",
            EMAIL: "email",
            SMS: "sms"
        };

        const isIntegrationChecked = (integration) => {
            if (
                [undefined, null].includes(data.contents[integration]) === false &&
                [undefined, null].includes(data.contents[integration]["isChecked"]) === false &&
                data.contents[integration]["isChecked"] === true
            ) {
                return true;
            }

            return false;
        };

        const isIntegrationSelected = (integration) => {
            if (
                [undefined, null].includes(data.contents[integration]) === false &&
                [undefined, null].includes(data.contents[integration]["integrationId"]) === false &&
                data.contents[integration]["integrationId"] !== ""
            ) {
                return true;
            }

            return false;
        };

        if (
            isIntegrationChecked(INTEGRATION_TYPES.SMS) === false &&
            isIntegrationChecked(INTEGRATION_TYPES.EMAIL) === false &&
            isIntegrationChecked(INTEGRATION_TYPES.WHATSAPP) === false
        ) {
            messages.push(BROADCAST_NODE.CHANNELS_NOT_SELECTED_ERROR);
        } else {
            if (isIntegrationChecked(INTEGRATION_TYPES.WHATSAPP) === true && isIntegrationSelected(INTEGRATION_TYPES.WHATSAPP) === false) {
                messages.push(BROADCAST_NODE.INTEGRATION_NOT_SELECTED_ERROR(INTEGRATION_TYPES.WHATSAPP));
            }

            if (isIntegrationChecked(INTEGRATION_TYPES.SMS) === true && isIntegrationSelected(INTEGRATION_TYPES.SMS) === false) {
                messages.push(BROADCAST_NODE.INTEGRATION_NOT_SELECTED_ERROR(INTEGRATION_TYPES.SMS));
            }

            if (isIntegrationChecked(INTEGRATION_TYPES.EMAIL) === true && isIntegrationSelected(INTEGRATION_TYPES.EMAIL) === false) {
                messages.push(BROADCAST_NODE.INTEGRATION_NOT_SELECTED_ERROR(INTEGRATION_TYPES.EMAIL));
            }
        }

        if (
            [undefined, null].includes(data.contents.payloadVariable) === false &&
            typeof data.contents.payloadVariable === "string" && data.contents.payloadVariable === ""
        ) {
            messages.push(BROADCAST_NODE.PAYLOAD_VARIABLE_NOT_SELECTED);
        }



        return {
            flag: messages.length > 0,
            messages
        };
    },

    validateRegisterHttpTriggerNode: (data) => {
        let messages = [];
        if (data.contents && data.contents.httpTriggerNodeId !== undefined && data.contents.httpTriggerNodeId !== null) {
            if (typeof data.contents.httpTriggerNodeId === 'string' && data.contents.httpTriggerNodeId.length === 0) {
                messages.push(REGISTER_HTTP_TRIGGER_NODE.HTTP_TRIGGER_NODE_ID_EMPTY_ERROR);
            }
        }
        return {
            flag: messages.length > 0,
            messages
        };
    },
    broadcastSMSNode: (data) => {
        let messages = [];

        if (
            [undefined, null].includes(data.contents.integrationId) === true ||
            data.contents.integrationId === ""
        ) {
            messages.push(BROADCAST_SMS_NODE.INTEGRATION_NOT_SELECTED_ERROR);
        }

        if (
            [undefined, null].includes(data.contents.payloadVariable) === false &&
            typeof data.contents.payloadVariable === "string" && data.contents.payloadVariable === ""
        ) {
            messages.push(BROADCAST_SMS_NODE.PAYLOAD_VARIABLE_NOT_SELECTED);
        }

        return {
            flag: messages.length > 0,
            messages
        };
    },



    setLocaleNode: (data, localeSupportedByBot) => {
        let messages = [];
        if (
            [undefined, null].includes(data.contents.selectedLocale) === false && typeof data.contents.selectedLocale === "string") {
            if (data.contents.selectedLocale === "") {
                messages.push(SET_LOCALE_NODE.LOCALE_NOT_SELECTED);
            } else {
                if ([undefined, null].includes(localeSupportedByBot) === false) {
                    if (!localeSupportedByBot[data.contents.selectedLocale]) {
                        messages.push(SET_LOCALE_NODE.NOT_VALID_LOCALE);
                    }
                }
            }
        }

        return {
            flag: messages.length > 0,
            messages
        };
    },






    //=======================================MESSAGING SERVICE CARD VALIDATIONS ==============================
    messagingServiceNode: (data) => {
        let messages = [];

        const INTEGRATION_TYPES = {
            WHATSAPP: "whatsapp",
            EMAIL: "email",
            SMS: "sms"
        };

        const isIntegrationChecked = (integration) => {
            if (
                [undefined, null].includes(data.contents[integration]) === false &&
                [undefined, null].includes(data.contents[integration]["isSelected"]) === false &&
                data.contents[integration]["isSelected"] === true
            ) {
                return true;
            }

            return false;
        };

        const isIntegrationSelected = (integration) => {
            if (
                [undefined, null].includes(data.contents[integration]) === false &&
                [undefined, null].includes(data.contents[integration]["integrationId"]) === false &&
                data.contents[integration]["integrationId"] !== ""
            ) {
                return true;
            }

            return false;
        };

        const isWebhookConfigEnabled = () => {
            if (
                [undefined, null].includes(data.contents.sms) === false &&
                [undefined, null].includes(data.contents.sms.enableWebhookConfig) === false &&
                data.contents.sms.enableWebhookConfig === true
            ) {
                return true;
            }

            return false;

        };

        const isWebhookConfigSelected = () => {
            if (
                [undefined, null].includes(data.contents.sms) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.webhookUrlConfig) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.webhookUrlConfig.type) === false &&
                data.contents.sms.webhookConfig.webhookUrlConfig.type !== ""
            ) {
                return true;
            }

            return false;

        };

        const webhookConfigSelectedType = () => {
            if (
                [undefined, null].includes(data.contents.sms) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.webhookUrlConfig) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.webhookUrlConfig.type) === false &&
                data.contents.sms.webhookConfig.webhookUrlConfig.type === "static"
            ) {
                return "static";
            }

            return "dynamic";
        }

        const isStaticUrlForWebhookConfigrationisEmpty = () => {
            if (
                [undefined, null].includes(data.contents.sms) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.webhookUrlConfig) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.webhookUrlConfig.url) === false &&
                data.contents.sms.webhookConfig.webhookUrlConfig.url === ""
            ) {
                return true;
            }

            return false;
        }

        const isWebhookAuthenticationEnabled = () => {
            if (
                [undefined, null].includes(data.contents.sms) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.authenticate) === false &&
                data.contents.sms.webhookConfig.authentication.authenticate === true
            ) {
                return true;
            }

            return false;

        }

        const isWebhookAuthentictionSelected = () => {
            if (
                [undefined, null].includes(data.contents.sms) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.type) === false &&
                data.contents.sms.webhookConfig.authentication.type !== ""
            ) {
                return true;
            }

            return false;

        }

        const webhookAuthenticationSelectedType = () => {
            if (
                [undefined, null].includes(data.contents.sms) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.type) === false &&
                data.contents.sms.webhookConfig.authentication.type !== ""
            ) {
                return data.contents.sms.webhookConfig.authentication.type;
            }

            return "";
        }

        const keyForApiKeyAuthenticationEmpty = () => {
            if (
                [undefined, null].includes(data.contents.sms) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.api_key) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.api_key.key) === false &&
                data.contents.sms.webhookConfig.authentication.api_key.key === ""
            ) {
                return true;
            }

            return false;
        }

        const valueForApiKeyAuthenticationEmpty = () => {
            if (
                [undefined, null].includes(data.contents.sms) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.api_key) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.api_key.value) === false &&
                data.contents.sms.webhookConfig.authentication.api_key.value === ""
            ) {
                return true;
            }

            return false;
        }

        const userNameForBasicAuthenticationEmpty = () => {
            if (
                [undefined, null].includes(data.contents.sms) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.basic_auth) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.basic_auth.username) === false &&
                data.contents.sms.webhookConfig.authentication.basic_auth.username === ""
            ) {
                return true;
            }

            return false;
        }

        const passwordForBasicAuthenticationEmpty = () => {
            if (
                [undefined, null].includes(data.contents.sms) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.basic_auth) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.basic_auth.password) === false &&
                data.contents.sms.webhookConfig.authentication.basic_auth.password === ""
            ) {
                return true;
            }

            return false;
        }

        const headerKeyWordForBearerAuthenticationEmpty = () => {
            if (
                [undefined, null].includes(data.contents.sms) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer.headerKeyNameForToken) === false &&
                data.contents.sms.webhookConfig.authentication.bearer.headerKeyNameForToken === ""
            ) {
                return true;
            }

            return false;
        }

        const KeyWordToAddBeforeTokenBearerAuthenticationEmpty = () => {
            if (
                [undefined, null].includes(data.contents.sms) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer.keywordToAddBeforeToken) === false &&
                data.contents.sms.webhookConfig.authentication.bearer.keywordToAddBeforeToken === ""
            ) {
                return true;
            }

            return false;
        }

        const isTokenTypeSelectedForBearerAuthentication = () => {
            if (
                [undefined, null].includes(data.contents.sms) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer.tokenType) === false &&
                data.contents.sms.webhookConfig.authentication.bearer.tokenType !== ""
            ) {
                return true;
            }

            return false;
        }

        const selectedTokenTypeForBearerAuthentication = () => {
            if (
                [undefined, null].includes(data.contents.sms) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer.tokenType) === false &&
                data.contents.sms.webhookConfig.authentication.bearer.tokenType !== ""
            ) {
                return data.contents.sms.webhookConfig.authentication.bearer.tokenType;
            }

            return '';
        }

        const tokenForStaticBearerTokenAuthenticationEmpty = () => {
            if (
                [undefined, null].includes(data.contents.sms) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer.static) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer.static.token) === false &&
                data.contents.sms.webhookConfig.authentication.bearer.static.token === ""
            ) {
                return true;
            }

            return false;
        }

        const urlForDynamicBearerTokenAuthenticationEmpty = () => {
            if (
                [undefined, null].includes(data.contents.sms) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer.dynamic) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer.dynamic.url) === false &&
                data.contents.sms.webhookConfig.authentication.bearer.dynamic.url === ""
            ) {
                return true;
            }

            return false;
        }

        const isMethodSelectedForDynamicBearerToken = () => {
            if (
                [undefined, null].includes(data.contents.sms) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer.dynamic) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer.dynamic.method) === false &&
                data.contents.sms.webhookConfig.authentication.bearer.dynamic.method !== ""
            ) {
                return true;
            }

            return false;
        }

        const isTokenFieldNameForDynamicBearerTokenEmpty = () => {
            if (
                [undefined, null].includes(data.contents.sms) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer.dynamic) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer.dynamic.tokenFieldName) === false &&
                data.contents.sms.webhookConfig.authentication.bearer.dynamic.tokenFieldName === ""
            ) {
                return true;
            }

            return false;
        }

        const validateHeadersForWebhookConfigration = () => {

            if (
                [undefined, null].includes(data.contents.sms) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.headers) === false &&
                data.contents.sms.webhookConfig.headers.length > 0
            ) {
                for (let index = 0; index < data.contents.sms.webhookConfig.headers.length; index++) {
                    const object = data.contents.sms.webhookConfig.headers[index];
                    if (object.key === "" && object.value === "") {
                        messages.push(BROADCAST_WITH_RABBITMQ_NODE.ANY_FEILD_EMPTY_ERROR("headers for webhookConfig", "key"));
                        messages.push(BROADCAST_WITH_RABBITMQ_NODE.ANY_FEILD_EMPTY_ERROR("headers for webhookConfig", "value"));
                        break;
                    }
                    else if (object.value === "") {
                        messages.push(BROADCAST_WITH_RABBITMQ_NODE.ANY_FEILD_EMPTY_ERROR("headers for webhookConfig", "value"));
                        break;
                    }
                    else if (object.key === "") {
                        messages.push(BROADCAST_WITH_RABBITMQ_NODE.ANY_FEILD_EMPTY_ERROR("headers for webhookConfig", "key"));
                        break;
                    }
                }
                return true;
            } else {
                return false;
            }

        }

        const validateHeadersForDynamicBearerAuthentication = () => {

            if (
                [undefined, null].includes(data.contents.sms) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer.dynamic) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer.dynamic.headers) === false &&
                data.contents.sms.webhookConfig.authentication.bearer.dynamic.headers.length > 0
            ) {
                for (let index = 0; index < data.contents.sms.webhookConfig.authentication.bearer.dynamic.headers.length; index++) {
                    const object = data.contents.sms.webhookConfig.authentication.bearer.dynamic.headers[index];
                    if (object.key === "" && object.value === "") {
                        messages.push(BROADCAST_WITH_RABBITMQ_NODE.ANY_FEILD_EMPTY_ERROR("headers for bearer authentication", "key"));
                        messages.push(BROADCAST_WITH_RABBITMQ_NODE.ANY_FEILD_EMPTY_ERROR("headers for  bearer authentication", "value"));
                        break;
                    }
                    else if (object.value === "") {
                        messages.push(BROADCAST_WITH_RABBITMQ_NODE.ANY_FEILD_EMPTY_ERROR("headers for  bearer authentication", "value"));
                        break;
                    }
                    else if (object.key === "") {
                        messages.push(BROADCAST_WITH_RABBITMQ_NODE.ANY_FEILD_EMPTY_ERROR("headers for  bearer authentication", "key"));
                        break;
                    }
                }
                return true;
            } else {
                return false;
            }

        }

        const selectedBodyTypeForDynamicBearerAuthentication = () => {
            if (
                [undefined, null].includes(data.contents.sms) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer.dynamic) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer.dynamic.bodyType) === false &&
                data.contents.sms.webhookConfig.authentication.bearer.dynamic.bodyType !== ""
            ) {
                return data.contents.sms.webhookConfig.authentication.bearer.dynamic.bodyType;
            }

            return "";

        }

        const validateJsonForDynamicBearerAuthentication = () => {
            if (
                [undefined, null].includes(data.contents.sms) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer.dynamic) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer.dynamic.json) === false &&
                data.contents.sms.webhookConfig.authentication.bearer.dynamic.json.length > 0
            ) {
                for (let index = 0; index < data.contents.sms.webhookConfig.authentication.bearer.dynamic.json.length; index++) {
                    const object = data.contents.sms.webhookConfig.authentication.bearer.dynamic.json[index];
                    if (object.key === "" && object.value === "") {
                        messages.push(BROADCAST_WITH_RABBITMQ_NODE.ANY_FEILD_EMPTY_ERROR("form data for bearer authentication", "key"));
                        messages.push(BROADCAST_WITH_RABBITMQ_NODE.ANY_FEILD_EMPTY_ERROR("form data for bearer authentication", "value"));
                        break;
                    }
                    else if (object.value === "") {
                        messages.push(BROADCAST_WITH_RABBITMQ_NODE.ANY_FEILD_EMPTY_ERROR("form data for bearer authentication", "value"));
                        break;
                    }
                    else if (object.key === "") {
                        messages.push(BROADCAST_WITH_RABBITMQ_NODE.ANY_FEILD_EMPTY_ERROR("form data for bearer authentication", "key"));
                        break;
                    }
                }
                return true;
            } else {
                return false;
            }

        }

        const validateFormDataForDynamicBearerAuthentication = () => {

            if (
                [undefined, null].includes(data.contents.sms) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer.dynamic) === false &&
                [undefined, null].includes(data.contents.sms.webhookConfig.authentication.bearer.dynamic.formData) === false &&
                data.contents.sms.webhookConfig.authentication.bearer.dynamic.formData.length > 0
            ) {
                for (let index = 0; index < data.contents.sms.webhookConfig.authentication.bearer.dynamic.formData.length; index++) {
                    const object = data.contents.sms.webhookConfig.authentication.bearer.dynamic.formData[index];
                    if (object.key === "" && object.value === "") {
                        messages.push(BROADCAST_WITH_RABBITMQ_NODE.ANY_FEILD_EMPTY_ERROR("JSON data", "key"));
                        messages.push(BROADCAST_WITH_RABBITMQ_NODE.ANY_FEILD_EMPTY_ERROR("JSON data", "value"));
                        break;
                    }
                    else if (object.value === "") {
                        messages.push(BROADCAST_WITH_RABBITMQ_NODE.ANY_FEILD_EMPTY_ERROR("JSON data", "value"));
                        break;
                    }
                    else if (object.key === "") {
                        messages.push(BROADCAST_WITH_RABBITMQ_NODE.ANY_FEILD_EMPTY_ERROR("JSON data", "key"));
                        break;
                    }
                }
                return true;
            } else {
                return false;
            }

        }

        const isValidURL = (str) => {
            let regexForUrl = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
            if (regexForUrl.test(str)) {
                return true;
            } else {
                return false;
            }
        }




        if (
            isIntegrationChecked(INTEGRATION_TYPES.SMS) === false &&
            isIntegrationChecked(INTEGRATION_TYPES.EMAIL) === false &&
            isIntegrationChecked(INTEGRATION_TYPES.WHATSAPP) === false
        ) {
            messages.push(BROADCAST_WITH_RABBITMQ_NODE.CHANNELS_NOT_SELECTED_ERROR);
        } else {
            if (isIntegrationChecked(INTEGRATION_TYPES.WHATSAPP) === true && isIntegrationSelected(INTEGRATION_TYPES.WHATSAPP) === false) {
                messages.push(BROADCAST_WITH_RABBITMQ_NODE.INTEGRATION_NOT_SELECTED_ERROR("WhatsApp"));
            }

            if (isIntegrationChecked(INTEGRATION_TYPES.SMS) === true && isIntegrationSelected(INTEGRATION_TYPES.SMS) === false) {
                messages.push(BROADCAST_WITH_RABBITMQ_NODE.INTEGRATION_NOT_SELECTED_ERROR("SMS"));
            }

            if (isIntegrationChecked(INTEGRATION_TYPES.SMS) === true && isIntegrationSelected(INTEGRATION_TYPES.SMS) === true) {

                if (isWebhookConfigEnabled() === true) {

                    if (validateHeadersForWebhookConfigration() === true) {
                    }
                    if (isWebhookConfigSelected() === false) {
                        messages.push(BROADCAST_WITH_RABBITMQ_NODE.WEBHOOKCONFIG_TYPE_NOT_SELECTED_ERROR);
                    }

                    if (isWebhookConfigSelected() === true && webhookConfigSelectedType() === "static" && isStaticUrlForWebhookConfigrationisEmpty() === true) {
                        messages.push(BROADCAST_WITH_RABBITMQ_NODE.WEBHOOKCONFIG_TYPE_SELECTED_IS_STATIC_ERROR);
                    }

                    if (isWebhookConfigSelected() === true && webhookConfigSelectedType() === "static" && isStaticUrlForWebhookConfigrationisEmpty() === false) {
                        const url = data.contents.sms.webhookConfig.webhookUrlConfig.url;
                        if (!isValidURL(url)) {
                            messages.push(BROADCAST_WITH_RABBITMQ_NODE.NOT_A_VALID_URL_ERROR("Webhook Configration"));
                        }
                    }

                    if (isWebhookConfigSelected() === true && isWebhookAuthenticationEnabled() === true) {
                        if (isWebhookAuthentictionSelected() === false) {
                            messages.push(BROADCAST_WITH_RABBITMQ_NODE.WEBHOOKCONFIG_AUTHENTICATION_TYPE_NOT_SELECTED_ERROR);
                        }

                        if (isWebhookAuthentictionSelected() === true) {

                            if (webhookAuthenticationSelectedType() === "api_key") {

                                if (keyForApiKeyAuthenticationEmpty() === true) {
                                    messages.push(BROADCAST_WITH_RABBITMQ_NODE.AUTHENTICATION_FEILD_EMPTY_ERROR("Api Key", "key"));
                                }

                                if (valueForApiKeyAuthenticationEmpty() === true) {
                                    messages.push(BROADCAST_WITH_RABBITMQ_NODE.AUTHENTICATION_FEILD_EMPTY_ERROR("Api Key", "value"));
                                }

                            }

                            if (webhookAuthenticationSelectedType() === "basic_auth") {

                                if (userNameForBasicAuthenticationEmpty() === true) {
                                    messages.push(BROADCAST_WITH_RABBITMQ_NODE.AUTHENTICATION_FEILD_EMPTY_ERROR("Basic Auth", "username"));
                                }

                                if (passwordForBasicAuthenticationEmpty() === true) {
                                    messages.push(BROADCAST_WITH_RABBITMQ_NODE.AUTHENTICATION_FEILD_EMPTY_ERROR("Basic Auth", "password"));
                                }

                            }

                            if (webhookAuthenticationSelectedType() === "bearer") {

                                if (headerKeyWordForBearerAuthenticationEmpty() === true) {
                                    messages.push(BROADCAST_WITH_RABBITMQ_NODE.AUTHENTICATION_FEILD_EMPTY_ERROR("Bearer", "header keywords for token"));
                                }

                                // if (KeyWordToAddBeforeTokenBearerAuthenticationEmpty() === true) {
                                //     messages.push(BROADCAST_WITH_RABBITMQ_NODE.AUTHENTICATION_FEILD_EMPTY_ERROR("Bearer", "Key words to add before token"));
                                // }

                                if (isTokenTypeSelectedForBearerAuthentication() === false) {
                                    messages.push(BROADCAST_WITH_RABBITMQ_NODE.BEARER_AUTHENTICATION_TYPE_NOT_SELECTED_ERROR);
                                }

                                if (isTokenTypeSelectedForBearerAuthentication() === true && selectedTokenTypeForBearerAuthentication() === "static" && tokenForStaticBearerTokenAuthenticationEmpty() === true) {
                                    messages.push(BROADCAST_WITH_RABBITMQ_NODE.AUTHENTICATION_FEILD_EMPTY_ERROR("Bearer", "Static token"));
                                }

                                if (isTokenTypeSelectedForBearerAuthentication() === true && selectedTokenTypeForBearerAuthentication() === "dynamic") {
                                    validateHeadersForDynamicBearerAuthentication();

                                    if (selectedBodyTypeForDynamicBearerAuthentication() === "json") {
                                        validateJsonForDynamicBearerAuthentication();
                                    }


                                    if (selectedBodyTypeForDynamicBearerAuthentication() === "formData") {
                                        validateFormDataForDynamicBearerAuthentication();
                                    }

                                    if (urlForDynamicBearerTokenAuthenticationEmpty() === true) {
                                        messages.push(BROADCAST_WITH_RABBITMQ_NODE.AUTHENTICATION_FEILD_EMPTY_ERROR("Bearer", "URL"));
                                    } else {
                                        const url = data.contents.sms.webhookConfig.authentication.bearer.dynamic.url;
                                        if (!isValidURL(url)) {
                                            messages.push(BROADCAST_WITH_RABBITMQ_NODE.NOT_A_VALID_URL_ERROR("Bearer Url"));
                                        }
                                    }

                                    if (isMethodSelectedForDynamicBearerToken() === false) {
                                        messages.push(BROADCAST_WITH_RABBITMQ_NODE.AUTHENTICATION_FEILD_NOT_SELECTED_ERROR("Bearer", "method"));
                                    }

                                    if (isTokenFieldNameForDynamicBearerTokenEmpty() === true) {
                                        messages.push(BROADCAST_WITH_RABBITMQ_NODE.AUTHENTICATION_FEILD_EMPTY_ERROR("Bearer", "token field name"));
                                    }
                                }




                            }

                        }

                    }

                }

            }

            if (isIntegrationChecked(INTEGRATION_TYPES.EMAIL) === true && isIntegrationSelected(INTEGRATION_TYPES.EMAIL) === false) {
                messages.push(BROADCAST_WITH_RABBITMQ_NODE.INTEGRATION_NOT_SELECTED_ERROR("EMAIL"));
            }
        }

        if (
            [undefined, null].includes(data.contents.payloadVariable) === false &&
            typeof data.contents.payloadVariable === "string" && data.contents.payloadVariable === ""
        ) {
            messages.push(BROADCAST_NODE.PAYLOAD_VARIABLE_NOT_SELECTED);
        }

        return {
            flag: messages.length > 0,
            messages
        };
    },


    //======================================= Zoho CRM Node VAlidation ==============================
    validateZohoCrmNode: (data) => {

        console.log({ data });
        let result = { flag: false, messages: [] };
        if (data.contents && data.contents.data) {

            const contentData = data.contents.data;
            for (const key in contentData) {
                if (key !== "IGNORE" && key !== "fields" && contentData[key].trim() === '') {
                    result.flag = true;
                    result.messages.push(`Field ${key} can not be empty`)
                }
            }

            if (!data.outputVariable) {
                result.flag = true;
                result.messages.push(ZOHO_CRM.OUTPUT_VARIABLE_NOT_DEFINED)
            }

        }
        return result;

    },
};