import { NODE_TYPES } from "../utils/Constants/WorkflowConstants";

export const flowBuilderDarkThemeObject = {
    color: "#fff",
    workflowBackground: "#555",
    workflowBackgrondDotColor: "#666",
    cardBackground: "#333",
    edgeColor: "#dadada",
    // selectedEdgeColor: '#4c85ff',
    selectedEdgeColor: "#0E66CC",
    propertyMenuBackground: "#333",
    logContainerBackground: "#222",
    navbarBackground: "#000",
    errorIconColor: "#e65645",
    onTimeoutColor: "#df4945",
    onErrorColor: "#df4945",
    textEditorHighlightColor: "#53989c",
    textEditorTextColor: "#ddd",
    textEditorBackgroundColor: "#333",
    tooltipBackground: "white",
    tooltipColor: "rgba(0, 0, 0, 0.87)",
    contextMenu: {
        background: "#333",
        backgroundShadow: "rgba(0, 0, 0, 0.37)",
        cardsTextColor: "#ddd",
        cardsBorderColor: "#888",
        cardHoverColor: "#222",
    },
    simulator: {
        background: "#333",
        color: "#aaa",
    },
    dropzone: {
        background: "#031A33",
        color: "#fafafa",
        borderColor: "#4ad5e7",
        focusedBorderColor: "#2196f3",
        acceptBorderColor: "#00e676",
        rejectBorderColor: "#ff1744",
    },
    cards: {
        defaultNodeTheme: {
            cardColor: "black", // this default is for development purpose in production it will not come
            nodeContextMenuColor: "white", // 'white',
            headerText: "white",
            bodyText: "white",
            maxCardWidth: "350px",
        },
        startNode: {
            cardColor: "#888",
            nodeContextMenuColor: "white", // '#888',
            headerText: "white",
            bodyText: "white",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "#ddd",
            },
        },
        plainMessageTextNode: {
            cardColor: "rgb(7, 89, 133)",
            nodeContextMenuColor: "white", // '#2bb6ff',
            headerText: "white",
            bodyText: "white",
            iconColor: "white",
            maxCardWidth: "350px",
        },
        questionNode: {
            cardColor: "rgb(6, 95, 70)",
            nodeContextMenuColor: "white", // 'rgb(115, 246, 209)',
            headerText: "white",
            bodyText: "white",
            iconColor: "white",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "rgb(115, 246, 209)",
            },
        },
        listNode: {
            cardColor: "#6366F1",
            nodeContextMenuColor: "white", // 'rgb(125 127 231)',
            headerText: "white",
            bodyText: "white",
            iconColor: "white",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "rgb(125 127 231)",
            },
        },
        buttonNode: {
            cardColor: "rgb(248, 113, 113)",
            nodeContextMenuColor: "white", // '#f87171',
            headerText: "White",
            bodyText: "#fff",
            iconColor: "white",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "#f87171",
            },
        },
        carouselNode: {
            cardColor: "rgb(255 67 119)",
            nodeContextMenuColor: "white", // 'rgb(255 67 119)',
            headerText: "#ffffff",
            bodyText: "grey",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "rgb(255 67 119)",
            },
        },
        mediaInputNode: {
            cardColor: "rgb(44 102 119)",
            nodeContextMenuColor: "white", // 'rgb(44 165 201)',
            headerText: "#ffffff",
            bodyText: "grey",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "rgb(44 165 201)",
            },
        },
        mediaListNode: {
            cardColor: "rgb(136 0 91)",
            nodeContextMenuColor: "white", // '#8ac14a',
            headerText: "#ffffff",
            bodyText: "grey",
            maxCardWidth: "350px",
        },
        conditionNode: {
            cardColor: "RGB(47 125 120)",
            nodeContextMenuColor: "white", // 'RGB(47 125 120)',
            headerText: "white",
            bodyText: "grey",
            maxCardWidth: "350px",
            trueConditionColor: "#d0ffc0",
            falseConditionColor: "#ffd2da",
            propertyMenu: {
                baseColor: "RGB(47 125 120)",
            },
        },
        mediaNode: {
            cardColor: "rgb(236, 72, 153)",
            nodeContextMenuColor: "white", // '#bd437f',
            headerText: "#ffffff",
            bodyText: "grey",
            maxCardWidth: "350px",
        },
        switchConditionNode: {
            cardColor: "#7e22ce",
            nodeContextMenuColor: "white", // '#a66cd9',
            headerText: "white",
            bodyText: "grey",
            caseButtonColor: "#1180FF",
            caseButtonErrorColor: "red",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "#a66cd9",
            },
        },
        iterationGuardNode: {
            cardColor: "rgb(119 111 102)",
            nodeContextMenuColor: "white", // '#d9c1ff',
            headerText: "rgb(255 255 255)",
            bodyText: "grey",
            maxCardWidth: "350px",
            continueLoopColor: "#77ffee",
            breakLoopColor: "#eaf651",
        },
        loopBackNode: {
            cardColor: "rgb(139 132 132)",
            nodeContextMenuColor: "white", // '#bbbbbb',
            headerText: "white",
            bodyText: "grey",
            maxCardWidth: "350px",
        },
        webhookNode: {
            cardColor: "rgb(18 137 148)",
            nodeContextMenuColor: "white", // 'rgb(170 248 255)',
            headerText: "white",
            bodyText: "grey",
            successButtonColor: "green",
            failureButtonColor: "red",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "rgb(170 248 255)",
            },
        },
        channelSwitchNode: {
            cardColor: "rgb(121 0 177)",
            nodeContextMenuColor: "white", // 'rgb(183 29 255)',
            headerText: "#ffffff",
            bodyText: "#b5cae1",
            maxCardWidth: "350px",
        },
        scriptNode: {
            cardColor: "rgb(4 106 105)",
            nodeContextMenuColor: "white", // 'rgb(56 214 212)',
            headerText: "white",
            bodyText: "grey",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "rgb(56 214 212)",
            },
        },
        locationOutputNode: {
            cardColor: "rgb(38 121 153)",
            nodeContextMenuColor: "white", // 'rgb(38 121 153)',
            headerText: "white",
            bodyText: "#ffffff",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "rgb(38 121 153)",
            },
        },
        locationInputNode: {
            cardColor: "rgb(98 72 100)",
            nodeContextMenuColor: "white", // '#fbcfff',
            headerText: "white",
            bodyText: "#ffffff",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "#fbcfff",
            },
        },
        dynamicButtonNode: {
            cardColor: "rgb(118 59 122)",
            nodeContextMenuColor: "white", // '#decedf',
            headerText: "white",
            bodyText: "grey",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "#decedf",
            },
        },
        dynamicListNode: {
            cardColor: "rgb(67 135 164)",
            nodeContextMenuColor: "white", // '#b25cbf',
            headerText: "white",
            bodyText: "grey",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "#b25cbf",
            },
        },
        sendSMSCard: {
            cardColor: "rgb(164 44 3)",
            nodeContextMenuColor: "white", // 'rgb(202 79 37)',
            headerText: "white",
            bodyText: "white",
            successButtonColor: "green",
            noMoreCreditButtonColor: "orange",
            failureButtonColor: "red",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "rgb(202 79 37)",
            },
        },
        sendEmailCard: {
            cardColor: "#4b5563",
            nodeContextMenuColor: "white", // '#d3e4fc',
            headerText: "white",
            bodyText: "white",
            successButtonColor: "green",
            failureButtonColor: "red",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "#4b5563",
            },
        },
        jsonMapperCard: {
            cardColor: "rgb(208 101 0)",
            nodeContextMenuColor: "white", // 'rgb(208 101 0)',
            headerText: "white",
            bodyText: "grey",
            maxCardWidth: "350px",
        },
        liveAgentNode: {
            cardColor: "rgb(255 0 153)",
            nodeContextMenuColor: "white", // 'rgb(255 0 153)',
            headerText: "white",
            bodyText: "grey",
            resolveButtonColor: "rgb(196 255 211)",
            notAvailableButtonColor: "rgb(178 222 255)",
            timeoutWhenLiveAgentNotRespondButtonColor: "#eaf651",
            timeoutWhenUserNotRespondButtonColor: "#9a5da3",
            failureButtonColor: "rgb(255 133 161)",
            maxCardWidth: "350px",
        },
        getRecipientDataNode: {
            cardColor: "rgb(18 66 40)",
            nodeContextMenuColor: "white", // 'rgb(24 156 85)',
            headerText: "white",
            bodyText: "grey",
            successButtonColor: "#d0ffc0",
            failureButtonColor: "#ffd2da",
            maxCardWidth: "350px",
        },
        saveRecipientDataNode: {
            cardColor: "rgb(125, 5, 5)",
            nodeContextMenuColor: "white", // 'rgb(206 50 50)',
            headerText: "white",
            bodyText: "rgb(68 0 0)",
            successButtonColor: "#d0ffc0",
            failureButtonColor: "#ffd2da",
            maxCardWidth: "350px",
        },
        variableAssignmentNode: {
            cardColor: "rgb(61 96 71)",
            nodeContextMenuColor: "white", // 'rgb(143 182 154)',
            headerText: "white",
            bodyText: "grey",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "rgb(170 255 194)",
            },
        },
        subFlowNode: {
            cardColor: "#db2777",
            nodeContextMenuColor: "white", // '#ffdeec',
            headerText: "white",
            bodyText: "grey",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "#ffdeec",
            },
        },
        constantAssignmentCard: {
            cardColor: "rgb(102 129 68)",
            nodeContextMenuColor: "white", // '',
            headerText: "white",
            bodyText: "grey",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "rgb(102 129 68)",
            },
        },
        documentCognitionCard: {
            cardColor: "rgb(208 101 0)",
            nodeContextMenuColor: "white", // 'rgb(208 101 0)',
            headerText: "white",
            bodyText: "grey",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "rgb(208 101 0)",
            },
        },
        responseCard: {
            cardColor: "rgb(208 101 0)",
            nodeContextMenuColor: "white", // 'rgb(208 101 0)',
            headerText: "white",
            bodyText: "grey",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "rgb(208 101 0)",
            },
        },
        broadcastNode: {
            cardColor: "rgb(255 215 175)",
            nodeContextMenuColor: "white", // 'rgb(208 101 0)',
            headerText: "white",
            bodyText: "grey",
            maxCardWidth: "350px",
            successButtonColor: "green",
            rateLimitingButtonColor: "orange",
            unauthorizedButtonColor: "#c66b5b",
            failureButtonColor: "red",
            propertyMenu: {
                baseColor: "#3730a3",
            },
        },
        httpTriggerNode: {
            cardColor: "#a0f78c",
            nodeContextMenuColor: "#228e09",
            headerText: "#228e09",
            bodyText: "grey",
            maxCardWidth: "350px",
            httpTriggerInvokeColor: "green",
            httpTriggerNotRegisteredColor: "red",
            failureButtonColor: "red",
            propertyMenu: {
                baseColor: "#228e09",
            },
        },
        registerHttpTriggerNode: {
            cardColor: "#a0f78c",
            nodeContextMenuColor: "#228e09",
            headerText: "#228e09",
            bodyText: "grey",
            maxCardWidth: "350px",
            successColor: "green",
            failureColor: "red",
            propertyMenu: {
                baseColor: "#228e09",
            },
        },
        rabbitMqNode: {
            cardColor: "#a0f78c",
            nodeContextMenuColor: "#228e09",
            headerText: "#228e09",
            bodyText: "grey",
            maxCardWidth: "350px",
            successColor: "green",
            failureColor: "red",
            propertyMenu: {
                baseColor: "#228e09",
            },
        },
        broadcastSMSNode: {
            cardColor: "rgb(46 212 174)",
            nodeContextMenuColor: "rgb(9 88 69)",
            headerText: "rgb(9 88 69)",
            bodyText: "grey",
            maxCardWidth: "350px",
            successButtonColor: "green",
            failureButtonColor: "red",
            propertyMenu: {
                baseColor: "rgb(46 212 174)",
            },
        },
        setLocaleNode: {
            cardColor: "rgb(46 212 174)",
            nodeContextMenuColor: "rgb(9 88 69)",
            headerText: "rgb(9 88 69)",
            bodyText: "grey",
            maxCardWidth: "350px",
            successButtonColor: "green",
            failureButtonColor: "red",
            propertyMenu: {
                baseColor: "rgb(46 212 174)",
            },
        },
        whatsAppFlowsNode: {
            cardColor: "#BFE9FF",
            nodeContextMenuColor: "#075985",
            headerText: "#075985",
            bodyText: "grey",
            maxCardWidth: "350px",
        },
        whatsAppTemplateNode: {
            cardColor: "rgb(112, 230, 153)",
            nodeContextMenuColor: "#075e54",
            headerText: "#075e54",
            bodyText: "grey",
            maxCardWidth: "350px",
        },
        // Card Theme for Dark Mode
        whatsAppPaymentNode: {
            cardColor: "rgb(112, 230, 153)",
            nodeContextMenuColor: "#075e54",
            headerText: "#075e54",
            bodyText: "grey",
            maxCardWidth: "350px",
        },
        messagingServiceNode: {
            cardColor: "rgb(255 215 175)",
            nodeContextMenuColor: 'white', // 'rgb(208 101 0)',
            headerText: "white",
            bodyText: "grey",
            maxCardWidth: "350px",
            successButtonColor: "green",
            rateLimitingButtonColor: "orange",
            unauthorizedButtonColor: "#c66b5b",
            failureButtonColor: "red",
            propertyMenu: {
                baseColor: "rgb(46 212 174)",
            },
        },
        [NODE_TYPES.CONNECTOR_NODE]: {
            cardColor: "rgb(208 101 80)",
            nodeContextMenuColor: "white", // 'rgb(208 101 0)',
            headerText: "white",
            bodyText: "grey",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "rgb(208 101 80)",
            },
            successButtonColor: "green",
            failureButtonColor: "red",
        },
        whatsAppCatalogNode: {
            cardColor: "rgb(184, 245, 149)",
            nodeContextMenuColor: "#b8f595",
            headerText: "#075e54",
            bodyText: "grey",
            maxCardWidth: "350px",
        },
    },
    dcListing: {
        boxShadow: "rgb(0 0 0 / 100%) 0px 3px 6px",
    },
};

export const flowBuilderLightThemeObject = {
    color: "#222",
    workflowBackground: "#f2f2f2",
    workflowBackgrondDotColor: "#ddd",
    edgeColor: "#dadada",
    // selectedEdgeColor: '#4c85ff',
    selectedEdgeColor: "#0E66CC",
    cardBackground: "#fff",
    propertyMenuBackground: "#fff",
    logContainerBackground: "#fff",
    navbarBackground: "#fff",
    errorIconColor: "#f00",
    onTimeoutColor: "brown",
    onErrorColor: "brown",
    textEditorHighlightColor: "#cee4e5",
    textEditorTextColor: "black",
    textEditorBackgroundColor: "white",
    tooltipBackground: "#444",
    tooltipColor: "rgba(255, 255, 255, 1)",
    contextMenu: {
        background: "#fff",
        backgroundShadow: "rgba(31, 38, 135, 0.37)",
        cardsTextColor: "#333",
        cardsBorderColor: "#ddd",
        cardHoverColor: "#efefef",
    },
    simulator: {
        background: "#fff",
        color: "#333",
    },
    dropzone: {
        background: "#fafafa",
        color: "black",
        borderColor: "#4ad5e7",
        focusedBorderColor: "#2196f3",
        acceptBorderColor: "#00e676",
        rejectBorderColor: "#ff1744",
    },
    cards: {
        defaultNodeTheme: {
            cardColor: "#f3474b", // this default is for development purpose in production it will not come
            nodeContextMenuColor: "#333",
            headerText: "#333",
            bodyText: "grey",
            maxCardWidth: "350px",
        },
        startNode: {
            cardColor: "#A6A9F2",
            nodeContextMenuColor: "#333",
            headerText: "#333",
            bodyText: "grey",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "#333",
            },
        },
        plainMessageTextNode: {
            cardColor: "#BFE9FF",
            nodeContextMenuColor: "#075985",
            headerText: "#075985",
            bodyText: "grey",
            maxCardWidth: "350px",
        },
        questionNode: {
            cardColor: "#73F6D1",
            nodeContextMenuColor: "#065F46",
            headerText: "#065F46",
            bodyText: "grey",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "#065F46",
            },
        },
        listNode: {
            cardColor: "#d2d3ff",
            nodeContextMenuColor: "#6366F1",
            headerText: "#6366F1",
            bodyText: "grey",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "#6366F1",
            },
        },
        buttonNode: {
            cardColor: "#ffd6d6",
            nodeContextMenuColor: "#f87171",
            headerText: "#f87171",
            bodyText: "#333",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "#f87171",
            },
        },
        carouselNode: {
            cardColor: "#ffccda",
            nodeContextMenuColor: "#9f1239",
            headerText: "#9f1239",
            bodyText: "grey",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "#9f1239",
            },
        },
        mediaInputNode: {
            cardColor: "#b1eeff",
            nodeContextMenuColor: "#0e7490",
            headerText: "#0e7490",
            bodyText: "grey",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "#0e7490",
            },
        },
        mediaNode: {
            cardColor: "#ffd6ea",
            nodeContextMenuColor: "#ec4899",
            headerText: "#ec4899",
            bodyText: "grey",
            maxCardWidth: "350px",
        },
        mediaListNode: {
            cardColor: "#d6ffa7",
            nodeContextMenuColor: "#365314",
            headerText: "#365314",
            bodyText: "grey",
            maxCardWidth: "350px",
        },
        conditionNode: {
            cardColor: "#91f8f1",
            nodeContextMenuColor: "RGB(47 125 120)",
            headerText: "RGB(47 125 120)",
            bodyText: "grey",
            trueConditionColor: "green",
            falseConditionColor: "red",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "RGB(47 125 120)",
            },
        },
        switchConditionNode: {
            cardColor: "#e7ccff",
            nodeContextMenuColor: "#7e22ce",
            headerText: "#7e22ce",
            bodyText: "grey",
            caseButtonColor: "#1180FF",
            caseButtonErrorColor: "red",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "#7e22ce",
            },
        },
        iterationGuardNode: {
            cardColor: "#d9c1ff",
            nodeContextMenuColor: "#7e22ce",
            headerText: "#7e22ce",
            bodyText: "grey",
            continueLoopColor: "#1180FF",
            breakLoopColor: "#1180FF",
            maxCardWidth: "350px",
        },
        loopBackNode: {
            cardColor: "#bbbbbb",
            nodeContextMenuColor: "#000000",
            headerText: "#000000",
            bodyText: "grey",
            maxCardWidth: "350px",
        },
        channelSwitchNode: {
            cardColor: "#d1ceff",
            nodeContextMenuColor: "#3730a3",
            headerText: "#3730a3",
            bodyText: "#1180FF",
            maxCardWidth: "350px",
        },
        subFlowNode: {
            cardColor: "#ffdeec",
            nodeContextMenuColor: "#db2777",
            headerText: "#db2777",
            bodyText: "grey",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "#db2777",
            },
        },
        locationOutputNode: {
            cardColor: "#b7ebff",
            nodeContextMenuColor: "#164e63",
            headerText: "#164e63",
            bodyText: "grey",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "rgb(38 121 153)",
            },
        },
        locationInputNode: {
            cardColor: "#fbcfff",
            nodeContextMenuColor: "#a21caf",
            headerText: "#a21caf",
            bodyText: "grey",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "#a21caf",
            },
        },
        dynamicButtonNode: {
            cardColor: "#d4d4ef",
            nodeContextMenuColor: "#52525b",
            headerText: "#52525b",
            bodyText: "grey",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "#52525b",
            },
        },
        dynamicListNode: {
            cardColor: "#fcdeff",
            nodeContextMenuColor: "#a21caf",
            headerText: "#a21caf",
            bodyText: "grey",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "#a21caf",
            },
        },
        sendSMSCard: {
            cardColor: "#ffbfaa",
            nodeContextMenuColor: "rgb(207 69 23)",
            headerText: "rgb(207 69 23)",
            bodyText: "grey",
            successButtonColor: "green",
            noMoreCreditButtonColor: "orange",
            failureButtonColor: "red",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "rgb(207 69 23)",
            },
        },
        sendEmailCard: {
            cardColor: "#d3e4fc",
            nodeContextMenuColor: "#4b5563",
            headerText: "#4b5563",
            bodyText: "grey",
            successButtonColor: "green",
            failureButtonColor: "red",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "#4b5563",
            },
        },
        scriptNode: {
            cardColor: "rgb(191 255 254)",
            nodeContextMenuColor: "rgb(4 106 105)",
            headerText: "rgb(4 106 105)",
            bodyText: "grey",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "rgb(4 106 105)",
            },
        },
        jsonMapperCard: {
            cardColor: "rgb(254 235 164)",
            nodeContextMenuColor: "rgb(255 124 0)",
            headerText: "rgb(255 124 0)",
            bodyText: "grey",
            maxCardWidth: "350px",
        },
        liveAgentNode: {
            cardColor: "rgb(243 207 248)",
            nodeContextMenuColor: "rgb(255 0 153)",
            headerText: "rgb(255 0 153)",
            bodyText: "grey",
            resolveButtonColor: "green",
            notAvailableButtonColor: "#1180FF",
            timeoutWhenLiveAgentNotRespondButtonColor: "orange",
            timeoutWhenUserNotRespondButtonColor: "#9a5da3",
            failureButtonColor: "red",
            maxCardWidth: "350px",
        },
        getRecipientDataNode: {
            cardColor: "rgb(203 252 215)",
            nodeContextMenuColor: "rgb(18 66 40)",
            headerText: "rgb(18 66 40)",
            bodyText: "grey",
            successButtonColor: "green",
            failureButtonColor: "red",
            maxCardWidth: "350px",
        },
        saveRecipientDataNode: {
            cardColor: "rgb(255 189 191)",
            nodeContextMenuColor: "rgb(125 5 5)",
            headerText: "rgb(125 5 5)",
            bodyText: "grey",
            successButtonColor: "green",
            failureButtonColor: "red",
            maxCardWidth: "350px",
        },
        variableAssignmentNode: {
            cardColor: "rgb(170 255 194)",
            nodeContextMenuColor: "rgb(61 96 71)",
            headerText: "rgb(61 96 71)",
            bodyText: "grey",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "rgb(61 96 71)",
            },
        },
        webhookNode: {
            cardColor: "rgb(170 248 255)",
            nodeContextMenuColor: "rgb(18 137 148)",
            headerText: "rgb(18 137 148)",
            bodyText: "grey",
            successButtonColor: "green",
            failureButtonColor: "red",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "rgb(18 137 148)",
            },
        },
        constantAssignmentCard: {
            cardColor: "rgb(255 206 226)",
            nodeContextMenuColor: "rgb(143 62 62)",
            headerText: "rgb(143 62 62)",
            bodyText: "grey",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "rgb(143 62 62)",
            },
        },
        documentCognitionCard: {
            cardColor: "rgb(254 235 164)",
            nodeContextMenuColor: "rgb(255 124 0)",
            headerText: "rgb(255 124 0)",
            bodyText: "grey",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "rgb(255 124 0)",
            },
        },
        responseCard: {
            cardColor: "rgb(182 230 192)",
            nodeContextMenuColor: "rgb(15 133 40)",
            headerText: "rgb(15 133 40)",
            bodyText: "grey",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "rgb(15 133 40)",
            },
        },
        broadcastNode: {
            cardColor: "rgb(48 242 246 / 79%)",
            nodeContextMenuColor: "#068d91", // 'rgb(208 101 0)',
            headerText: "#098199",
            bodyText: "grey",
            maxCardWidth: "350px",
            successButtonColor: "green",
            rateLimitingButtonColor: "orange",
            unauthorizedButtonColor: "#c66b5b",
            failureButtonColor: "red",
            propertyMenu: {
                baseColor: "#098199",
            },
        },
        httpTriggerNode: {
            cardColor: "#a0f78c",
            nodeContextMenuColor: "#228e09",
            headerText: "#228e09",
            bodyText: "grey",
            maxCardWidth: "350px",
            httpTriggerInvokeColor: "green",
            httpTriggerNotRegisteredColor: "red",
            failureButtonColor: "red",
            propertyMenu: {
                baseColor: "#228e09",
            },
        },
        registerHttpTriggerNode: {
            cardColor: "#a0f78c",
            nodeContextMenuColor: "#228e09",
            headerText: "#228e09",
            bodyText: "grey",
            maxCardWidth: "350px",
            successColor: "green",
            failureColor: "red",
            propertyMenu: {
                baseColor: "#228e09",
            },
        },
        rabbitMqNode: {
            cardColor: "#a0f78c",
            nodeContextMenuColor: "#228e09",
            headerText: "#228e09",
            bodyText: "grey",
            maxCardWidth: "350px",
            successColor: "green",
            failureColor: "red",
            propertyMenu: {
                baseColor: "#228e09",
            },
        },
        broadcastSMSNode: {
            cardColor: "rgb(46 212 174)",
            nodeContextMenuColor: "rgb(9 88 69)",
            headerText: "rgb(9 88 69)",
            bodyText: "grey",
            maxCardWidth: "350px",
            successButtonColor: "green",
            failureButtonColor: "red",
            propertyMenu: {
                baseColor: "rgb(9 88 69)",
            },
        },
        setLocaleNode: {
            cardColor: "#ffcc99",
            nodeContextMenuColor: "#b35900",
            headerText: "#b35900",
            bodyText: "grey",
            maxCardWidth: "350px",
            successButtonColor: "green",
            failureButtonColor: "red",
            propertyMenu: {
                baseColor: "#b35900",
            },
        },
        whatsAppFlowsNode: {
            cardColor: "rgb(38 208 188)",
            nodeContextMenuColor: "#075e54",
            headerText: "#075e54",
            bodyText: "grey",
            maxCardWidth: "350px",
        },
        whatsAppTemplateNode: {
            cardColor: "rgb(112, 230, 153)",
            nodeContextMenuColor: "rgb(33, 148, 72)",
            headerText: "#075e54",
            bodyText: "grey",
            maxCardWidth: "350px",
        },
        // Card Theme for Light Mode
        whatsAppPaymentNode: {
            cardColor: "rgb(100, 156, 245)",
            nodeContextMenuColor: "rgb(9, 88, 214)",
            headerText: "#075e54",
            bodyText: "grey",
            maxCardWidth: "350px",
        },
        messagingServiceNode: {
            cardColor: "rgb(48 242 246 / 79%)",
            nodeContextMenuColor: '#068d91', // 'rgb(208 101 0)',
            headerText: "#098199",
            bodyText: "grey",
            maxCardWidth: "350px",
            successButtonColor: "green",
            rateLimitingButtonColor: "orange",
            unauthorizedButtonColor: "#c66b5b",
            failureButtonColor: "red",
            propertyMenu: {
                baseColor: "#b35900",
            },

        },
        [NODE_TYPES.CONNECTOR_NODE]: {
            cardColor: "rgb(208 101 80)",
            nodeContextMenuColor: '#068d91', // 'rgb(208 101 0)',
            headerText: "white",
            bodyText: "grey",
            maxCardWidth: "350px",
            propertyMenu: {
                baseColor: "rgb(208 101 80)",
            },
            successButtonColor: "green",
            failureButtonColor: "red",
        },
        whatsAppCatalogNode: {
            cardColor: "rgb(184, 245, 149)",
            nodeContextMenuColor: "rgb(43, 196, 43)",
            headerText: "#075e54",
            bodyText: "grey",
            maxCardWidth: "350px",
        },
    },
    dcListing: {
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px",
    },
};
