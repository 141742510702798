import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { addLocaleToAllRenderableNode } from "../../pages/pages/Bots/workflow-builder/utils/getNodeContentBasedOnLocale";
import {
    addNewNode,
    addTargetHandleIdOnEndLoopConnect,
    addVariable,
    calculateErrorCount,
    calculateWarningCount,
    checkUnConnectedNodes,
    connectNodes,
    deleteEdge,
    deleteNode,
    deleteVariable,
    duplicateNode,
    markVariableAsSentiveVariable,
    pullUploadedMediaListFromServer,
    setCurrentlySelecteNode,
    setLatestSelectedNodeOrEdgeForDeletion,
    updateChannelArrayOfNodes,
    updateLocaleErrorOnInitialEditorDataLoad,
    updateNode,
    updateVariableValue
} from "../builderLogic/builderStoreLogic";

class UndoState {
    undoArray = [];
    redoArray = [];
    length = this.undoArray.length;
    redoLength = this.redoArray.length;

    push(prevState) {

        this.redoArray = [];
        this.redoLength = this.redoArray.length;

        this.undoArray.push(JSON.stringify(prevState)); // this might through an exception if the encountered json is not valid
        this.length = this.undoArray.length;
    }

    pop(currentState) {
        try {

            this.redoArray.push(JSON.stringify(currentState))
            this.redoLength = this.redoArray.length;

            const updatedUndoState = JSON.parse(this.undoArray.pop()); // this might through an exception if the encountered json is not valid to handle this situation return last state -1
            this.length = this.undoArray.length;
            return updatedUndoState;
        } catch (error) {
            return JSON.parse(JSON.stringify(currentState));
        }
    }

    redoPop(currentState) {
        try {

            this.undoArray.push(JSON.stringify(currentState));
            this.length = this.undoArray.length;

            const updatedRedoState = JSON.parse(this.redoArray.pop()); // this might through an exception if the encountered json is not valid to handle this situation return last state -1
            this.redoLength = this.redoArray.length;
            return updatedRedoState;
        } catch (error) {
            return JSON.parse(JSON.stringify(currentState));
        }
    }

    clear() {
        this.undoArray = [];
    }

    redoClear() {
        this.redoArray = [];
    }

}

const undoArray = new UndoState();

export const pullUploadedMediaListFromServerThunk = createAsyncThunk(
    "mediaList",
    async (state) => {
        return await pullUploadedMediaListFromServer(state);
    }
);

export const isBotDeployed = (botStatus) => {
    return botStatus === "deployed";
};

const workflowEditorStateSlice = createSlice({
    name: "Workflow Editor Slice",
    initialState: {
        // Define your intial state
        botStatus: "deployed", // draft | save | deleted | deployed
        botId: "", //TODO GET BOT ID FROM STATE
        workflowId: "",
        name: "Workflow",
        isDefaultWorkflow: false,
        isCampaignWorkflow: false,
        nodes: [],
        edges: [],
        variables: [],
        variableSuggestionList: [],
        uploadedMediaList: [],
        currentlySelectedNode: null,
        currentlySelectedEdge: null,
        latestSelectedNodeOrEdge: null,
        endConnectionNodeDataOnMouseHover: {},
        startConnectionNodeDataOnConnectionStart: {},
        handleIdDataForEndLoopConnect: {},
        logCount: { warningCount: 0, errorCount: 0 },
        validationTypes: [],
        channels: [],
        locales: [
            { code: 'en-US', name: "US English", checked: true },
            // { code: 'hi-IN', name: "India hindi", checked: true }
        ],
        selectedLocale: 'en-US',
        defaultLocale: 'en-US',
        localeSupportedByBot: {}, // locale supported by bot

    },
    reducers: {
        // IMport all reducers here from store
        ResetStateForFlowBuilder: () => {
            return {
                // Define your intial state
                botStatus: "deployed", // draft | save | deleted | deployed
                botId: "", //TODO GET BOT ID FROM STATE
                workflowId: "",
                name: "Workflow",
                isDefaultWorkflow: false,
                isCampaignWorkflow: false,
                nodes: [],
                edges: [],
                variables: [],
                variableSuggestionList: [],
                uploadedMediaList: [],
                currentlySelectedNode: null,
                latestSelectedNodeOrEdge: null,
                endConnectionNodeDataOnMouseHover: {},
                startConnectionNodeDataOnConnectionStart: {},
                handleIdDataForEndLoopConnect: {},
                logCount: { warningCount: 0, errorCount: 0 },
                validationTypes: [],
                channels: [],
                channelsWithIntegrationId: [],
            };
        },
        UndoUserAction: (state, action) => {
            if (isBotDeployed(state.botStatus)) return state;
            if (undoArray.length > 0) {
                const lastStateData = undoArray.pop(state);
                // #TODO: redo implementation
                return lastStateData;
            } else {
                return state;
            }
        },
        RedoUserAction: (state, action) => {
            if (isBotDeployed(state.botStatus)) return state;
            if (undoArray.redoLength > 0) {
                const lastStateData = undoArray.redoPop(state);
                // #TODO: redo implementation
                return lastStateData;
            } else {
                return state;
            }
        },
        AddNewNode: (state, action) => {
            if (isBotDeployed(state.botStatus)) return state;
            const clonedState = cloneDeep(state);
            undoArray.push(clonedState);
            const data = addNewNode(clonedState, action.payload);
            return data;
        },
        DeleteNode: (state, action) => {
            if (isBotDeployed(state.botStatus)) return state;
            const clonedState = cloneDeep(state);
            undoArray.push(state);
            const updatedState = deleteNode(clonedState, action.payload);
            return updatedState;
        },
        DeleteEdge: (state, action) => {
            if (isBotDeployed(state.botStatus)) return state;
            const clonedState = cloneDeep(state);
            undoArray.push(clonedState);
            const updatedState = deleteEdge(clonedState, action.payload);
            return updatedState;
        },
        DuplicateNode: (state, action) => {
            if (isBotDeployed(state.botStatus)) return state;
            const clonedState = cloneDeep(state);
            undoArray.push(clonedState);
            return duplicateNode(clonedState, action.payload);
        },
        UpdateNode: (state, action) => {
            if (isBotDeployed(state.botStatus)) return state;
            const clonedState = cloneDeep(state);

            undoArray.push(clonedState);

            return updateNode(clonedState, action.payload);
        },
        AddVariable: (state, action) => {
            if (isBotDeployed(state.botStatus)) return state;
            return addVariable(state, action.payload);
        },
        UpdateVariableValue: (state, action) => {
            if (isBotDeployed(state.botStatus)) return state;
            return updateVariableValue(state, action.payload);
        },
        DeleteVariable: (state, action) => {
            if (isBotDeployed(state.botStatus)) return state;
            return deleteVariable(state, action.payload);
        },
        ConnectNode: (state, action) => {
            if (isBotDeployed(state.botStatus)) return state;
            const clonedState = cloneDeep(state);
            undoArray.push(clonedState);
            let newState = connectNodes(clonedState, action.payload);
            if (newState === state) {
                undoArray.pop(newState);
            }
            return newState;
        },
        UpdateNodePosition: (state, { payload }) => {
            //   undoArray.push(state);
            if (isBotDeployed(state.botStatus)) return state;
            return {
                ...state,
                nodes: payload.newNodes,
            };
        },
        UpdateEdgePosition: (state, { payload }) => {
            if (isBotDeployed(state.botStatus)) return state;
            return {
                ...state,
                edges: payload.newEdges,
            };
        },
        AddStateToUndo: (state, action) => {
            if (isBotDeployed(state.botStatus)) return state;
            const clonedState = cloneDeep(state);
            undoArray.push(clonedState);
            return state;
        },

        AddTargetHandleIdOnEndLoopConnect: (state, action) => {
            if (isBotDeployed(state.botStatus)) return state;
            return addTargetHandleIdOnEndLoopConnect(state, action.payload);
        },
        UpdateVariablePropertyList: (state, action) => {
            if (isBotDeployed(state.botStatus)) return state;
            const variablesListWithoutCurrentNodeVariables =
                state.variableSuggestionList.filter(
                    (variable) => variable.nodeId !== action.payload.currentNodeId
                );
            return {
                ...state,
                variableSuggestionList: [
                    ...variablesListWithoutCurrentNodeVariables,
                    ...action.payload.variableSuggestionList,
                ],
            };
        },
        UpdateMediaList: (state, action) => {
            return {
                ...state,
                uploadedMediaList: [...action.payload.uploadedMediaList],
            };
        },
        SetCurrentlySelecteNode: (state, action) => {
            return setCurrentlySelecteNode(state, action);
        },
        SetCurrentlySelectedEdge: (state, action) => {
            return {
                ...state,
                currentlySelectedEdge: action.payload.edge ?? null
            }
        },

        SetLatestSelectedNodeOrEdgeForDeletion: (state, action) => {
            return setLatestSelectedNodeOrEdgeForDeletion(state, action);
        },

        LoadEditorData: (state, { payload, type }) => {
            undoArray.clear();

            //#TODO: Temporary implementation for handling expected error in old workflow, implemented in backend for new Workflow
            //if that extra properties in context object adding a extra properties in context object
            // and removing the (-) symbol is exist in variableId or in nodeId
            let newPayloadForVariableSuggestionsList = payload.variableSuggestionList.map((variable => {
                if (variable.variableName === '{{context}}' && variable.nodeId.includes('-')) {
                    return {
                        ...variable,
                        nodeId: variable.nodeId.split('-').join('')
                    }
                }
                return variable;
            }));
            const extraPropertiesFound = payload.variableSuggestionList.find((variable) => (variable.display === '{{context.recipientFullName}}'));
            if (!extraPropertiesFound) {
                newPayloadForVariableSuggestionsList.push({
                    display: "{{context.recipientDocumentId}}",
                    id: "{{context.recipientDocumentId}}",
                    nodeId: "81f8dab225574777b842c7aee7930c21",
                    variableName: "{{context}}"
                });
                newPayloadForVariableSuggestionsList.push({
                    display: "{{context.recipientFullName}}",
                    id: "{{context.recipientFullName}}",
                    nodeId: "81f8dab225574777b842c7aee7930c21",
                    variableName: "{{context}}"
                });
                newPayloadForVariableSuggestionsList.push({
                    display: "{{context.recipientPhoneNumber}}",
                    id: "{{context.recipientPhoneNumber}}",
                    nodeId: "81f8dab225574777b842c7aee7930c21",
                    variableName: "{{context}}"
                });
                newPayloadForVariableSuggestionsList.push({
                    display: "{{context.recipientEmail}}",
                    id: "{{context.recipientEmail}}",
                    nodeId: "81f8dab225574777b842c7aee7930c21",
                    variableName: "{{context}}"
                });
            }

            let newPayloadForVaribles = payload.variables.map((variable) => {
                if (variable.nodeId.includes('-') || variable.variableId.includes('-')) {
                    return {
                        ...variable,
                        nodeId: variable.nodeId.split('-').join(''),
                        variableId: variable.variableId.split('-').join('')
                    }
                }
                return variable;
            });

            // =====================================================================

            return {
                ...state,
                currentlySelectedNode: null,
                latestSelectedNodeOrEdge: null,
                endConnectionNodeDataOnMouseHover: {},
                handleIdDataForEndLoopConnect: {},
                logCount: payload.logCount, // { warningCount: 0, errorCount: 0 },
                startConnectionNodeDataOnConnectionStart: {},
                nodes: payload.nodes,
                edges: payload.edges,
                variables: newPayloadForVaribles,
                botId: payload.botId,
                workflowId: payload.workflowId,
                name: payload.name,
                isDefaultWorkflow: payload.isDefaultWorkflow,
                isCampaignWorkflow: payload.isCampaignWorkflow,
                uploadedMediaList: payload.uploadedMediaList,
                variableSuggestionList: newPayloadForVariableSuggestionsList,
                botStatus: payload.botStatus,
                validationTypes: payload.validationTypes,
                channels: payload.channels,
                channelsWithIntegrationId: payload.channelsWithIntegrationId,
                integrations: payload.integrations,
                botVersion: payload.botVersion,
                locales: payload.locales, // locales supported pinnacle
                selectedLocale: payload.selectedLocale, // 'en-US', // editor current locale
                defaultLocale: payload.defaultLocale, // default locale of a bot 
                localeSupportedByBot: payload.localeSupportedByBot // locale supported by bot
            };
        },
        CheckUnConnectedNodes: (state, action) => {
            const clonedState = cloneDeep(state);
            const updatedState = checkUnConnectedNodes(clonedState, action);
            return updatedState;
        },
        UpdateNodesChannelArray: (state, action) => {
            const clonedState = cloneDeep(state);
            const updatedNodes = updateChannelArrayOfNodes(clonedState, action);
            return { ...state, nodes: updatedNodes };
        },
        UpdateBotStatus: (state, action) => {
            return { ...state, botStatus: action.payload.botStatus };
        },
        ShowCurrentCardInSimulator: (state, action) => {
            // Step one extract all nodeId all node id will be in sequence
            const nodeIds = action.payload.map((item) => item.nodeId);
            const newNodesArrays = state.nodes.map((node) => {
                const found = nodeIds.includes(node.id);
                if (found) {
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            currentNodeInTestSimulator: found,
                        }
                    }
                } else {
                    return node;
                }
            });

            return {
                ...state,
                nodes: newNodesArrays,
            }
        },
        ResetCurrentCardInSimulatorState: (state, action) => {
            const newNodesArrays = state.nodes.map((node) => {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        currentNodeInTestSimulator: false
                    }
                }
            });

            return {
                ...state,
                nodes: newNodesArrays,
            }
        },
        UpdateLocale: (state, { payload }) => {
            if (payload.locales) {
                state.locales = payload.locales;
            }
            if (payload.defaultLocale) {
                state.defaultLocale = payload.defaultLocale;
            }
            if (payload.selectedLocale) {
                state.selectedLocale = payload.selectedLocale;
            }
        },
        UpdateBotSupportedLocale: (state, { payload }) => {
            if (payload.type === 'add') {
                const locale = state.locales.find((item) => item.code === payload.locale);
                if (locale) {
                    locale.checked = true;
                }
            }

            if (payload.type === 'remove') {

                // Check if atleast one locale is enabled or not

                const locale = state.locales.find((item) => item.code === payload.locale);
                if (locale) {
                    locale.checked = false;
                }
                const firstLocaleWhichIsEnabled = state.locales.find((item) => item.checked === true);

                if (payload.locale === state.defaultLocale) {
                    // Select select locale is as removed locale then we need to change to default locale
                    state.defaultLocale = firstLocaleWhichIsEnabled.code;
                }
                if (payload.locale === state.selectedLocale) {
                    // Select select locale is as removed locale then we need to change to default
                    state.selectedLocale = firstLocaleWhichIsEnabled.code;
                }
            }

            let nodesToUpdate = state.nodes;
            if (!state.localeSupportedByBot[payload.locale]) { // This property does not exist then add the given locale to all nodes
                nodesToUpdate = addLocaleToAllRenderableNode({ nodes: state.nodes, locale: payload.locale });
            }
            // here we are updating the state of localeSupportedByBot based on checked true false payload
            let newLocaleSupportedByBot = { ...state.localeSupportedByBot, [payload.locale]: payload.type === 'add' ? true : false };
            nodesToUpdate = updateLocaleErrorOnInitialEditorDataLoad({ nodes: nodesToUpdate, localeSupportedByBot: newLocaleSupportedByBot });
            state.nodes = nodesToUpdate;
            state.localeSupportedByBot = newLocaleSupportedByBot;
            state.logCount = {
                errorCount: calculateErrorCount(nodesToUpdate),
                warningCount: calculateWarningCount(nodesToUpdate),
            };
        },
        MarkVariableAsSensitiveVariable: (state, { payload }) => {
            return markVariableAsSentiveVariable(state, payload);
        },
        SetNodesAndEdges: (state, action) => {
            return {
                ...state,
                nodes: action.payload?.nodes ? action.payload.nodes : state.nodes,
                edges: action.payload?.edges ? action.payload.edges : state.edges
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(
            pullUploadedMediaListFromServerThunk.fulfilled,
            (state, action) => {
                state.uploadedMediaList = [...action.payload];
            }
        );
    },
});

export const {
    UndoUserAction,
    AddNewNode,
    DeleteNode,
    DeleteEdge,
    DuplicateNode,
    UpdateNode,
    AddVariable,
    UpdateVariableValue,
    DeleteVariable,
    SetCurrentlySelecteNode,
    SetCurrentlySelectedEdge,
    SetLatestSelectedNodeOrEdgeForDeletion,
    ConnectNode,
    UpdateNodePosition,
    UpdateEdgePosition,
    AddStateToUndo,
    AddTargetHandleIdOnEndLoopConnect,
    DeployBot,
    UpdateVariablePropertyList,
    UpdateMediaList,
    LoadEditorData,
    CheckUnConnectedNodes,
    UpdateNodesChannelArray,
    ResetStateForFlowBuilder,
    UpdateBotStatus,
    RedoUserAction,
    ShowCurrentCardInSimulator,
    ResetCurrentCardInSimulatorState,
    UpdateLocale,
    UpdateBotSupportedLocale,
    MarkVariableAsSensitiveVariable,
    SetNodesAndEdges,
} = workflowEditorStateSlice.actions;

export default workflowEditorStateSlice.reducer;
