import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/workflowAxios";
import { uuid } from "short-uuid";

const findCurrentScreenIndex = (state, screen) => {
  return state.flowJson.TemporaryFlowJsonScreens.findIndex(
    (component) => component.id === screen.id
  );
};

const findChildIndex = (state, screenIndex, parentComponentData) => {
  return state.flowJson.TemporaryFlowJsonScreens[
    screenIndex
  ].layout.childrens.findIndex(
    (components) => components.u_id === parentComponentData.u_id
  );
};

const updateTemporaryFlowJson = (
  state,
  screenIndex,
  childIndex,
  dataToUpdate
) => {
  if (dataToUpdate["center-caption"] && dataToUpdate["center-caption"] !== "") {
    delete dataToUpdate["right-caption"];
    delete dataToUpdate["left-caption"];
  } else if (!dataToUpdate["center-caption"]) {
    delete dataToUpdate["center-caption"];
  }
  return (state.flowJson.TemporaryFlowJsonScreens[screenIndex].layout.childrens[
    childIndex
  ].componentData.value = dataToUpdate);
};

const updateSimulatorJson = (state, screenIndex, childIndex, dataToUpdate) => {
  state.simulator.screenData[screenIndex].layout.childrens[
    childIndex
  ].componentData.value = dataToUpdate;
};

const getComponentData = (state, screenIndex, childIndex) => {
  return state.flowJson.TemporaryFlowJsonScreens[screenIndex].layout.childrens[
    childIndex
  ].componentData.value;
};

const initialStates = {
  flow: {
    flowId: "",
    categories: "",
    workflowJson: {},
    status: "",
    flowName: "",
    createdAt: "",
    screens: [],
  },

  screen: {
    currentWorkingScreen: null,
    allScreens: [],
  },

  flowJson: {
    TemporaryFlowJsonScreens: [],
    dataChannelUri: null,
    finalFlowJson: null,
    flowJsonToShow: null,
    showJsonModal: false,
  },

  showPropertyWindowTab: {
    state: false,
    item: "",
  },

  simulator: {
    screenData: [],
  },

  updatedFlowData: null,

  completeActionInFooterButtonExist: false,

  flowsListingData: {
    flows: [],
    page: 1,
    searchText: "",
    totalCount: 0,
    pageSize: 4,
    categories: [],
    whatsappChannelIntegrations: [],
  },

  dropDownOptionslist: [],

  CallDataChannelUrlOnClicked: {
    state: false,
    uuid: "",
  },
  CallDataChannelUrlOnClickeddropdown: {
    state: false,
    uuid: "",
  },
  CallDataChannelUrlOnClickedCheckBox: {
    state: false,
    uuid: "",
  },
};

const whatsAppFlowSlice = createSlice({
  name: "whatsAppFlowSlice",

  initialState: initialStates,

  reducers: {
    resetAllStates: () => initialStates,

    /**
     * @typedef { Array<{ value:any, name: 'flows' | 'page' | 'searchText' | 'totalCount' | 'categories' | 'whatsappChannelIntegrations' }> } TActionPayload
     * @param {{ type:string, payload:TActionPayload }} action
     */
    setFlowListingData: (state, action) => {
      const actionPayload = action.payload;
      const newState = {};
      if (actionPayload.length > 0) {
        for (let index = 0; index < actionPayload.length; index++) {
          const element = actionPayload[index];
          newState[element.name] = element.value;
        }
      }
      state.flowsListingData = { ...state.flowsListingData, ...newState };
    },

    deleteDynamicVariableInSlice: (state, action) => {
      const { name, value } = action.payload;
      const flowJson = state.flowJson.TemporaryFlowJsonScreens;
      for (let index = 0; index < flowJson.length; index++) {
        const element = flowJson[index];
        if (element.id === value) {
          //value is a screen name
          //delete varaible from data.variable
          const variabledata = element.data.variables;
          if (
            state.flowJson.TemporaryFlowJsonScreens[index].data.variables
              .length === 1
          ) {
            state.flowJson.TemporaryFlowJsonScreens[index].data = {};
          } else {
            const newArray = variabledata.filter((obj) => {
              if (obj.name !== name) {
                return obj;
              }
            });
            let tempJson = JSON.parse(
              JSON.stringify(
                state.flowJson.TemporaryFlowJsonScreens[index].data
              )
            );
            tempJson.variables = newArray;
            state.flowJson.TemporaryFlowJsonScreens[index].data = tempJson;
          }

          const childrenData = JSON.parse(
            JSON.stringify(element.layout.childrens)
          );

          //delete varaible actually used in flowJson

          for (let index = 0; index < childrenData.length; index++) {
            const newObject = JSON.parse(
              JSON.stringify(childrenData[index].componentData.value)
            );
            for (const key in newObject) {
              console.log(`${key}:${newObject[key]}`);

              const dataVaraibleToDelete =
                (newObject[key] instanceof String ||
                  typeof newObject[key] === "string") &&
                newObject[key].includes("${data.") &&
                newObject[key].split("${data.")[1].split("}")[0];

              if (dataVaraibleToDelete === name) {
                newObject[key] = null;
              }
            }
            childrenData[index].componentData.value = newObject;
            // state.flowJson.TemporaryFlowJsonScreens[index].layout.childrens[
            //   index
            // ].componentData.value = newObject;
          }

          state.flowJson.TemporaryFlowJsonScreens[index].layout.childrens =
            childrenData;
          state.simulator.screenData[index].layout.childrens = childrenData;
        }
      }
      state.showPropertyWindowTab = {
        state: false,
        item: "",
      };
    },

    updateFlowName: (state, action) => {
      const { flowName } = action.payload;
      state.flow.flowName = flowName;
    },

    setFlowAndScreenData: (state, action) => {
      const {
        flowId,
        flowName,
        categories,
        createdAt,
        status,
        workflowJson,
        screens,
        dataChannelComponent,
      } = action.payload;
      state.flow.flowId = flowId;
      state.flow.flowName = flowName;
      state.flow.categories = categories;
      state.flow.createdAt = createdAt;
      state.flow.status = status;
      state.flowJson.TemporaryFlowJsonScreens = workflowJson;
      state.simulator.screenData = workflowJson;
      state.flowJson.dataChannelUri = dataChannelComponent;
      state.flow.screens = screens;
      state.screen.allScreens = screens;
      state.screen.currentWorkingScreen = screens.find(
        (screen) => screen.screenIndex === 0
      );
    },

    setFlowNameAndCategory: (state, action) => {
      const { flowName, categories, name } = action.payload;
      switch (name) {
        case "flowName":
          state.controls.flowName = flowName;
          break;
        case "categories":
          state.controls.categories = categories;
          break;
        default:
          break;
      }
    },

    //  ------------------------------------------------ Screens ----------------------------------------------------------------

    setNewScreen: (state, action) => {
      const { screenName, screenIndex } = action.payload;

      const payloadToAddInAllScreen = {
        title: screenName,
        id: screenName.trim().toUpperCase().split(" ").join("_"),
        screenIndex: screenIndex,
      };

      const payloadToAddInTemporaryFlowJson = {
        id: payloadToAddInAllScreen.id,
        title: payloadToAddInAllScreen.title,
        data: {},
        layout: { type: "SingleColumnLayout", childrens: [] },
      };

      const payloadToAddForSimulatorScreenData = {
        id: payloadToAddInAllScreen.id,
        title: payloadToAddInAllScreen.title,
        data: {},
        layout: { type: "SingleColumnLayout", childrens: [] },
      };

      state.simulator.screenData.push(payloadToAddForSimulatorScreenData);

      state.screen.allScreens.push(payloadToAddInAllScreen);
      state.flowJson.TemporaryFlowJsonScreens.push(
        payloadToAddInTemporaryFlowJson
      );
      // state.modals.showCreateScreenModal = false;
      state.screen.currentWorkingScreen = state.screen.allScreens[screenIndex];
      state.showPropertyWindowTab = {
        state: false,
        item: "",
      };
    },

    deleteScreen: (state, action) => {
      const allScreen = [];
      const { screenIndex, currentScreenData } = action.payload;
      const tempFlowIndexToDelete = findCurrentScreenIndex(
        state,
        currentScreenData
      );
      state.screen.allScreens.splice(screenIndex, 1);
      state.flowJson.TemporaryFlowJsonScreens.splice(tempFlowIndexToDelete, 1);
      state.simulator.screenData.splice(tempFlowIndexToDelete, 1);
      state.screen.allScreens.map((screenData, index) => {
        const screenObject = {
          ...screenData,
          screenIndex: index,
        };
        allScreen.push(screenObject);
      });
      state.screen.allScreens = allScreen;
      state.screen.currentWorkingScreen = state.screen.allScreens[0];
      state.showPropertyWindowTab = {
        state: false,
        item: "",
      };
    },

    changeCurrentFlowScreen: (state, action) => {
      const screenData = action.payload;
      state.screen.currentWorkingScreen = screenData;
      state.showPropertyWindowTab = {
        state: false,
        item: "",
      };
    },

    onChangeScreenDrop: (state, action) => {
      const {
        currentScreenIndex,
        dropingScreenIndex,
        screenData,
        currentScreenData,
      } = action.payload;

      state.screen.allScreens.splice(currentScreenIndex, 1);
      state.flowJson.TemporaryFlowJsonScreens.splice(currentScreenIndex, 1);
      state.simulator.screenData.splice(currentScreenIndex, 1);

      const indexToAddTheScreenData =
        currentScreenIndex > dropingScreenIndex
          ? dropingScreenIndex + 1
          : dropingScreenIndex;

      state.screen.allScreens.splice(
        dropingScreenIndex === -1
          ? dropingScreenIndex + 1
          : indexToAddTheScreenData,
        0,
        screenData
      );
      state.flowJson.TemporaryFlowJsonScreens.splice(
        dropingScreenIndex === -1
          ? dropingScreenIndex + 1
          : indexToAddTheScreenData,
        0,
        currentScreenData
      );
      state.simulator.screenData.splice(
        dropingScreenIndex === -1
          ? dropingScreenIndex + 1
          : indexToAddTheScreenData,
        0,
        currentScreenData
      );

      state.showPropertyWindowTab = {
        state: false,
        item: "",
      };
    },

    // -----------------------------------------------  Flow Json ----------------------------------------------------------------
    saveFlowComponentValueToTemporaryFlowJson: (state, action) => {
      const { componentData, index, currentScreen } = action.payload;

      const indexOfScreen = findCurrentScreenIndex(state, currentScreen);

      updateTemporaryFlowJson(state, indexOfScreen, index, componentData);
    },

    addComponentToTemporaryFlowComponents: (state, action) => {
      const { componentData, screenData, index } = action.payload;

      const screenIndex = findCurrentScreenIndex(state, screenData);

      const payloadToAddInTemoporaryComponentArray = {
        screenId: screenData.id,
        u_id:
          screenData.id +
          "_" +
          componentData.type +
          "_" +
          Math.floor(Math.random() * 1000) +
          "_" +
          state.flowJson.TemporaryFlowJsonScreens[screenIndex].layout.childrens
            .length,
        type: componentData.type,
        index,
        componentData: {
          type: componentData.type,
          value: null,
        },
      };

      if (componentData.type === "DataChannelUrl") {
        state.flowJson.dataChannelUri = payloadToAddInTemoporaryComponentArray;
      }

      const payloadToAddInSimulatorScreenData = {
        screenId: screenData.id,
        type: componentData.type,
        u_id:
          screenData.id +
          "_" +
          componentData.type +
          "_" +
          Math.floor(Math.random() * 1000) +
          "_" +
          state.flowJson.TemporaryFlowJsonScreens[screenIndex].layout.childrens
            .length,
        componentData: {
          type: componentData.type,
          value: null,
        },
      };

      state.simulator.screenData[screenIndex].layout.childrens.push(
        payloadToAddInSimulatorScreenData
      );

      if (componentData.type === "Variable") {
        state.flowJson.TemporaryFlowJsonScreens[
          screenIndex
        ].layout.childrens.splice(0, 0, payloadToAddInTemoporaryComponentArray);
      } else {
        state.flowJson.TemporaryFlowJsonScreens[
          screenIndex
        ].layout.childrens.push(payloadToAddInTemoporaryComponentArray);
      }

      state.showPropertyWindowTab = {
        state: true,
        item: componentData.type,
        value: payloadToAddInTemoporaryComponentArray,
      };
    },

    //  -------------------------------------------- Button Functions ---------------------------------------------------------

    addButtonLabel: (state, action) => {
      const { currentScreenData, parentComponentData, inputDataToUpdate } =
        action.payload;

      const indexOfScreen = findCurrentScreenIndex(state, currentScreenData);
      const childIndex = findChildIndex(
        state,
        indexOfScreen,
        parentComponentData
      );
      const temporaryFlowJsonComponentData = getComponentData(
        state,
        indexOfScreen,
        childIndex
      );

      if (temporaryFlowJsonComponentData === null) {
        updateTemporaryFlowJson(
          state,
          indexOfScreen,
          childIndex,
          inputDataToUpdate
        );
        updateSimulatorJson(
          state,
          indexOfScreen,
          childIndex,
          inputDataToUpdate
        );
      } else {
        updateTemporaryFlowJson(state, indexOfScreen, childIndex, {
          ...temporaryFlowJsonComponentData,
          ...inputDataToUpdate,
        });
        updateSimulatorJson(state, indexOfScreen, childIndex, {
          ...temporaryFlowJsonComponentData,
          ...inputDataToUpdate,
        });
      }
    },

    addButtonItems: (state, action) => {
      const { currentScreenData, parentComponentData, buttonDataToUpdate } =
        action.payload;
      const indexOfScreen = findCurrentScreenIndex(state, currentScreenData);
      const childIndex = findChildIndex(
        state,
        indexOfScreen,
        parentComponentData
      );

      const temporaryFlowJsonComponentData = getComponentData(
        state,
        indexOfScreen,
        childIndex
      );

      if (temporaryFlowJsonComponentData === null) {
        updateTemporaryFlowJson(
          state,
          indexOfScreen,
          childIndex,
          buttonDataToUpdate
        );
        updateSimulatorJson(
          state,
          indexOfScreen,
          childIndex,
          buttonDataToUpdate
        );
      } else {
        updateTemporaryFlowJson(state, indexOfScreen, childIndex, {
          ...temporaryFlowJsonComponentData,
          ...buttonDataToUpdate,
        });
        updateSimulatorJson(state, indexOfScreen, childIndex, {
          ...temporaryFlowJsonComponentData,
          ...buttonDataToUpdate,
        });
      }
    },

    pushDynamicButtonOptions: (state, action) => {
      const { currentScreenData, parentComponentData, dynamicArrayVariable } =
        action.payload;

      const indexOfScreen = findCurrentScreenIndex(state, currentScreenData);
      const childIndex = findChildIndex(
        state,
        indexOfScreen,
        parentComponentData
      );

      const screenDataComponentDataObject =
        state.simulator.screenData[indexOfScreen].layout.childrens[childIndex]
          .componentData;
      const temporaryFlowJsonComponentDataObject =
        state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout.childrens[
          childIndex
        ].componentData;

      if ([null, undefined].includes(temporaryFlowJsonComponentDataObject)) {
        console.log("error while adding buttons in TemporaryFlowJsonScreens");
        return;
      }

      if ([null, undefined].includes(screenDataComponentDataObject)) {
        console.log("error while adding buttons in screenData");
        return;
      }

      const getTemporaryFlowJsonValueToUpdate = () => {
        if (
          [null, undefined].includes(temporaryFlowJsonComponentDataObject.value)
        ) {
          return { "data-source": dynamicArrayVariable };
        }

        if (
          [null, undefined].includes(
            temporaryFlowJsonComponentDataObject.value["data-source"]
          )
        ) {
          return {
            ...temporaryFlowJsonComponentDataObject.value,
            "data-source": dynamicArrayVariable,
          };
        }

        if (
          Array(
            temporaryFlowJsonComponentDataObject.value["data-source"].includes(
              "{data."
            )
          )
        ) {
          return {
            ...temporaryFlowJsonComponentDataObject.value,
            "data-source": dynamicArrayVariable,
          };
        }
        if (
          Array.isArray(
            temporaryFlowJsonComponentDataObject.value["data-source"]
          )
        ) {
          return {
            ...temporaryFlowJsonComponentDataObject.value,
            "data-source": dynamicArrayVariable,
          };
        }
      };

      const getScreenDataValueToUpdate = () => {
        if ([null, undefined].includes(screenDataComponentDataObject.value)) {
          return { "data-source": dynamicArrayVariable };
        }

        if (
          [null, undefined].includes(
            screenDataComponentDataObject.value["data-source"]
          )
        ) {
          return {
            ...screenDataComponentDataObject.value,
            "data-source": dynamicArrayVariable,
          };
        }
        if (
          Array(
            screenDataComponentDataObject.value["data-source"].includes(
              "{data."
            )
          )
        ) {
          return {
            ...screenDataComponentDataObject.value,
            "data-source": dynamicArrayVariable,
          };
        }
        if (Array.isArray(screenDataComponentDataObject.value["data-source"])) {
          return {
            ...screenDataComponentDataObject.value,
            "data-source": dynamicArrayVariable,
          };
        }
      };

      updateTemporaryFlowJson(
        state,
        indexOfScreen,
        childIndex,
        getTemporaryFlowJsonValueToUpdate()
      );
      updateSimulatorJson(
        state,
        indexOfScreen,
        childIndex,
        getScreenDataValueToUpdate()
      );
      // state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout.childrens[childIndex].componentData.value = getTemporaryFlowJsonValueToUpdate();
      // state.simulator.screenData[indexOfScreen].layout.childrens[childIndex].componentData.value = getScreenDataValueToUpdate();
    },

    pushButtonItemsInArray: (state, action) => {
      const { currentScreenData, parentComponentData, buttonDataToUpdate } =
        action.payload;
      const indexOfScreen = findCurrentScreenIndex(state, currentScreenData);
      const childIndex = findChildIndex(
        state,
        indexOfScreen,
        parentComponentData
      );

      const objectToPush = {
        id: buttonDataToUpdate,
        title: buttonDataToUpdate,
      };

      const screenDataComponentDataObject =
        state.simulator.screenData[indexOfScreen].layout.childrens[childIndex]
          .componentData;
      const temporaryFlowJsonComponentDataObject =
        state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout.childrens[
          childIndex
        ].componentData;

      // =================================== Preliminary Checks it should not happen ======================================
      if ([null, undefined].includes(temporaryFlowJsonComponentDataObject)) {
        console.log("error while adding buttons in TemporaryFlowJsonScreens");
        return;
      }

      if ([null, undefined].includes(screenDataComponentDataObject)) {
        console.log("error while adding buttons in screenData");
        return;
      }
      // ====================================================================================================================

      const getTemporaryFlowJsonValueToUpdate = () => {
        if (
          [null, undefined].includes(temporaryFlowJsonComponentDataObject.value)
        ) {
          return { "data-source": [{ ...objectToPush }] };
        }

        if (
          [null, undefined].includes(
            temporaryFlowJsonComponentDataObject.value["data-source"]
          )
        ) {
          return {
            ...temporaryFlowJsonComponentDataObject.value,
            "data-source": [{ ...objectToPush }],
          };
        }

        if (
          temporaryFlowJsonComponentDataObject.value["data-source"].includes(
            "{data."
          )
        ) {
          return {
            ...temporaryFlowJsonComponentDataObject.value,
            "data-source": [{ ...objectToPush }],
          };
        }

        if (
          Array.isArray(
            temporaryFlowJsonComponentDataObject.value["data-source"]
          )
        ) {
          return {
            ...temporaryFlowJsonComponentDataObject.value,
            "data-source": [
              ...temporaryFlowJsonComponentDataObject.value["data-source"],
              { ...objectToPush },
            ],
          };
        }
      };

      const getScreenDataValueToUpdate = () => {
        if ([null, undefined].includes(screenDataComponentDataObject.value)) {
          return { "data-source": [{ ...objectToPush }] };
        }

        if (
          [null, undefined].includes(
            screenDataComponentDataObject.value["data-source"]
          )
        ) {
          return {
            ...screenDataComponentDataObject.value,
            "data-source": [{ ...objectToPush }],
          };
        }

        if (
          screenDataComponentDataObject.value["data-source"].includes("{data.")
        ) {
          return {
            ...screenDataComponentDataObject.value,
            "data-source": [{ ...objectToPush }],
          };
        }

        if (Array.isArray(screenDataComponentDataObject.value["data-source"])) {
          return {
            ...screenDataComponentDataObject.value,
            "data-source": [
              ...screenDataComponentDataObject.value["data-source"],
              { ...objectToPush },
            ],
          };
        }
      };

      updateTemporaryFlowJson(
        state,
        indexOfScreen,
        childIndex,
        getTemporaryFlowJsonValueToUpdate()
      );
      updateSimulatorJson(
        state,
        indexOfScreen,
        childIndex,
        getScreenDataValueToUpdate()
      );
      // state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout.childrens[childIndex].componentData.value = getTemporaryFlowJsonValueToUpdate();
      // state.simulator.screenData[indexOfScreen].layout.childrens[childIndex].componentData.value = getScreenDataValueToUpdate();
    },

    deleteButtonItem: (state, action) => {
      const { index, currentScreenData, parentComponentData } = action.payload;
      const indexOfScreen = findCurrentScreenIndex(state, currentScreenData);
      const childIndex = findChildIndex(
        state,
        indexOfScreen,
        parentComponentData
      );

      state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout.childrens[
        childIndex
      ].componentData.value["data-source"].splice(index, 1);
      state.simulator.screenData[indexOfScreen].layout.childrens[
        childIndex
      ].componentData.value["data-source"].splice(index, 1);
    },

    deleteDataChannelUrlComponent: (state, action) => {
      state.flowJson.dataChannelUri = null;
      state.showPropertyWindowTab = {
        state: false,
        item: null,
        value: null,
      };
    },

    deleteVariableExample: (state, action) => {
      const { index, currentScreenData } = action.payload;
      const indexOfScreen = findCurrentScreenIndex(state, currentScreenData);

      state.flowJson.TemporaryFlowJsonScreens[
        indexOfScreen
      ].data.variables.splice(index, 1);
    },

    setNameOfFooterLabel: (state, action) => {
      const { currentScreenData, parentComponentData, footerDataToUpdate } =
        action.payload;

      const indexOfScreen = findCurrentScreenIndex(state, currentScreenData);
      const childIndex = findChildIndex(
        state,
        indexOfScreen,
        parentComponentData
      );

      const temporaryFlowJsonComponentData = getComponentData(
        state,
        indexOfScreen,
        childIndex
      );
      if (temporaryFlowJsonComponentData === null) {
        updateTemporaryFlowJson(
          state,
          indexOfScreen,
          childIndex,
          footerDataToUpdate
        );
        updateSimulatorJson(
          state,
          indexOfScreen,
          childIndex,
          footerDataToUpdate
        );
      } else {
        updateTemporaryFlowJson(state, indexOfScreen, childIndex, {
          ...temporaryFlowJsonComponentData,
          ...footerDataToUpdate,
        });
        updateSimulatorJson(state, indexOfScreen, childIndex, {
          ...temporaryFlowJsonComponentData,
          ...footerDataToUpdate,
        });
      }

      state.showPropertyWindowTab = {
        state: true,
        item: state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
          .childrens[childIndex].type,
        value:
          state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
            .childrens[childIndex],
      };
    },

    setFooterAction: (state, action) => {
      const { footerAction, currentScreenData, parentComponentData } =
        action.payload;
      const indexOfScreen = findCurrentScreenIndex(state, currentScreenData);
      const childIndex = findChildIndex(
        state,
        indexOfScreen,
        parentComponentData
      );

      const temporaryFlowJsonComponentData = getComponentData(
        state,
        indexOfScreen,
        childIndex
      );

      const indexOfCurrentScreen = state.screen.allScreens.findIndex(
        (screen) => screen.id === currentScreenData.id
      );

      if (footerAction === "complete") {
        state.completeActionInFooterButtonExist = true;
      }
      let payloadToAdd;

      if (temporaryFlowJsonComponentData === null) {
        if (footerAction === "navigate") {
          payloadToAdd = {
            "on-click-action": {
              name: footerAction,
              next: {
                type: "screen",
                name: state.screen.allScreens[indexOfCurrentScreen + 1].id,
              },
              payload: {},
            },
          };
        } else {
          payloadToAdd = {
            "on-click-action": {
              name: footerAction,
              payload: {},
            },
          };
        }

        updateTemporaryFlowJson(state, indexOfScreen, childIndex, {
          ...payloadToAdd,
        });
      } else {
        if (footerAction === "navigate") {
          payloadToAdd = {
            "on-click-action": {
              name: footerAction,
              next: {
                type: "screen",
                name: state.screen.allScreens[indexOfCurrentScreen + 1].id,
              },
              payload: {},
            },
          };
        } else {
          payloadToAdd = {
            "on-click-action": {
              name: footerAction,
              payload: {},
            },
          };
        }

        updateTemporaryFlowJson(state, indexOfScreen, childIndex, {
          ...temporaryFlowJsonComponentData,
          ...payloadToAdd,
        });
      }
    },

    addExtraVariable: (state, action) => {
      const { variableToAdd, currentScreenData } = action.payload;

      const indexOfScreen = findCurrentScreenIndex(state, currentScreenData);

      if (
        state.flowJson.TemporaryFlowJsonScreens[indexOfScreen]?.data?.variables
      ) {
        state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].data = {
          ...state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].data,
          variables: [
            ...state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].data
              .variables,
            { ...variableToAdd },
          ],
        };
      } else {
        state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].data = {
          ...state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].data,
          variables: [{ ...variableToAdd }],
        };
      }
    },

    // ------------------------------------------------ Inputs ----------------------------------------------------------------

    addConstantVariableToButton: (state, action) => {
      const { currentScreenData, parentComponentData, inputDataToUpdate } =
        action.payload;
      const indexOfScreen = findCurrentScreenIndex(state, currentScreenData);
      const childIndex = findChildIndex(
        state,
        indexOfScreen,
        parentComponentData
      );

      state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout.childrens[
        childIndex
      ].componentData.value["on-select-action"].payload = {
        ...state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
          .childrens[childIndex].componentData.value["on-select-action"]
          .payload,
        ...inputDataToUpdate,
      };
    },

    addInputLabel: (state, action) => {
      const { currentScreenData, parentComponentData, inputDataToUpdate } =
        action.payload;
      const indexOfScreen = findCurrentScreenIndex(state, currentScreenData);
      const childIndex = findChildIndex(
        state,
        indexOfScreen,
        parentComponentData
      );
      const temporaryFlowJsonComponentData = getComponentData(
        state,
        indexOfScreen,
        childIndex
      );

      if (temporaryFlowJsonComponentData === null) {
        updateTemporaryFlowJson(
          state,
          indexOfScreen,
          childIndex,
          inputDataToUpdate
        );
        updateSimulatorJson(
          state,
          indexOfScreen,
          childIndex,
          inputDataToUpdate
        );
      } else {
        updateTemporaryFlowJson(state, indexOfScreen, childIndex, {
          ...temporaryFlowJsonComponentData,
          ...inputDataToUpdate,
        });
        updateSimulatorJson(state, indexOfScreen, childIndex, {
          ...temporaryFlowJsonComponentData,
          ...inputDataToUpdate,
        });
      }

      state.showPropertyWindowTab = {
        state: true,
        item: state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
          .childrens[childIndex].type,
        value:
          state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
            .childrens[childIndex],
      };
    },

    setInputComponentRequiredField: (state, action) => {
      const { isRequired, currentScreenData, parentComponentData, name } =
        action.payload;
      const indexOfScreen = findCurrentScreenIndex(state, currentScreenData);
      const childIndex = findChildIndex(
        state,
        indexOfScreen,
        parentComponentData
      );
      const temporaryFlowJsonComponentData = getComponentData(
        state,
        indexOfScreen,
        childIndex
      );

      if (temporaryFlowJsonComponentData === null) {
        updateTemporaryFlowJson(state, indexOfScreen, childIndex, {
          label: null,
          required: isRequired,
          name,
        });
      } else {
        state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout.childrens[
          childIndex
        ].componentData.value.required = isRequired;
      }

      state.showPropertyWindowTab = {
        state: true,
        item: state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
          .childrens[childIndex].type,
        value:
          state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
            .childrens[childIndex],
      };
    },

    // -------------------------------------------------- Headers ---------------------------------------------------------------
    setTextHeadingAndSubHeading: (state, action) => {
      // Updated
      const { currentScreenData, parentComponentData, headerDataToUpdate } =
        action.payload;
      const indexOfScreen = findCurrentScreenIndex(state, currentScreenData);

      const childIndex = findChildIndex(
        state,
        indexOfScreen,
        parentComponentData
      );
      const temporaryFlowJsonComponentData = getComponentData(
        state,
        indexOfScreen,
        childIndex
      );

      if (temporaryFlowJsonComponentData === null) {
        updateTemporaryFlowJson(
          state,
          indexOfScreen,
          childIndex,
          headerDataToUpdate
        );
        updateSimulatorJson(
          state,
          indexOfScreen,
          childIndex,
          headerDataToUpdate
        );
      } else {
        updateTemporaryFlowJson(state, indexOfScreen, childIndex, {
          ...temporaryFlowJsonComponentData,
          ...headerDataToUpdate,
        });
        updateSimulatorJson(state, indexOfScreen, childIndex, {
          ...temporaryFlowJsonComponentData,
          ...headerDataToUpdate,
        });
      }

      state.showPropertyWindowTab = {
        state: true,
        item: state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
          .childrens[childIndex].type,
        value:
          state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
            .childrens[childIndex],
      };
    },

    setTextBodyInput: (state, action) => {
      const { currentScreenData, parentComponentData, textBodyDataToUpdate } =
        action.payload;
      const indexOfScreen = findCurrentScreenIndex(state, currentScreenData);
      const childIndex = findChildIndex(
        state,
        indexOfScreen,
        parentComponentData
      );
      const temporaryFlowJsonComponentData = getComponentData(
        state,
        indexOfScreen,
        childIndex
      );

      if (temporaryFlowJsonComponentData === null) {
        updateTemporaryFlowJson(
          state,
          indexOfScreen,
          childIndex,
          textBodyDataToUpdate
        );
        updateSimulatorJson(
          state,
          indexOfScreen,
          childIndex,
          textBodyDataToUpdate
        );
      } else {
        updateTemporaryFlowJson(state, indexOfScreen, childIndex, {
          ...temporaryFlowJsonComponentData,
          ...textBodyDataToUpdate,
        });
        updateSimulatorJson(state, indexOfScreen, childIndex, {
          ...temporaryFlowJsonComponentData,
          ...textBodyDataToUpdate,
        });
      }

      state.showPropertyWindowTab = {
        state: true,
        item: state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
          .childrens[childIndex].type,
        value:
          state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout
            .childrens[childIndex],
      };
    },

    deleteComponetFromSlice: (state, action) => {
      const { currentScreen, parentComponentData } = action.payload;
      const indexOfScreen = findCurrentScreenIndex(state, currentScreen);
      const childIndex = findChildIndex(
        state,
        indexOfScreen,
        parentComponentData
      );

      state.flowJson.TemporaryFlowJsonScreens[
        indexOfScreen
      ].layout.childrens.splice(childIndex, 1);
      state.simulator.screenData[indexOfScreen].layout.childrens.splice(
        childIndex,
        1
      );
      state.showPropertyWindowTab = {
        state: false,
        item: "",
      };
    },

    clearDropDownListState: (state, action) => {
      state.dropDownOptionslist = [];
    },

    //  -------------------------------------------- Others ---------------------------------------------------------------------
    setDatePicker: (state, action) => {
      const { datePickerLabel, currentScreenData, parentComponentData } =
        action.payload;
      const indexOfScreen = findCurrentScreenIndex(state, currentScreenData);

      const childIndex = findChildIndex(
        state,
        indexOfScreen,
        parentComponentData
      );
      const temporaryFlowJsonComponentData = getComponentData(
        state,
        indexOfScreen,
        childIndex
      );

      if (temporaryFlowJsonComponentData === null) {
        updateTemporaryFlowJson(state, indexOfScreen, childIndex, {
          label: datePickerLabel,
        });
        updateSimulatorJson(state, indexOfScreen, childIndex, {
          label: datePickerLabel,
        });
      } else {
        updateTemporaryFlowJson(
          state,
          indexOfScreen,
          childIndex,
          datePickerLabel
        );
        updateSimulatorJson(state, indexOfScreen, childIndex, datePickerLabel);
      }
    },

    setImageSrc: (state, action) => {
      const { imageSrc, currentScreenData, parentComponentData } =
        action.payload;
      const indexOfScreen = findCurrentScreenIndex(state, currentScreenData);
      const childIndex = findChildIndex(
        state,
        indexOfScreen,
        parentComponentData
      );

      const imageAddressArray = imageSrc.toString().split("base64,");
      let newImageSrc;
      if (imageAddressArray.length > 1) {
        newImageSrc = imageAddressArray[1];
      } else {
        newImageSrc = imageSrc;
      }

      updateTemporaryFlowJson(state, indexOfScreen, childIndex, {
        src: newImageSrc,
        width: 200,
        height: 200,
      });
      updateSimulatorJson(state, indexOfScreen, childIndex, {
        src: newImageSrc,
        width: 200,
        height: 200,
      });
    },

    updateTemporaryComponentArrayOnDropEvent: (state, action) => {
      const { screenData, componentData, componentIndex, dropEventIndex } =
        action.payload;
      const screenIndex = findCurrentScreenIndex(state, screenData);

      const payloadToAddInTemoporaryComponentArray = {
        screenId: screenData.id,
        u_id:
          screenData.id +
          "_" +
          componentData.type +
          "_" +
          Math.floor(Math.random() * 1000) +
          "_" +
          state.flowJson.TemporaryFlowJsonScreens[screenIndex].layout.childrens
            .length,
        type: componentData.type,
        index: componentIndex,
        componentData: {
          type: componentData.type,
          value: null,
        },
      };

      if (componentData.type === "DataChannelUrl") {
        state.flowJson.dataChannelUri = payloadToAddInTemoporaryComponentArray;
      }

      if (componentData.type === "Variable") {
        state.flowJson.TemporaryFlowJsonScreens[
          screenIndex
        ].layout.childrens.splice(0, 0, payloadToAddInTemoporaryComponentArray);
      } else {
        state.flowJson.TemporaryFlowJsonScreens[
          screenIndex
        ].layout.childrens.splice(
          dropEventIndex + 1,
          0,
          payloadToAddInTemoporaryComponentArray
        );
      }
      state.simulator.screenData[screenIndex].layout.childrens.splice(
        dropEventIndex + 1,
        0,
        payloadToAddInTemoporaryComponentArray
      );
      state.showPropertyWindowTab = {
        state: true,
        item: componentData.type,
        value: payloadToAddInTemoporaryComponentArray,
      };
    },

    updateTemporaryArrayOnRenderingComponentDroping: (state, action) => {
      const { screenData, componentData, componentIndex, dropEventIndex } =
        action.payload;
      const screenIndex = findCurrentScreenIndex(state, screenData);

      state.flowJson.TemporaryFlowJsonScreens[
        screenIndex
      ].layout.childrens.splice(componentIndex, 1);
      state.simulator.screenData[screenIndex].layout.childrens.splice(
        componentIndex,
        1
      );

      state.flowJson.TemporaryFlowJsonScreens[
        screenIndex
      ].layout.childrens.splice(dropEventIndex + 1, 0, componentData);
      state.simulator.screenData[screenIndex].layout.childrens.splice(
        dropEventIndex + 1,
        0,
        componentData
      );
      state.showPropertyWindowTab = {
        state: true,
        item: componentData.type,
        value: componentData,
      };
    },

    setShowPropertyWindowTab: (state, action) => {
      const { propertyWindoTabValue, item, value } = action.payload;

      state.showPropertyWindowTab.state = propertyWindoTabValue;
      state.showPropertyWindowTab.item = item;
      state.showPropertyWindowTab.value = value;
    },

    setShowDropDownItemList: (state, action) => {
      const dropDownItemList = action.payload;
      const dropDownComponentIndex = state.dropDownOptionslist.findIndex(
        (component) => component.u_id === dropDownItemList.u_id
      );

      if (
        state.dropDownOptionslist.length < 1 ||
        state.dropDownOptionslist[dropDownComponentIndex] === null ||
        dropDownComponentIndex === -1
      ) {
        state.dropDownOptionslist = [
          ...state.dropDownOptionslist,
          { ...dropDownItemList },
        ];
      } else {
        state.dropDownOptionslist[dropDownComponentIndex] = {
          ...state.dropDownOptionslist[dropDownComponentIndex],
          ...dropDownItemList,
        };
      }
    },

    changeComponentConfirmationModal: (state, action) => {
      const modalStatus = action.payload;
      state.modals.showDeleteComponentConfirmationModal = modalStatus;
    },

    onPendingCreateFlow: (state, action) => {
      state.flows.controls.isCreateFlowPending = true;
    },

    onSuccessCreateFlow: (state, action) => {
      state.flows.controls.isCreateFlowPending = false;
      const { flowId, flowName, category, createdAt } = action.payload;
      state.flows.flowId = flowId;
      state.flows.flowName = flowName;
      state.flows.flowCategory = category;
      state.flows.flowCreatedAt = createdAt;
    },

    onFailureCreateFlow: (state, action) => {
      state.flows.controls.createFlowErrorMessage = action.payload.message;
    },

    onChangeDataChannelUrl: (state, action) => {
      const { dataChannelUrl } = action.payload;

      state.flowJson.dataChannelUri = dataChannelUrl;
    },

    callDataChannelUrlOnChecked: (state, action) => {
      const {
        currentScreenData,
        parentComponentData,
        setCallDataChannelUrl,
        value,
        uuid,
      } = action.payload;

      const indexOfScreen = findCurrentScreenIndex(state, currentScreenData);

      const childIndex = findChildIndex(
        state,
        indexOfScreen,
        parentComponentData
      );

      const temporaryFlowJsonComponentDataObject =
        state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout.childrens[
          childIndex
        ].componentData;
      let getTemporaryFlowJsonValueToUpdate;

      if (value === "radioButton") {
        state.CallDataChannelUrlOnClicked.state = setCallDataChannelUrl;
        state.CallDataChannelUrlOnClicked.uuid = uuid;
      } else if (value === "dropdown") {
        state.CallDataChannelUrlOnClickeddropdown.state = setCallDataChannelUrl;
        state.CallDataChannelUrlOnClickeddropdown.uuid = uuid;
      } else if (value === "checkBox") {
        state.CallDataChannelUrlOnClickedCheckBox.state = setCallDataChannelUrl;
        state.CallDataChannelUrlOnClickedCheckBox.uuid = uuid;
      }

      if (setCallDataChannelUrl) {
        getTemporaryFlowJsonValueToUpdate = () => {
          if (
            [null, undefined].includes(
              temporaryFlowJsonComponentDataObject.value
            )
          ) {
            return {
              "on-select-action": {
                name: "data_exchange",
                payload: {},
              },
            };
          }

          if (temporaryFlowJsonComponentDataObject.value instanceof Object) {
            return {
              ...temporaryFlowJsonComponentDataObject.value,
              "on-select-action": {
                name: "data_exchange",
                payload: {},
              },
            };
          }
        };

        state.flowJson.TemporaryFlowJsonScreens[indexOfScreen].layout.childrens[
          childIndex
        ].componentData.value = getTemporaryFlowJsonValueToUpdate();
      } else {
        getTemporaryFlowJsonValueToUpdate = () => {
          if (
            [null, undefined].includes(
              temporaryFlowJsonComponentDataObject.value
            )
          ) {
            return null;
          }

          if (
            temporaryFlowJsonComponentDataObject.value instanceof Object &&
            temporaryFlowJsonComponentDataObject.value["on-select-action"]
          ) {
            delete temporaryFlowJsonComponentDataObject.value[
              "on-select-action"
            ];
            return {
              ...temporaryFlowJsonComponentDataObject.value,
            };
          }
        };
      }
      updateTemporaryFlowJson(
        state,
        indexOfScreen,
        childIndex,
        getTemporaryFlowJsonValueToUpdate()
      );
    },

    // ----------------------------------------- TEMPLATES ---------------------------------- //

    userDetailTemplateComponent: (state, action) => {
      const { screenData } = action.payload;

      const screenIndex = findCurrentScreenIndex(state, screenData);

      let screenComponentToAdd = [
        {
          screenId: "",
          u_id: "",
          type: "TextInput",
          index: 0,
          componentData: {
            type: "TextInput",
            value: {
              label: "Name",
              name: "name",
              required: true,
              "input-type": "text",
              visible: true,
            },
          },
        },
        {
          screenId: "",
          u_id: "",
          type: "TextInput",
          index: 0,
          componentData: {
            type: "TextInput",
            value: {
              label: "Email",
              name: "email",
              required: true,
              "input-type": "email",
              visible: true,
            },
          },
        },
        {
          screenId: "",
          u_id: "",
          type: "TextArea",
          index: 1,
          componentData: {
            type: "TextArea",
            value: {
              label: "Address",
              name: "address",
              required: true,
              visible: true,
            },
          },
        },
        {
          screenId: "",
          u_id: "",
          type: "DatePicker",
          index: 1,
          componentData: {
            type: "DatePicker",
            value: {
              label: "Date Of Birth",
              name: "dob",
              required: true,
              visible: true,
            },
          },
        },
      ];

      const updatedScreenDataComponentForUserDetails = screenComponentToAdd.map(
        (item, index) => {
          return {
            screenId: screenData.id,
            u_id: uuid(),
            type: item.type,
            index: item.index,
            componentData: item.componentData,
          };
        }
      );

      state.simulator.screenData[screenIndex].layout.childrens = [
        ...state.simulator.screenData[screenIndex].layout.childrens,
        ...updatedScreenDataComponentForUserDetails,
      ];
      state.flowJson.TemporaryFlowJsonScreens[screenIndex].layout.childrens = [
        ...state.flowJson.TemporaryFlowJsonScreens[screenIndex].layout
          .childrens,
        ...updatedScreenDataComponentForUserDetails,
      ];
    },

    // ----------------------------------------- TEMPLATES ---------------------------------- //
  },

  //----------------------------------extra-Reducers-----------------------------------------
  extraReducers: (builder) => {
    //-> get all flow

    builder.addCase(GetAllFlowThunk.pending, (state, action) => {
      state.showGetAllFlowLoader = true;

      state.showgetAllFlowErrormessage = "";
    });

    builder.addCase(GetAllFlowThunk.fulfilled, (state, action) => {
      state.showGetAllFlowLoader = false;

      state.showgetAllFlowErrormessage = "";

      state.allFlow = action.payload.data;
    });

    builder.addCase(GetAllFlowThunk.rejected, (state, action) => {
      state.showGetAllFlowLoader = false;

      state.showgetAllFlowErrormessage = action.error.message;
    });

    builder.addCase(createFlowJson.pending, (state, action) => {
      state.flowJson.finalFlowJson = action.meta.arg.jsonData;
    });

    builder.addCase(createFlowJson.fulfilled, (state, action) => {
      state.updatedFlowData = action.payload;
    });

    builder.addCase(createFlowJson.rejected, (state, action) => {});

    builder.addCase(handleViewJsonThunk.pending, (state, action) => {});

    builder.addCase(handleViewJsonThunk.fulfilled, (state, action) => {
      state.flowJson.flowJsonToShow = action.payload;
      state.flowJson.showJsonModal = true;
    });

    builder.addCase(handleViewJsonThunk.rejected, (state, action) => {});
  },
});

export const {
  resetAllStates,
  setFlowNameAndCategory,
  addComponentToTemporaryFlowComponents,
  setNewScreen,
  changeCreateScreenModal,
  changeCurrentFlowScreen,
  deleteScreen,
  saveFlowComponentValueToTemporaryFlowJson,
  deleteDataElementFromSlice,
  deleteComponetFromSlice,
  addDropDownLabel,
  addDropDownItems,
  deleteDropDownItem,
  setTextHeadingAndSubHeading,
  addButtonLabel,
  addButtonItems,
  pushButtonItemsInArray,
  deleteButtonItem,
  deleteVariableExample,
  addInputLabel,
  setInputComponentRequiredField,
  setTextBodyInput,
  setDatePicker,
  setImageSrc,
  setNameOfFooterLabel,
  setFooterAction,
  updateTemporaryComponentArrayOnDropEvent,
  setShowPropertyWindowTab,
  setShowDropDownItemList,
  clearDropDownListState,
  setFlowAndScreenData,
  updateFlowName,
  setFlowListingData,
  updateTemporaryArrayOnRenderingComponentDroping,
  changeComponentConfirmationModal,
  onChangeScreenDrop,
  addExtraVariable,
  deleteDataChannelUrlComponent,
  onChangeDataChannelUrl,
  pushDynamicButtonOptions,
  addConstantVariableToButton,
  callDataChannelUrlOnChecked,
  callDataChannelUrlOnCheckedDropDown,
  callDataChannelUrlOnCheckedCheckBox,
  userDetailTemplateComponent,
  deleteDynamicVariableInSlice,
} = whatsAppFlowSlice.actions;

export default whatsAppFlowSlice.reducer;

// ======================================================= THUNK =====================================================

export const GetAllFlowThunk = createAsyncThunk("get-all-flows", async () => {
  try {
    const response = await axiosInstance.get(`flows/get-all-flows`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error.message;
  }
});

export const CreateFlowThunk = createAsyncThunk(
  "create-flow",
  async (flowdata) => {
    try {
      const response = await axiosInstance.post(`flows/create-flow`, flowdata);
      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const createFlowJson = createAsyncThunk(
  "update-flow-json",
  async (flowObjectToUpdate) => {
    try {
      const response = await axiosInstance.post(
        `flows/update-json-flow`,
        flowObjectToUpdate
      );
      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const handleViewJsonThunk = createAsyncThunk(
  "get-Flow-Json",
  async ({ flowId }) => {
    try {
      const response = await axiosInstance.get(`flows/get-flow-json/${flowId}`);
      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      console.log(error);
    }
  }
);